<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-button color="primary" (click)="onDismiss()">{{'DIALOGS.CONFIRM_BUTTONS.YES' | translate}}</button>
  <button mat-button color="primary" (click)="onConfirm()">{{'DIALOGS.CONFIRM_BUTTONS.NO' | translate}}</button>
  <button mat-button color="primary" (click)="onSaveAndExit()">{{'DIALOGS.CONFIRM_BUTTONS.SAVE_AND_EXIT' | translate}}</button>
</div>
