import { Component, OnInit } from '@angular/core';
import { PageModel, PreviewPageModel } from "../../../shared/models/page.model";
import { ActivatedRoute, Router } from "@angular/router";
import { PagesManagementService } from "../../../shared/services/pages-management.service";
import { ReportCategoryModel } from "../../../shared/models/report-category.model";
import { PageChildren } from "../../../shared/models/page.model";
import { CategoriesService } from "../../../shared/services/categories.service";
import { BehaviorSubject, forkJoin, Observable, Observer, of, Subscription } from "rxjs";
import { CategoryTypes } from "../../../shared/enums/categoryTypes.enum";
import { BreadcrumbsService } from "../../../shared/services/breadcrumbs.service";
import { ConfirmComponent, ConfirmModel } from "../../modal-dialogs/confirm/confirm.component";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ContentSeas } from "../../../shared/enums/contentSeas.enum";

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss']
})
export class ViewPageComponent implements OnInit {

  public pageId: any;
  public page: PageModel | undefined;

  public pages: PageModel[] | undefined;
  public categories: ReportCategoryModel[] | undefined

  public categoryTypes = CategoryTypes;

  private pageIdSubscription: Subscription | undefined;

  public previewItems: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public areaSeas = ContentSeas;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PagesManagementService,
    private categoriesService: CategoriesService,
    private breadcrumbsService: BreadcrumbsService,
    private toast: ToastService,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ViewPageComponent>,
    private dialog: MatDialog
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    this.checkBreadcrumbs();
    return of(true);
  }

  ngOnInit(): void {
    // get content item data
    this.pageIdSubscription = this.route.params.subscribe(routeParams => {
      // get page's data
      this.pageId = routeParams['id'];
      this.getData();

      // this.breadcrumbsService.updateItems(this.pageId);

      // record first breadcrumb item if needed
      if (this.breadcrumbsService.breadcrumbsItems.getValue().length === 0) {
        this.breadcrumbsService.addBreadcrumb(routeParams['id']);
      }
    });
  }

  ngOnDestroy() {
    this.pageIdSubscription?.unsubscribe();
  }

  checkBreadcrumbs() {
    this.breadcrumbsService.pendingAddingBreadcrumbItem !== undefined
    && this.breadcrumbsService.addBreadcrumb(this.breadcrumbsService.pendingAddingBreadcrumbItem);

    this.breadcrumbsService.pendingShorteningBreadcrumbItem !== undefined
    && this.breadcrumbsService.clearAfterIndex(this.breadcrumbsService.pendingShorteningBreadcrumbItem);
  }

  getData() {

    let requests = [
      this.pagesService.getPage(this.pageId),
      this.pagesService.getPages(),
      this.categoriesService.getCategories()
    ];

    forkJoin(requests).subscribe({
      next: (responses: any) => {

        // responses[0] - page info
        let pageInfo = responses[0];
        pageInfo.children = pageInfo.children.sort((
          a: { order: number; },
          b: { order: number; }) => (a.order > b.order ? 1 : -1));
        this.page = pageInfo;

        // responses[1] - all pages
        this.pages = responses[1];

        // responses[2] - all categories
        this.categories = responses[2];

        this.previewItems.next(this.getItems());

      },
      error: (error) => {}
    });

  }

  getCategoryName(id: number) {
    return this.categories?.find(category => category.id === id)?.name;
  }

  getPageTitle(id: number) {
    return this.pages?.find(page => page.id === id)?.title;
  }

  getItems() {
    return this.page?.children?.map(child => child.pageId > 0 ?
      {
        name: this.getPageTitle(child.pageId),
        type: 'page',
        promoted: child.promoted
      } :
      {
        name: this.getCategoryName(child.categoryId),
        type: 'category',
        promoted: child.promoted
      });
  }

  get unconnectedPagesNumber() : number | undefined {
    let withoutPage = this.page?.children?.filter(child => child.pageId === 0);
    return withoutPage && withoutPage.length;
  }

  confirmRemovePage(event: Event, pageId: number) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAGE'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove page
        this.pagesService.deletePage(pageId)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_PAGE'));
              this.router.navigate(['/pages-management/'], {replaceUrl: true});
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  gotoChild(child: PageChildren) {
    this.breadcrumbsService.addBreadcrumb(child.pageId);
    this.router.navigate([`/page-view/${child.pageId}`], {replaceUrl: true});
  }

  editPage() {

  }


}
