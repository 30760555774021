import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ApiService } from '../../shared/services/api.service';
import { ToastService } from '../../shared/services/toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContentModel } from '../../shared/models/content.model';
import { ConfirmComponent, ConfirmModel } from '../modal-dialogs/confirm/confirm.component';
import { ContentService } from '../../shared/services/content.service';
import { ReportCategoryModel } from '../../shared/models/report-category.model';
import { AppConstants } from '../../shared/constants';
import { StatesService } from '../../shared/services/states.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  public appConstants = AppConstants;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<ContentComponent>,
    private dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,
    private contentService: ContentService,
    private statesService: StatesService
  ) {}

  public filteredContentItems: any = new MatTableDataSource([]);
  public contentItems: any[] = [];
  public displayedColumns: string[] = ['actions', 'id', 'name', 'groupName', 'description', 'categories', 'order'];

  public groups: any[] = [];
  public groupMultiCtrl: FormControl = new FormControl();
  public groupFilterCtrl: FormControl = new FormControl();
  public filteredGroups: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected filteredGroupsCache: any[] = [];
  public isAllGroupsIndeterminate = true;
  public isAllGroupsChecked = false;

  public editingOrderValue: number = 0;
  public searchTerm: string = '';

  private componentIsDestroyed = new Subject<boolean>();

  ngOnInit(): void {
    this.contentService.getGroups().subscribe((resp: any) => {
      this.groups = resp;

      // load the initial statuses list
      this.filteredGroups.next(this.groups.slice());

      // listen for search field value changes
      this.groupFilterCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
        this.filterGroups();
        this.setToggleAllGroupsCheckboxState();
      });

      // listen for multi select field value changes
      this.groupMultiCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
        this.setToggleAllGroupsCheckboxState();
      });

      this.groupFilterCtrl.patchValue('');
    });
  }

  ngAfterViewInit() {
    this.updateContentItems();
  }

  confirmRemoveContent(event: Event, content: ContentModel) {
    const dialogData = new ConfirmModel(
      this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CONTENT')
    );
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: '400px',
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        // remove content
        this.contentService.removeContent(content.id).subscribe({
          next: (resp: any) => {
            this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_CONTENT_ITEM'));
            this.updateContentItems();
          },
          error: (error: any) => {},
        });
      } else {
        // not confirmed - do nothing
      }
    });
  }

  updateContentItems() {
    this.contentService.getContentItems(this.searchTerm).subscribe({
      next: (resp: any) => {
        this.filteredContentItems.data = resp;
        this.contentItems = resp;

        this.paginator.pageIndex = this.statesService.contentPaginator.pageIndex;
        this.paginator.pageSize = this.statesService.contentPaginator.pageSize;
        this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

        this.filteredContentItems.paginator = this.paginator;
        this.filteredContentItems.sort = this.sort;
      },
      error: (error) => {},
    });
  }

  gotoAddContentPage() {
    this.router.navigate(['content-add']);
  }

  categoriesNames(categories: ReportCategoryModel[]) {
    return categories.map((category) => category.name).join(', ');
  }

  changePage(event: any) {
    this.statesService.contentPaginator.pageIndex = event.pageIndex;
    this.statesService.contentPaginator.pageSize = event.pageSize;
  }

  toggleSelectAllGroups(selectAllValue: boolean) {
    this.filteredGroups.pipe(take(1), takeUntil(this.componentIsDestroyed)).subscribe((val) => {
      if (selectAllValue) {
        let alreadySelected = this.groupMultiCtrl.value ?? [];
        val.forEach((group) => {
          if (!alreadySelected.includes(group.id)) alreadySelected.push(group.id);
        });
        this.groupMultiCtrl.patchValue(alreadySelected);
      } else {
        this.groupMultiCtrl.patchValue([]);
      }
    });
  }
  filterGroups() {
    if (!this.groups) {
      return;
    }
    // get the search keyword
    let search = this.groupFilterCtrl.value;
    if (!search) {
      this.filteredGroupsCache = this.groups.slice();
      this.filteredGroups.next(this.filteredGroupsCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the statuses
    this.filteredGroupsCache = this.groups.filter((group: { name: string }) => group.name.toLowerCase().indexOf(search) > -1);
    this.filteredGroups.next(this.filteredGroupsCache);
  }
  protected setToggleAllGroupsCheckboxState() {
    let filteredLength = 0;
    if (this.groupMultiCtrl && this.groupMultiCtrl.value) {
      this.filteredGroupsCache.forEach((filteredGroup) => {
        if (this.groupMultiCtrl.value.find((selectedGroupId: number) => selectedGroupId === filteredGroup.id)) {
          filteredLength++;
        }
      });
      this.isAllGroupsIndeterminate = filteredLength > 0 && filteredLength < this.filteredGroupsCache.length;
      this.isAllGroupsChecked = filteredLength > 0 && filteredLength === this.filteredGroupsCache.length;
    }

    this.updateResults();
  }

  ngOnDestroy() {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();

    // this.sortingSubscription?.unsubscribe();
  }

  updateResults() {
    if (this.groupMultiCtrl.value?.length > 0) {
      this.filteredContentItems.data = this.contentItems.filter((item) => this.groupMultiCtrl.value.includes(item.groupId));
    } else {
      this.filteredContentItems.data = this.contentItems;
    }
  }

  filterByGroup(groupId: string): void {
    if (this.groupMultiCtrl.value && groupId === this.groupMultiCtrl.value[0]) {
      this.groupMultiCtrl.patchValue([]);
    } else {
      this.groupMultiCtrl.patchValue([this.groups.find((group) => group.id === groupId).id]);
    }
  }

  updateContentOrder(id: number) {
    const contentIndex = this.contentItems.findIndex((content) => content.id === id);

    if (
      this.contentItems[contentIndex].order !== this.editingOrderValue &&
      this.editingOrderValue >= 0 &&
      this.editingOrderValue <= AppConstants.maximumOrderValue
    ) {
      this.contentService.setContentOrder(id, this.editingOrderValue).subscribe((response) => {
        this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_ORDER'));
        const updatedItem = this.contentItems[contentIndex];
        updatedItem.order = this.editingOrderValue;
        this.contentItems[contentIndex] = updatedItem;
      });
    }
  }

  setEditingValue(mode: any, order: number): void {
    if (mode === 'edit') {
      this.editingOrderValue = order;
    }
  }

  resetFilters() {
    this.searchTerm = '';
    // ToDo: reset some values as page, etc.
    this.updateContentItems();
    // ToDo: reset filters
  }

  searchContent() {
    // ToDo: reset some values as page, etc.
    this.updateContentItems();
  }
}
