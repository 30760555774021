import { Component, Input, OnInit } from '@angular/core';
import { PreviewPageModel } from "../../models/page.model";

@Component({
  selector: 'app-page-preview',
  templateUrl: './page-preview.component.html',
  styleUrls: ['./page-preview.component.scss']
})
export class PagePreviewComponent implements OnInit {

  @Input() items: PreviewPageModel[] | undefined;

  public promotedItems: PreviewPageModel[] | undefined = [];
  public notPromotedItems: PreviewPageModel[] | undefined = [];

  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.promotedItems = this.items?.filter(item => item.promoted);
    this.notPromotedItems = this.items?.filter(item => !item.promoted);
  }

}
