import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../shared/services/auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private route: Router,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
  }

  sendResetPasswordEmail() {
    if (this.form.valid) {

      const email = this.form.get('email')?.value;

      this.auth.sendForgotPassword(email).subscribe({
        next: (resp: any) => {
          // we don't check if this email exists
          // just show 'sent' message and navigate to the login page
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CHECK_EMAIL_BOX'));
          this.form.reset();
          this.route.navigate(['/login']);
        },
        error: (error) => {},
      });

    }
  }
}
