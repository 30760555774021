<div class="crud-page">
  <div class="page-header">
      <a class="link back-button" routerLink="/pages-management"><mat-icon>arrow_left</mat-icon>{{'PAGES.PAGES_MANAGEMENT.BACK' | translate}}</a>
      <h1>{{'PAGES.PAGE_UPDATE.TITLE' | translate}}</h1>
      <button (click)="startPageUpdating()" [disabled]="!form.valid" class="toolbar" color="primary" mat-flat-button>{{'BUTTONS.SAVE_CHANGES' | translate}}</button>
      <button (click)="confirmRemovePage($event, pageId)" class="toolbar" color="primary" mat-flat-button>{{'BUTTONS.DELETE_PAGE' | translate}}</button>
    </div>
  <div class="page-content top-breadcrumbs">
      <app-breadcrumbs></app-breadcrumbs>
      <div *ngIf="page" class="view-container wide">
        <div class="items">
          <form [formGroup]="form" class="vertical-form wide">
            <mat-form-field class="category-name">
              <mat-label>{{'PAGES.PAGES_MANAGEMENT.PAGE_TITLE' | translate}}</mat-label>
              <input formControlName="title" matInput required>
              <mat-error *ngIf="checkError('title', 'required')">{{'PAGES.PAGES_MANAGEMENT.ERRORS.REQUIRED_TITLE' | translate}}</mat-error>
              <mat-error *ngIf="checkError('title', 'minlength')">{{'PAGES.PAGES_MANAGEMENT.ERRORS.TOO_SHORT_TITLE' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="category-name">
              <mat-label>{{'PAGES.PAGES_MANAGEMENT.PAGE_SUBTITLE' | translate}}</mat-label>
              <input formControlName="subTitle" matInput>
            </mat-form-field>

            <span class="value in-row">
              <mat-checkbox formControlName="main" (change)="checkCategoryType()">{{'PAGES.PAGES_MANAGEMENT.IS_MAIN' | translate}}</mat-checkbox>

              <mat-radio-group formControlName="categoryType" (change)="checkMain()">
                <mat-radio-button value="{{categoryTypes.OBSERVATION}}">{{categoryTypes.OBSERVATION}}</mat-radio-button>
                <mat-radio-button value="{{categoryTypes.VIOLATION}}">{{categoryTypes.VIOLATION}}</mat-radio-button>
              </mat-radio-group>
            </span>

            <span class="unconnected-pages">
              <mat-icon class="mat-icon material-icons">noise_control_off</mat-icon>
              {{'PAGES.PAGES_MANAGEMENT.UNCONNECTED_PAGES' | translate}} ({{unconnectedPagesNumber}})
            </span>

            <div formArrayName="children">
              <mat-list class="categories-list" role="list">
                <div *ngFor="let child of pageChildren.controls; let i=index">
                  <mat-list-item [formGroupName]="i" role="listitem" >
                    <img class="light-link" (click)="removeChild(i)" [src]="child.get('pageId')?.value > 0 ? 'assets/icons/remove-category.png' : 'assets/icons/red-remove-category.png'">

                    <img [src]="child.get('pageId')?.value > 0 ? 'assets/icons/category.png' : 'assets/icons/red-category.png'">

                    <mat-form-field [class.category-only]="child.get('pageId')?.value === 0" class="category-name-dropdown">
                      <mat-select formControlName="categoryId">
                        <mat-option [value]=0></mat-option>
                        <mat-option *ngFor="let category of categories" [value]="category.id">
                          {{category.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <span class="page-title">
                      <img src="assets/icons/link.png" />
                      <img src="assets/icons/{{child.get('pageId')?.value > 0 ? '':'no-'}}page.png" />

                      <mat-form-field [class.category-only]="child.get('pageId')?.value === 0" class="page-name-dropdown">
                        <mat-select formControlName="pageId" [class]="'page-select-' + i">
                          <mat-option [value]=0></mat-option>
                          <mat-option *ngFor="let page of pages" [value]="page.id">
                            {{page.title}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>

                    <button (click)="gotoChild(child.get('pageId')?.value)" *ngIf="child.get('pageId')?.value > 0" class="goto-page" mat-flat-button>{{'BUTTONS.GOTO_PAGE' | translate}}</button>
                    <button (click)="choosePage(child, i)" *ngIf="!(child.get('pageId')?.value > 0)" class="add-new" color="primary" mat-flat-button>{{'BUTTONS.ADD_NEW' | translate}}</button>

                    <mat-form-field class="sea-dropdown">
                      <mat-select formControlName="sea" placeholder="{{'PAGES.CONTENT.BY_SEA' | translate}}" required>
                        <mat-option [value]="areaSeas.MEDITERRANEAN">{{'PAGES.CONTENT.SEAS.MEDITERRANEAN' | translate}}</mat-option>
                        <mat-option [value]="areaSeas.RED">{{'PAGES.CONTENT.SEAS.RED' | translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-checkbox formControlName="promoted">{{'PAGES.PAGES_MANAGEMENT.PROMOTED' | translate}}</mat-checkbox>

                    <button (click)="removeChild(i)" *ngIf="false">{{'BUTTONS.REMOVE' | translate}}</button>
                  </mat-list-item>
                </div>
              </mat-list>
            </div>

            <button (click)="addNewChild()" class="action" color="primary" mat-flat-button>+ {{'BUTTONS.ADD_CATEGORY' | translate}}</button>
          </form>
        </div>
        <app-page-preview [items]="previewItems.getValue()"></app-page-preview>
      </div>
    </div>
</div>
