<div class="page-preview" *ngIf="promotedItems && promotedItems.length > 0 || notPromotedItems && notPromotedItems.length > 0">
  <span class="header">{{'COMPONENTS.PAGE_PREVIEW.TITLE' | translate}}</span>
  <div class="page-preview-container">
    <mat-list role="list">
      <mat-list-item role="listitem" *ngIf="promotedItems && promotedItems.length > 0">
        <span class="title">{{'COMPONENTS.PAGE_PREVIEW.FREQUENTLY_USED' | translate}}</span>
      </mat-list-item>
      <mat-list-item role="listitem" *ngFor="let item of promotedItems" [class.category-only]="item.type === 'category'">
        <img [src]="item.type === 'page' ? 'assets/icons/page.png' : 'assets/icons/red-page.png'"/> {{item.name}}
      </mat-list-item>

      <mat-list-item role="listitem" *ngIf="notPromotedItems && notPromotedItems.length > 0">
        <span class="title">{{'COMPONENTS.PAGE_PREVIEW.OTHER_CATEGORIES' | translate}}</span>
      </mat-list-item>
      <mat-list-item role="listitem" *ngFor="let item of notPromotedItems" [class.category-only]="item.type === 'category'">
        <img [src]="item.type === 'page' ? 'assets/icons/page.png' : 'assets/icons/red-page.png'"/> {{item.name}}
      </mat-list-item>
    </mat-list>
  </div>
</div>
