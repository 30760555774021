import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private loading: LoadingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // do not show 'loading' status if something local is loading like SVG icons

    if (request.responseType !== 'text') {
      setTimeout(() => {
        this.loading.show();
      }, 0);

      return next.handle(request).pipe(
        finalize(() => {
          setTimeout(() => {
            this.loading.hide();
          }, 0);
          // this.loading.hide()
        })
      )
    } else return next.handle(request);
  }
}
