import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { delay, mergeMap, Observable, of, retryWhen } from 'rxjs';
import { ToastService } from "../services/toast.service";
import { Router } from "@angular/router";

import { ToastMessageTypes } from "../enums/toastMessageTypes.enum";
import { Location } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

export const maxRetries = 2;
export const delayMs = 2000;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly injector: Injector,
    private toast: ToastService,
    private route: Router,
    private location: Location,
    private translate: TranslateService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      retryWhen((error) => error.pipe(
        mergeMap((error, index) => {
          // for 500 errors we want to retry a couple of times with delays between tries
          if (error.status == 500 && index < maxRetries ) {
            return of(error).pipe(delay(delayMs));
          }

          let errorMessage = ``;
          if (error.error instanceof ErrorEvent) {
            // client-side error
            this.toast.show(error.error.message, ToastMessageTypes.ERROR);

          } else {
            // server-side error
            if (error.status !== 401) { // do not show 'unauthorized' error

              if (error.error?.errors || error.error) {
                if (!error.status || error.status !== 500) {
                  const errorSet = error.error.errors || error.error;

                  errorMessage = error.status && !errorSet[0]?.errorCode ?
                    this.translate.instant(`ERROR_MESSAGES.${error.status}`) :
                    errorSet.map((err: any) => this.translate.instant(`ERROR_MESSAGES.SERVER_ERROR_CODES.${err.errorCode}`)).join('<br>');
                }

                // 500 code is here too
                if (error.status && error.status == 500) {
                  errorMessage = this.translate.instant(`ERROR_MESSAGES.${error.status}`);
                }

              } else {
                errorMessage = this.translate.instant(`ERROR_MESSAGES.${error.status}`);
              }
              this.toast.show(errorMessage, ToastMessageTypes.ERROR);
              if (error.status === 404) {
                // go back
                // this.location.back();
              }
            }

            if (error.status === 401 && ( // 401 error
              req.url.indexOf('/token') !== -1 && // checking PIN request
              req.url.indexOf('/token/refresh') === -1 // but not refreshing token request
            )) {
              // temporary token is already expired and user has to login again to get a new one
              this.toast.show(this.translate.instant('ERROR_MESSAGES.PIN_EXPIRED'), ToastMessageTypes.ERROR);
              this.route.navigate(['/login']);
            }

          }
          throw error;
        })
      ))
    )
    // return next.handle(request);
  }
}
