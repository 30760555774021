import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ReportStatusService } from "../../../shared/services/report-status.service";

@Component({
  selector: 'app-view-report-status',
  templateUrl: './view-report-status.component.html',
  styleUrls: ['./view-report-status.component.scss']
})
export class ViewReportStatusComponent implements OnInit {

  private reportStatusId: any;
  public reportStatus: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportStatusService: ReportStatusService
  ) { }

  ngOnInit(): void {
    // get status data
    this.reportStatusId = this.route.snapshot.paramMap.get('id');

    if (this.reportStatusId) {
      this.reportStatusService.getReportStatus(this.reportStatusId).subscribe({
        next: (resp: any) => {
          this.reportStatus = resp;
        },
        error: (error: any) => {}});
    }
  }

  editReportStatus() {
    this.router.navigate([`report-status-edit/${this.reportStatusId}`]);
  }

}
