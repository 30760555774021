import { Component, OnInit } from '@angular/core';
import { AppService } from "../../shared/services/app.service";
import { AuthService } from "../../shared/services/auth/auth.service";

@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent implements OnInit {

  constructor(
    private appService: AppService,
    private auth: AuthService
  ) { }

  public appInfo: any = undefined;

  ngOnInit(): void {
    if (this.auth.isAuthenticated.value) {
      this.appService.getAppVersion()
        .subscribe({
          next: (resp: any) => {
            this.appInfo = resp;
          },
          error: (error) => {
          }
        })
    }
  }

}
