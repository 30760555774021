import { Component, OnInit } from '@angular/core';
import { ContentModel } from "../../../shared/models/content.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ContentService } from "../../../shared/services/content.service";
// import { Gallery, ImageItem } from "ng-gallery";
import lgZoom from 'lightgallery/plugins/zoom';
import { forkJoin } from "rxjs";
import { ContentGroupModel } from "../../../shared/models/groups.model";

@Component({
  selector: 'app-view-content',
  templateUrl: './view-content.component.html',
  styleUrls: ['./view-content.component.scss']
})
export class ViewContentComponent implements OnInit {

  private contentItemId: any;
  public contentItem: ContentModel | undefined;

  private contentGroups: ContentGroupModel[] | undefined;
  public contentGroupName: string | undefined;

  public gallerySettings = {
    counter: false,
    plugins: [lgZoom]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    // private gallery: Gallery
  ) { }

  ngOnInit(): void {
    // get content item data
    this.contentItemId = this.route.snapshot.paramMap.get('id');

    if (this.contentItemId) {

      let requests = [
        this.contentService.getContent(this.contentItemId),
        this.contentService.getGroups()
      ];

      forkJoin(requests).subscribe({
        next: (responses: any) => {

          // Content
          /* if (resp.imageUri) {
            const imageData = [{src: resp.imageUri, thumb: resp.imageUri}, {src: 'https://simpl.info/webp/cherry.jpg', thumb: 'https://simpl.info/webp/cherry.jpg'}];
            this.gallery.ref().load(imageData.map(item => new ImageItem(item)));
          } */

          if (responses[0].categories) {
            responses[0].categories = responses[0].categories.sort((
              a: { name: { toLowerCase: () => string; }; },
              b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          }

          this.contentItem = responses[0];

          // Groups
          this.contentGroups = responses[1];
          if (this.contentItem?.groupId) {
            this.contentGroupName = this.contentGroups?.find((group: ContentGroupModel) => group.id === this.contentItem?.groupId)?.name;
          }
        },
        error: (error: any) => {}});
    }
  }

  editContent() {
    this.router.navigate([`content-edit/${this.contentItemId}`]);
  }

}
