<div class="page-header">
  <div class="search-toolbar">
    <h1>{{ "PAGES.CONTENT.TITLE" | translate }}</h1>
    <div class="search-container">
      <mat-form-field appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
    <div class="two-in-column">
      <button class="toolbar search" mat-flat-button color="primary" (click)="searchContent()">{{'PAGES.REPORTS.SEARCH' | translate}}</button>
      <button class="toolbar" mat-flat-button color="primary" (click)="resetFilters()">{{'PAGES.REPORTS.RESET_FILTERS' | translate}}</button>
    </div>
    <div class="two-in-column">
      <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddContentPage()">
        {{ "BUTTONS.ADD_CONTENT" | translate }}
      </button>
      <mat-form-field class="filter-input">
        <mat-select
          placeholder="{{ 'PAGES.CONTENT.FILTER_BY_GROUP' | translate }}"
          [formControl]="groupMultiCtrl"
          (selectionChange)="updateResults()"
          [multiple]="true"
        >
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              [formControl]="groupFilterCtrl"
              [placeholderLabel]="translate.instant('COMMON.SEARCH')"
              [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
              (toggleAll)="toggleSelectAllGroups($event)"
              [toggleAllCheckboxIndeterminate]="isAllGroupsIndeterminate"
              [toggleAllCheckboxChecked]="isAllGroupsChecked"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let group of filteredGroups | async" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="page-content">
  <div class="data-table">
    <table
      mat-table
      [dataSource]="filteredContentItems"
      multiTemplateDataRows
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8 data"
      matSort
    >
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">
          {{ "COMMON.ACTIONS" | translate }}
        </th>
        <td mat-cell *matCellDef="let content" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/content-edit/{{ content.id }}">
            <mat-icon>edit</mat-icon>
          </button>
          <button class="link" mat-icon-button (click)="confirmRemoveContent($event, content)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-50">
          {{ "PAGES.CONTENT.ID" | translate }}
        </th>
        <td mat-cell *matCellDef="let content" class="column-50">
          <a class="link" routerLink="/content-view/{{ content.id }}">
            {{ content.id }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAGES.CONTENT.NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let content">{{ content.name }}</td>
      </ng-container>

      <ng-container matColumnDef="groupName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAGES.CONTENT.GROUP_NAME" | translate }}
        </th>
        <td mat-cell *matCellDef="let content" class="group-filter-toggler" (click)="filterByGroup(content.groupId)">
          {{ content.groupName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAGES.CONTENT.DESCRIPTION" | translate }}
        </th>
        <td mat-cell *matCellDef="let content">{{ content.description }}</td>
      </ng-container>

      <ng-container matColumnDef="categories">
        <th mat-header-cell *matHeaderCellDef>
          {{ "PAGES.CONTENT.CATEGORIES" | translate }}
        </th>
        <td mat-cell *matCellDef="let content">
          {{ categoriesNames(content.categories) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-120">
          {{ "PAGES.CONTENT.ORDER" | translate }}
        </th>
        <td mat-cell *matCellDef="let content" class="column-120">
          <editable editableOnEscape (save)="updateContentOrder(content.id)" (modeChange)="setEditingValue($event, content.order)">
            <ng-template viewMode>{{ content.order }}</ng-template>
            <ng-template editMode>
              <div class="edit-order">
                <input matInput [(ngModel)]="editingOrderValue" editableFocusable type="number" />
                <button
                  class="link check"
                  mat-icon-button
                  editableOnSave
                  [disabled]="this.editingOrderValue < 0 || this.editingOrderValue > appConstants.maximumOrderValue || !this.editingOrderValue"
                >
                  <mat-icon>check</mat-icon>
                </button>
                <button class="link close" mat-icon-button editableOnCancel>
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </ng-template>
          </editable>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let content; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          {{ "COMMON.NO_DATA_MATCHING_YOUR_REQUEST" | translate }}
        </td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>
  </div>
</div>
