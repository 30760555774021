import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BackofficeUserRoles } from "../../../shared/enums/roles.enum";
import { conditionalValidator } from "../../../shared/helpers/conditional.validator";
import { ApiService } from "../../../shared/services/api.service";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { BackofficeUsersService } from "../../../shared/services/backoffice-users.service";
import { Router } from "@angular/router";
import { ReserveModel } from "../../../shared/models/reserve.model";
import { ReportCategoryModel } from "../../../shared/models/report-category.model";
import { ReservesManagementService } from "../../../shared/services/reserves-management.service";
import { CategoriesService } from "../../../shared/services/categories.service";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Patterns } from "../../../shared/patterns";

@Component({
  selector: 'app-add-backoffice-user',
  templateUrl: './add-backoffice-user.component.html',
  styleUrls: ['./add-backoffice-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBackofficeUserComponent implements OnInit {

  @ViewChild('searchCategoryInput') searchCategoryInput: ElementRef | undefined;
  @ViewChild('searchReserveInput') searchReserveInput: ElementRef | undefined;

  public searchReserveText: string = '';
  public searchCategoryText: string = '';

  public reserves: ReserveModel[] = [];
  public categories: ReportCategoryModel[] = [];

  public form: FormGroup;
  public userRoles = BackofficeUserRoles;

  private componentIsDestroyed = new Subject<boolean>();

  public receiveEmail: boolean = false;
  public receiveSMS: boolean = false;
  public receiveWhatsApp: boolean = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private translate: TranslateService,
    private backofficeUsersService: BackofficeUsersService,
    private reservesService: ReservesManagementService,
    private categoriesService: CategoriesService,
    private router: Router
  ) {
    this.form = this.fb.group({
      fullName: ['', [
        Validators.required,
        Validators.pattern(Patterns.name)
      ]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [
        Validators.pattern(Patterns.mobile),
        conditionalValidator(
          () => this.form.get('role')?.value === this.userRoles.INSPECTOR,
          Validators.required,
          'conditionalMobile')
      ]],
      role: ['', Validators.required],
      reserves: ['', conditionalValidator(
        () => this.form.get('role')?.value === this.userRoles.INSPECTOR,
        Validators.required,
        'conditionalReserve'
      )],
      categories: [[], conditionalValidator(
        () => this.form.get('role')?.value === this.userRoles.INSPECTOR,
        Validators.required,
        'conditionalCategory'
      )]
    });

    this.form.valueChanges
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe(() => {
        if (this.form.get('role')?.value !== this.userRoles.INSPECTOR) {
          this.form.get('categories')?.setErrors(null);
          this.form.get('reserves')?.setErrors(null);
        }
    })
  }

  ngOnDestroy() {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }

  ngOnInit(): void {

    // get reserves and categories
    let requests = [
      this.categoriesService.getCategories(),
      this.reservesService.getReserves()
    ];

    forkJoin(requests).subscribe({
      next: (responses: any) => {
        // responses[0] - all categories
        const categories = responses[0].sort((
          a: { name: { toLowerCase: () => string; }; },
          b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.categories = categories;

        // responses[1] - all reserves
        const reserves = responses[1].sort((
          a: { name: { toLowerCase: () => string; }; },
          b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this.reserves = reserves;

      },
      error: (error) => {}
    });

  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  addBackofficeUser() {

    if (this.form.valid) {

      let data: any = {};

      const email = this.form.get('email')?.value;
      const mobile = this.form.get('mobile')?.value;
      const fullName = this.form.get('fullName')?.value;
      const roleId = this.form.get('role')?.value;

      data = { email, fullName, roleId };

      if (mobile) {
        data.mobile = mobile;
      }

      if (roleId === BackofficeUserRoles.INSPECTOR) {
        data.categoriesIds = this.form.get('categories')?.value;
        data.polygonsIds = this.form.get('reserves')?.value;

        data.recieveEmail = this.receiveEmail;
        data.recieveSMS = this.receiveSMS;
        data.recieveWhatsApp = this.receiveWhatsApp;
      }

      this.backofficeUsersService.addBackofficeUser(data).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_USER'));
          this.router.navigate(['/backoffice-users']);
        },
        error: (error) => {},
      });
    }
  }

  setFocus(type: string) {
    if (type === 'categories') this.searchCategoryInput?.nativeElement.focus();
    if (type === 'reserves') this.searchReserveInput?.nativeElement.focus();
  }

  resetSearchText(type: string) {
    if (type === 'categories') this.searchCategoryText = '';
    if (type === 'reserves') this.searchReserveText = '';
  }

}
