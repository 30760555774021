<div class="page-header">
  <a routerLink="/reports-tickets" class="link back-button"
    ><mat-icon>arrow_left</mat-icon>{{ "PAGES.REPORTS_TICKETS.BACK" | translate }}</a
  >
  <h1 *ngIf="reportData?.reportDate">{{ reportData.reportDate + "Z" | date : "dd/MM/yy (HH:mm)" }}</h1>
  <div class="report-status">
    <div>
      <span
        >{{ "PAGES.REPORTS_TICKETS.TYPE" | translate }}: <b>{{ reportData.reportType }}</b></span
      >
      <span *ngIf="reportData.backOfficeUserFullName"
        >{{ "PAGES.REPORTS_TICKETS.LAST_CHANGED_BY" | translate }}: <b>{{ reportData.backOfficeUserFullName }}</b></span
      >
      <div>
        {{ "PAGES.REPORTS_TICKETS.STATUS" | translate }}:
        <mat-select class="inline-select" [(ngModel)]="reportStatus" [ngModelOptions]="{ standalone: true }" *ngIf="isAdmin">
          <mat-option [value]="status.id" *ngFor="let status of statuses" [disabled]="disabledStatusNames.includes(status.name)">{{
            status.name
          }}</mat-option>
        </mat-select>
        <span *ngIf="!isAdmin" class="status">{{ statusName(reportStatus) }}</span>
      </div>
    </div>
    <div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{ "PAGES.REPORTS.LOG_TABLE.NOTE" | translate }}</mat-label>
          <input matInput [(ngModel)]="reportNote" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <button class="toolbar" mat-flat-button color="primary" (click)="saveReport()">{{ "BUTTONS.SAVE_REPORT" | translate }}</button>
</div>
<div class="page-content">
  <div>
    <div class="item">
      <span class="column-header">{{ "PAGES.REPORTS.CATEGORY" | translate }}</span>

      <mat-form-field class="real-width">
        <mat-select [(ngModel)]="reportCategories[0]" [ngModelOptions]="{ standalone: true }">
          <mat-option [value]="-2"></mat-option>
          <mat-option *ngIf="checkIfChanged(0)" [value]="-1">{{ reportData.categories[0]?.name }}</mat-option>
          <mat-option [value]="category.id" *ngFor="let category of categories">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="real-width">
        <mat-select [(ngModel)]="reportCategories[1]" [ngModelOptions]="{ standalone: true }">
          <mat-option [value]="-2"></mat-option>
          <mat-option *ngIf="checkIfChanged(1)" [value]="-1">{{ reportData.categories[1]?.name }}</mat-option>
          <mat-option [value]="category.id" *ngFor="let category of categories">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="real-width">
        <mat-select [(ngModel)]="reportCategories[2]" [ngModelOptions]="{ standalone: true }">
          <mat-option [value]="-2"></mat-option>
          <mat-option *ngIf="checkIfChanged(2)" [value]="-1">{{ reportData.categories[2]?.name }}</mat-option>
          <mat-option [value]="category.id" *ngFor="let category of categories">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="item">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.REPORTER" | translate }}</span>
      {{ reportData.firstName }} {{ reportData.lastName }}
    </div>

    <div class="item">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.PHONE" | translate }}</span>
      {{ reportData.phoneNumber }}
    </div>

    <div class="item" *ngIf="reportData.email">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.EMAIL" | translate }}</span>
      {{ reportData.email }}
    </div>

    <div class="item" *ngIf="reportData.seaRelations">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.MY_RELATIONS_TO_THE_SEA" | translate }}</span>
      {{ reportData.seaRelations.join(", ") }}
    </div>

    <div class="item" *ngIf="reportData.createdDate">
      <span class="column-header">{{ "PAGES.REPORTS.CREATED_DATE" | translate }}</span>
      {{ reportData.createdDate + "Z" | date : "dd/MM/yy (HH:mm)" }}
    </div>
  </div>
  <div *ngIf="reportData.images?.length > 0">
    <div class="item">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.PHOTO_FROM_REPORT" | translate }}</span>
      <lightgallery [settings]="gallerySettings">
        <ng-container *ngFor="let image of reportData.images">
          <img *ngIf="!isVideo(image)" class="gallery-img thumbnails zoom" [src]="image" />

          <a class="video-thumb-container gallery-img" *ngIf="isVideo(image) && image" [attr.data-video]="getDataVideo(image)">
            <video class="thumbnails gallery-img" [controls]="false" [autoplay]="false" [muted]="true">
              <source [src]="image" [type]="'video/' + getVideoFileExtension(image)" />
            </video>
            <button class="play-button" type="button" aria-disabled="false">
              <span class="play-button-icon-placeholder" aria-hidden="true"></span>
            </button>
          </a>
        </ng-container>
      </lightgallery>
    </div>
  </div>
  <div *ngIf="!!reportData.description || !!reportData.metricObservationData">
    <div class="item" *ngIf="!!reportData.description">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.DESCRIPTION" | translate }}</span>
      {{ reportData.description }}
    </div>
    <div class="item" *ngIf="reportData.metricObservationData">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.METRIC_OBSERVATION_DATA" | translate }}</span>
      <span [innerHTML]="reportData.metricObservationData.split('\n').join('<br />')"></span>
    </div>
  </div>
  <div>
    <div class="item">
      <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.LOCATION" | translate }}</span>
      <div id="google-map"></div>
    </div>

    <a href="https://maps.google.com/?q={{ reportData.latitude }},{{ reportData.longitude }}" target="_blank" class="map light-link"
      >GoogleMaps {{ reportData.location }}</a
    >
  </div>
</div>
