import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class PagesManagementService {

  constructor(
    private api: ApiService
  ) { }

  getPages() {
    return this.api.get(`categorypages`);
  }

  addPage(formData: any) {
    return this.api.post(`categorypages`, formData);
  }

  updatePage(formData: any) {
    return this.api.put(`categorypages`, formData);
  }

  getPage(id: number | string) {
    return this.api.get(`categorypages/${id}`);
  }

  deletePage(id: number | string ) {
    return this.api.delete(`categorypages/${id}`);
  }

  searchPage(search: string) {
    return this.api.get(`categorypages/search`, { searchTerm: search });
  }
}
