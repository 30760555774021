import { Component, OnInit } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { environment } from "../../../../environments/environment";
import { AppConstants } from "../../../shared/constants";
import { ReportsService } from "../../../shared/services/reports.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { forkJoin } from "rxjs";
import { ReportStatusService } from "../../../shared/services/report-status.service";
import { CategoriesService } from "../../../shared/services/categories.service";
import { ToastMessageTypes } from "../../../shared/enums/toastMessageTypes.enum";
import { BackofficeUserRoles } from "../../../shared/enums/roles.enum";
import { AuthService } from "../../../shared/services/auth/auth.service";
import { ReservesManagementService } from '../../../shared/services/reserves-management.service';
import { ReserveTypes } from '../../../shared/enums/reserveTypes.enum';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})
export class EditReportComponent implements OnInit {

  private reportId: any = '';
  public reportData: any = {};

  public categories: any = [];
  public statuses: any = [];
  public disabledStatusNames = AppConstants.disabledStatusNames;

  public reportCategories: number[] = [];

  public reportStatus: number = 0;
  public reportNote: string = '';

  public gallerySettings = {
    counter: true,
    videojs: true,
    plugins: [lgZoom, lgVideo]
  };

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng
  }
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  infoWindow: google.maps.InfoWindow | undefined;

  constructor(
    private reportsService: ReportsService,
    private reportStatusesService: ReportStatusService,
    private reservesService: ReservesManagementService,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    // get content item data
    this.reportId = this.route.snapshot.paramMap.get('id');

    if (this.reportId) {
      let requests = [
        this.reportsService.getReportData(this.reportId),
        this.categoriesService.getCategories(),
        this.reportStatusesService.getReportStatuses(),
        this.reservesService.getReserves()
      ];

      forkJoin(requests).subscribe({
        next: (responses: any) => {

          // report data

          this.reportData = responses[0];

          this.reportData.categories = this.reportData.categories.sort((a: { order: number; }, b: { order: number; }) => (a.order > b.order) ? 1 : -1)

          // this.inspectorReportService.inspectorName = resp.inspectorFullName;
          this.reportStatus = responses[0].reportStatusName;

          let loader = new Loader({
            apiKey: environment.googleMapsAPIKey,
            language: environment.googleMapsAPILanguage
          });

          let center: any = null;
          if (responses[0].latitude && responses[0].longitude) {
            center = {
              lat: responses[0].latitude,
              lng: responses[0].longitude
            }
          }

          loader.load().then(() => {
            this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
              center,
              zoom: this.zoom,
              styles: AppConstants.googleMap.styles
            });

            // draw polygons
            responses[3].forEach((reserve: any) => this.drawReserve(reserve));

            // show marker
            new google.maps.Marker({
              position: center,
              map: this.map
            });
          });

          // categories
          this.categories = responses[1];
          this.reportCategories[0] = this.checkIfChanged(0) ? -1 : (this.reportData?.categories?.[0]?.id ?? null);
          this.reportCategories[1] = this.checkIfChanged(1) ? -1 : (this.reportData?.categories?.[1]?.id ?? null);
          this.reportCategories[2] = this.checkIfChanged(2) ? -1 : (this.reportData?.categories?.[2]?.id ?? null);

          // report statuses
          this.statuses = responses[2];
          this.reportStatus = this.reportData.reportStatusId;

        },
        error: (error) => {}
      });
    }
  }

  drawReserve(reserve: any, accented = false) {
    if (reserve.hasRadius || reserve.radius > 0) { // ToDo: remove reserve.radius > 0
      // circle
      const circle = new google.maps.Circle({
        center: {
          lat: reserve.centerLatitude ?? 0,
          lng: reserve.centerLongitude ?? 0
        },
        radius: reserve.radius,
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: accented ? 100 : (reserve.type === ReserveTypes.REGION ? 1 : 2),
        map: this.map
      });
      circle.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })
    } else {
      // polygon
      const coords = reserve.coords ? reserve.coords
        .replace(new RegExp('lat', 'g'), '"lat"')
        .replace(new RegExp('lng', 'g'), '"lng"') : '';

      const polygon = new google.maps.Polygon({
        paths: JSON.parse(<string>(`[${coords}]`)),
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map
      });

      polygon.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })
    }
  }

  showInfoWindow(reserve: any, event: any) {
    this.infoWindow?.close();

    this.infoWindow = new google.maps.InfoWindow({
      content: `<h2>${reserve.name}</h2><p>Type: ${reserve.type}</p><p>${reserve.description??''}</p>`
    });

    this.infoWindow.open({
      map: this.map
    });

    this.infoWindow.setPosition(event.latLng.toJSON())

  }


  get isAdmin(): boolean {
    return this.auth.userProfile.getValue().roleId === BackofficeUserRoles.ADMIN;
  }

  statusName(id: number): string {
    return this.statuses.find((status: { id: number; }) => status.id === id)?.name ?? '';
  }

  checkIfChanged(order: number) {
    const category = this.reportData?.categories?.find((category: { order: number; }) => category.order === order);
    if (!category) return false;

    // check if this category was removed at all
    if (!this.categories.find((cat: { id: number; }) => cat.id === category.id)) return true;

    // if we have category with such id. it means 2 case - renamed or not
    return category && this.categories.find((cat: { id: number; name: string; }) => cat.id === category.id && cat.name !== category.name);
  }

  isVideo(fileName: string): boolean {
    return /^https?:\/\/(.+\/)+.+(\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4))$/i.test(fileName);
  }

  getVideoFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }

  getDataVideo(fileName: string=''): string  {
    return '{"source": [{"src": "' + fileName + '", "type":"video/'+ this.getVideoFileExtension(fileName) +'"}], "attributes": {"preload": true, "controls": true, "autoplayVideoOnSlide": false}}';
  }

  saveReport() {
    let data: any = {};
    data.reportStatusId = this.reportStatus;

    let categories: any[] = [];

    if (!!this.reportCategories[0] && this.reportCategories[0] === -1) {
      // user wants to use previous value
      categories.push(this.reportData?.categories?.find((category: { order: number; }) => category.order === 0));
    } else if (!!this.reportCategories[0] && this.reportCategories[0] > -1) {
      // user wants to update value
      categories.push({
        order: 0,
        id: this.reportCategories[0],
        name: this.categories?.find((category: {id: number; }) => category.id === this.reportCategories[0])?.name
      });
    } else {
      // user wants to unset value
    }

    if (!!this.reportCategories[1] && this.reportCategories[1] === -1) {
      // user wants to use previous value
      categories.push(this.reportData?.categories?.find((category: { order: number; }) => category.order === 1));
    } else if (!!this.reportCategories[1] && this.reportCategories[1] > -1) {
      // user wants to update value
      categories.push({
        order: 1,
        id: this.reportCategories[1],
        name: this.categories?.find((category: {id: number; }) => category.id === this.reportCategories[1])?.name
      });
    } else {
      // user wants to unset value
      delete categories[1];
    }

    if (!!this.reportCategories[2] && this.reportCategories[2] === -1) {
      // user wants to use previous value
      categories.push(this.reportData?.categories?.find((category: { order: number; }) => category.order === 2));
    } else if (!!this.reportCategories[2] && this.reportCategories[2] > -1) {
      // user wants to update value
      categories.push({
        order: 2,
        id: this.reportCategories[2],
        name: this.categories?.find((category: {id: number; }) => category.id === this.reportCategories[2])?.name
      });
    } else {
      // user wants to unset value
      delete categories[2];
    }

    let categoryIsSet: boolean[] = [];

    categoryIsSet[0] = categories.find((category: {order: number;}) => category.order === 0);
    categoryIsSet[1] = categories.find((category: {order: number;}) => category.order === 1);
    categoryIsSet[2] = categories.find((category: {order: number;}) => category.order === 2);

    if (!categoryIsSet[0]) {
      // at least one category should be set
      this.toast.show(this.translate.instant('PAGES.REPORTS.ERRORS.TOP_CATEGORY_REQUIRED'), ToastMessageTypes.ERROR);
      return;
    }

    if (categoryIsSet[2] && !categoryIsSet[1]) {
      // second category can't be unset
      this.toast.show(this.translate.instant('PAGES.REPORTS.ERRORS.MIDDLE_CATEGORY_REQUIRED'), ToastMessageTypes.ERROR);
      return;
    }

    if (!!this.reportNote) {
      data.note = this.reportNote;
    }

    data.categories = categories;

    this.reportsService.updateReport(this.reportId, data).subscribe({
      next: (resp: any) => {
        this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_REPORT'));
        this.router.navigate(['/reports-tickets']);
      },
      error: (error) => {},
    });
  }


}
