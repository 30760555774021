import { Component, Input, OnInit } from '@angular/core';
import { CategoriesService } from "../../services/categories.service";

@Component({
  selector: 'app-categories-dropdown',
  templateUrl: './categories-dropdown.component.html',
  styleUrls: ['./categories-dropdown.component.scss']
})
export class CategoriesDropdownComponent implements OnInit {

  @Input() categoryId: number | undefined;
  public categories: any;

  constructor(
    private categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    this.categoriesService.getCategories()
      .subscribe({
        next: (resp: any) => {

          this.categories = resp;
        },
        error: (error) => {}
      })
  }

}
