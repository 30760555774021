import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { BackofficeUsersService } from "../../../shared/services/backoffice-users.service";
import {forkJoin} from "rxjs";
import {ReservesManagementService} from "../../../shared/services/reserves-management.service";
import {CategoriesService} from "../../../shared/services/categories.service";
import {BackofficeUserRoles} from "../../../shared/enums/roles.enum";

@Component({
  selector: 'app-view-backoffice-user',
  templateUrl: './view-backoffice-user.component.html',
  styleUrls: ['./view-backoffice-user.component.scss']
})
export class ViewBackofficeUserComponent implements OnInit {

  private userId: any;
  public user: any;

  public backofficeUserRoles = BackofficeUserRoles;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private backofficeUsersService: BackofficeUsersService,
    private reservesService: ReservesManagementService,
    private categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    // get user data
    this.userId = this.route.snapshot.paramMap.get('id');

    if (this.userId) {

      // get reserves and categories
      let requests = [
        this.backofficeUsersService.getBackofficeUser(this.userId),
        this.categoriesService.getCategories(),
        this.reservesService.getReserves()
      ];

      forkJoin(requests).subscribe({
        next: (responses: any) => {
          this.user = responses[0];

          const categories = responses[1];
          this.user.categories = this.user.categoriesIds?.map(
            (userCategoryId: number) => categories.find((category: any) => category.id === userCategoryId).name ?? ''
          ).join(', ');

          const reserves = responses[2];
          this.user.reserves = this.user.polygonsIds?.map(
            (userReserveId: number) => reserves.find((reserve: any) => reserve.id === userReserveId).name ?? ''
          ).join(', ');
        },
        error: (error) => {}
      });
    }
  }

  editBackofficeUser() {
    this.router.navigate([`backoffice-users-edit/${this.userId}`]);
  }

}
