<div class="page-header">
  <h1>{{'PAGES.PAGES_MANAGEMENT.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddPage()">{{'BUTTONS.ADD_PAGE' | translate}}</button>
</div>
<div class="page-content top-breadcrumbs">
  <div class="data-table">

    <table mat-table [dataSource]="pages" multiTemplateDataRows matSortActive="title" matSortDirection="asc" class="mat-elevation-z8 data" matSort>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">{{'COMMON.ACTIONS' | translate}}</th>
        <td mat-cell *matCellDef="let page" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/page-edit/{{page.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemovePage($event, page)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-100">{{'PAGES.PAGES_MANAGEMENT.ID' | translate}}</th>
        <td mat-cell *matCellDef="let page" class="column-100">
          <a class="link" routerLink="/page-view/{{page.id}}" [class.main]="page.main"> {{page.main ? '❖':''}} {{zeroPad(page.id, 6)}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-200">{{'PAGES.PAGES_MANAGEMENT.PAGE_TITLE' | translate}}</th>
        <td mat-cell *matCellDef="let page" class="column-200">
          <span matBadge="{{shortenedCategoryType(page.categoryType)}}" matBadgeOverlap="false" class="category-type" *ngIf="page.main">{{page.title}}</span>
          <ng-container *ngIf="!page.main">{{page.title}}</ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="subTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.PAGES_MANAGEMENT.PAGE_SUBTITLE' | translate}}</th>
        <td mat-cell *matCellDef="let page"> {{page.subTitle}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let page; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>

  </div>
</div>
