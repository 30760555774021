import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class ReportStatusService {

  constructor(
    private api: ApiService
  ) { }

  getReportStatuses() {
    return this.api.get(`reportstatuses`);
  }

  getReportStatusesByInspector(key: string) {
    return this.api.get(`reportstatuses/inspector/${key}`);
  }

  addReportStatus(data: any) {
    return this.api.post(`reportstatuses`, data);
  }

  updateReportStatus(id: number, data: any) {
    return this.api.put(`reportstatuses/${id}`, data);
  }

  getReportStatus(id: number) {
    return this.api.get(`reportstatuses/${id}`);
  }

  removeReportStatus(id: number) {
    return this.api.delete(`reportstatuses/${id}`);
  }

}
