import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth/auth.service";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ToastService} from "../../shared/services/toast.service";
import { MustMatch } from "../../shared/helpers/must-match.validator";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss']
})
export class SetupPasswordComponent implements OnInit {

  invitationId: string = '';
  public form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private fb: FormBuilder,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      pin: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}')
      ]], // 8+, 1U+1l+1d
      repeatPassword: ['', Validators.required]
    }, { validator: MustMatch('password', 'repeatPassword')});
  }

  ngOnInit(): void {
    this.invitationId = '' + this.route.snapshot.paramMap.get('id');

    // send the request to create a PIN code for this user if invitationId is valid
    this.auth.pingInvitationId(this.invitationId).subscribe({
      error: () => {
        // incorrect invitation ID - go to the login page directly
        this.router.navigate(['/login']);
      }
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  setupPassword() {

    if (this.form.valid) {

      const pinCode = this.form.get('pin')?.value;
      const password = this.form.get('password')?.value;
      const repeatPassword = this.form.get('repeatPassword')?.value;

      this.auth.setupPassword({ invitationId: this.invitationId, pinCode, password, repeatPassword }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.PASSWORD_SET'));

          // get profile info
          this.auth.profile()
            .subscribe({
              next: (resp: any) => {
                this.auth.saveUserToLocalStorage(resp);
                this.router.navigate(['/']);
              },
              error: (error) => {}
            })
        },
        error: (error) => {},
      });

    }
  }

}
