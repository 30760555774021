import { Injectable } from '@angular/core';
import { PaginatorClass, PaginatorModel } from "../models/paginator.model";
import { SortingClass, SortingModel } from "../models/sorting.model";

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  public backofficeUsersPaginator: PaginatorModel;
  public contentPaginator: PaginatorModel;
  public pagesPaginator: PaginatorModel;
  public reportCategoriesPaginator: PaginatorModel;
  public reportStatusesPaginator: PaginatorModel;
  public reservesPaginator: PaginatorModel;
  public usersPaginator: PaginatorModel;

  public reportTicketsPaginator: PaginatorModel;
  public reportTicketsSorting: SortingModel;
  public reportTicketsFilters: any;

  constructor() {

    this.backofficeUsersPaginator = new PaginatorClass();
    this.contentPaginator = new PaginatorClass();
    this.pagesPaginator = new PaginatorClass();
    this.reportCategoriesPaginator = new PaginatorClass();
    this.reportStatusesPaginator = new PaginatorClass();
    this.reservesPaginator = new PaginatorClass();
    this.usersPaginator = new PaginatorClass();

    this.reportTicketsPaginator = new PaginatorClass();
    this.reportTicketsSorting = new SortingClass();

    this.reportTicketsFilters = {};

  }
}
