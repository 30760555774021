import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import moment from "moment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private api: ApiService
  ) { }

  getReportTickets(filterData: any) {
    return this.api.get(`reports`, filterData);
  }

  getReportSendingLogs(id: number): Observable<any> {
    return this.api.get(`reports/send/${id}`);
  }

  getReportLogs(id: number, inspectorKey = ''): Observable<any> {
    return !!inspectorKey ? this.api.get(`reports/log/${id}?inspectorKey=${inspectorKey}`) : this.api.get(`reports/log/${id}`);
  }

  getMapReportTickets(filterData: any) {
    return this.api.get(`reports/map`, filterData);
  }

  getReportData(id: number) {
    return this.api.get(`reports/${id}`);
  }

  updateReport(id: number, data: any) {
    return this.api.put(`reports/${id}`, data);
  }

  updateReportByInspector(id: number, data: any) {
    return this.api.put(`reports/${id}`, data);
  }

  removeReport(id: number) {
    return this.api.delete(`reports/${id}`);
  }

  exportToExcel(filters: any) {
    let params = `?`;
    if (filters.dateRange?.startDate) params += `startDate=${moment(filters.dateRange?.startDate).utc().format()}&`;
    if (filters.dateRange?.endDate) params += `endDate=${moment(filters.dateRange?.endDate).utc().format()}&`;
    if (filters.reportTypes?.length > 0) params += `reportTypes=` + filters.reportTypes.join('&reportTypes=');
    params = params.replace(/[&?]*$/, "");

    return this.api.open(`reports/export/excel`, params);
  }

}
