import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { forkJoin, Subscription } from "rxjs";
import { ReserveModel } from "../../../shared/models/reserve.model";
import { ReservesManagementService } from "../../../shared/services/reserves-management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppConstants } from "../../../shared/constants";
import { Loader } from "@googlemaps/js-api-loader";
import { environment } from "../../../../environments/environment";
import { ReserveTypes } from "../../../shared/enums/reserveTypes.enum";
import { ConfirmComponent, ConfirmModel } from "../../modal-dialogs/confirm/confirm.component";
import { ToastService } from "../../../shared/services/toast.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-view-reserve',
  templateUrl: './view-reserve.component.html',
  styleUrls: ['./view-reserve.component.scss']
})
export class ViewReserveComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  public searchReserveText: string = '';

  public form: FormGroup;

  public reserveId: number | undefined;
  public reserve: ReserveModel | undefined;

  public existingReserves: ReserveModel[] = [];

  private reserveIdSubscription: Subscription | undefined;

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng
  }
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  infoWindows: google.maps.InfoWindow | undefined;

  drawnReserves: any = [];

  public reserveTypes = ReserveTypes;

  constructor(
    private fb: FormBuilder,
    private reservesService: ReservesManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private dialogRef: MatDialogRef<ViewReserveComponent>,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      currentReserve: ['']
    });
  }

  ngOnInit(): void {

    let loader = new Loader({
      apiKey: environment.googleMapsAPIKey,
      language: environment.googleMapsAPILanguage
    });

    loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
        center: this.center,
        zoom: this.zoom,
        styles: AppConstants.googleMap.styles
      });

      // get content item data
      this.reserveIdSubscription = this.route.params.subscribe(routeParams => {
        this.infoWindows?.close();

        // get page's data
        this.reserveId = +routeParams['id'];
        this.getData();
      });
    })
  }

  ngOnDestroy() {
    this.reserveIdSubscription?.unsubscribe();
  }

  getData() {
    let requests = [
      this.reservesService.getReserve(this.reserveId),
      this.reservesService.getReserves()
    ];

    forkJoin(requests).subscribe({
      next: (responses: any) => {
        // responses[0] - reserveInfo info
        this.reserve = responses[0];

        // responses[1] - all reserves
        const existingReserves = responses[1].sort((
          a: { name: { toLowerCase: () => string; }; },
          b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        this.existingReserves = existingReserves;

        // update map
        this.updateMap();
      },
      error: (error) => {}
    });
  }

  setFocus() {
    this.searchInput?.nativeElement.focus();
  }

  updateMap() {
    // 1. Clear the map
    this.drawnReserves.forEach((reserve: google.maps.Circle | google.maps.Polygon) => reserve.setMap(null));
    this.drawnReserves = [];

    const existingReserves = this.existingReserves.filter((reserve) => reserve.id !== this.reserveId);
    const existingRegions = existingReserves.filter((reserve) => reserve.type === ReserveTypes.REGION);

    // 2. Prepare and draw everything we need
    existingReserves.forEach((reserve) => this.drawReserve(reserve));

    //
    // // logic: show regions
    // if (this.reserve?.type === ReserveTypes.REGION) {
    //   existingRegions.forEach((region: ReserveModel) => this.drawReserve(region));
    // }
    //
    // // logic: show parent region and all natureReserves in it
    // if (this.reserve?.type === ReserveTypes.NATURE_RESERVE) {
    //   if (this.reserve.parentId) {
    //
    //     // 1. draw region
    //     // const region = existingRegions.find((region) =>
    //     //   region.id === this.reserve?.parentId);
    //     // this.drawReserve(region);
    //
    //     existingRegions.forEach((region) => this.drawReserve(region));
    //
    //     // 2. draw all existing reserves inside
    //     // // if we want to display reserves only for same region
    //     // const reserves = existingReserves.filter((reserve) =>
    //     //   reserve.parentId === this.reserve?.parentId);
    //
    //     const reserves = existingReserves.filter((reserve) => reserve.parentId);
    //     reserves.forEach((reserve) => this.drawReserve(reserve));
    //   }
    // }

    // show myself
    this.drawReserve(this.reserve, true);

    this.form.get('currentReserve')?.patchValue(this.reserveId);
  }

  drawReserve(reserve: any, accented = false) {
    if (reserve.hasRadius || reserve.radius > 0) { // ToDo: remove reserve.radius > 0
      // circle
      const circle = new google.maps.Circle({
        center: {
          lat: reserve.centerLatitude ?? 0,
          lng: reserve.centerLongitude ?? 0
        },
        radius: reserve.radius,
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: accented ? 100 : (reserve.type === ReserveTypes.REGION ? 1 : 2),
        map: this.map
      });
      circle.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })

      this.drawnReserves.push(circle);
    } else {
      // polygon
      const coords = reserve.coords ? reserve.coords
        .replace(new RegExp('lat', 'g'), '"lat"')
        .replace(new RegExp('lng', 'g'), '"lng"') : '';

      const polygon = new google.maps.Polygon({
        paths: JSON.parse(<string>(`[${coords}]`)),
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map
      });

      polygon.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })

      this.drawnReserves.push(polygon);
    }
  }

  showInfoWindow(reserve: any, event: any) {
    this.infoWindows?.close();

    this.infoWindows = new google.maps.InfoWindow({
      content: `<h2>${reserve.name}</h2><p>Type: ${reserve.type}</p><p>${reserve.description??''}</p>`
    });

    this.infoWindows.open({
      map: this.map
    });

    this.infoWindows.setPosition(event.latLng.toJSON())

  }

  confirmRemoveReserve(event: Event, reserve: any) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RESERVE'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove reserve
        this.reservesService.deleteReserve(reserve.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_RESERVE'));
              this.router.navigate([`/reserves-management`], {replaceUrl: true});
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  gotoReserve() {
    this.router.navigate([`/reserve-view/${this.form.get('currentReserve')?.value}`], {replaceUrl: true});
  }

  prepareForView(coords: string | undefined) {
    return coords && coords.replace(/\s/g,'').replace(/},{/g, '},<br/>{');
  }

}
