import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from './angular-material.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './shared/guards/auth-guard';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorInterceptor } from "./shared/interceptors/error.interceptor";
import { ToastrModule } from 'ngx-toastr';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HeaderComponent } from "./shared/header/header.component";
import { UserProfileToolbarComponent } from "./shared/user-profile-toolbar/user-profile-toolbar.component";
import { ReportTicketImageActionsComponent } from "./components/menus/report-ticket-image-actions/report-ticket-image-actions.component";

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { GoogleMapsModule } from '@angular/google-maps';
// import { GalleryModule, Gallery } from  'ng-gallery';
// import { LightboxModule } from  'ng-gallery/lightbox';
// import { GALLERY_CONFIG } from 'ng-gallery';
import { CustomCalendarHeaderComponent } from "./shared/custom-calendar-header/custom-calendar-header.component";
import { LightgalleryModule } from 'lightgallery/angular';

import { SafePipe } from "./shared/pipes/safe.pipe";
import { SearchPipe } from "./shared/pipes/search.pipe";
import { ReplaceLineBreaksPipe } from "./shared/pipes/replace-line-breaks.pipe";

import { NgxEditorModule } from 'ngx-editor';

import { LoginComponent } from './components/login/login.component';
import { LoginPinComponent } from "./components/login-pin/login-pin.component";
import { SetupPasswordComponent } from "./components/setup-password/setup-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";

import { ConfirmComponent } from "./components/modal-dialogs/confirm/confirm.component";
import { Page404Component } from "./components/page404/page404.component";

import { LoadingInterceptor } from "./shared/interceptors/loading.interceptor";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";

import { TranslationResolver } from "./shared/resolvers/translation.resolver";

import { ReportCategoriesComponent } from "./components/report-categories/report-categories.component";
import { AddCategoryComponent } from "./components/report-categories/add-category/add-category.component";
import { EditCategoryComponent } from "./components/report-categories/edit-category/edit-category.component";
import { ViewCategoryComponent } from "./components/report-categories/view-category/view-category.component";

import { BackofficeUsersComponent } from "./components/backoffice-users/backoffice-users.component";
import { AddBackofficeUserComponent } from "./components/backoffice-users/add-backoffice-user/add-backoffice-user.component";
import { ViewBackofficeUserComponent } from "./components/backoffice-users/view-backoffice-user/view-backoffice-user.component";
import { EditBackofficeUserComponent } from "./components/backoffice-users/edit-backoffice-user/edit-backoffice-user.component";

import { UsersComponent } from "./components/users/users.component";
import { AddUserComponent } from "./components/users/add-user/add-user.component";
import { ViewUserComponent } from "./components/users/view-user/view-user.component";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";

import { ContentComponent } from "./components/content/content.component";
import { AddContentComponent } from "./components/content/add-content/add-content.component";
import { EditContentComponent } from "./components/content/edit-content/edit-content.component";
import { ViewContentComponent } from "./components/content/view-content/view-content.component";

import { ReportStatusManagementComponent } from "./components/report-status-management/report-status-management.component";
import { AddReportStatusComponent } from "./components/report-status-management/add-report-status/add-report-status.component";
import { EditReportStatusComponent } from "./components/report-status-management/edit-report-status/edit-report-status.component";
import { ViewReportStatusComponent } from "./components/report-status-management/view-report-status/view-report-status.component";

import { PagesManagementComponent } from "./components/pages-management/pages-management.component";
import { AddPageComponent } from "./components/pages-management/add-page/add-page.component";
import { EditPageComponent } from "./components/pages-management/edit-page/edit-page.component";
import { ViewPageComponent } from "./components/pages-management/view-page/view-page.component";

import { CategoriesDropdownComponent} from "./shared/components/categories-dropdown/categories-dropdown.component";
import { BreadcrumbsComponent } from "./shared/components/breadcrumbs/breadcrumbs.component";
import { PagePreviewComponent } from "./shared/components/page-preview/page-preview.component";

import { ConfirmDialogComponent } from "./shared/components/confirm-dialog/confirm-dialog.component";

import { ReservesManagementComponent } from "./components/reserves-management/reserves-management.component";
import { AddReserveComponent } from "./components/reserves-management/add-reserve/add-reserve.component";
import { EditReserveComponent } from "./components/reserves-management/edit-reserve/edit-reserve.component";
import { ViewReserveComponent } from "./components/reserves-management/view-reserve/view-reserve.component";

import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';

import { MessagesManagementComponent } from "./components/messages-management/messages-management.component";
import { HomePageContactsComponent } from "./components/home-page-contacts/home-page-contacts.component";
import { InspectorReportComponent } from "./components/inspector-report/inspector-report.component";

import { ReportsManagementComponent } from "./components/reports-management/reports-management.component";
import { ViewReportComponent } from "./components/reports-management/view-report/view-report.component";
import { EditReportComponent } from "./components/reports-management/edit-report/edit-report.component";
import { AppVersionComponent } from "./components/app-version/app-version.component";
import { EditableModule } from '@ngneat/edit-in-place';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    SafePipe,
    SearchPipe,
    ReplaceLineBreaksPipe,
    AppComponent,
    HeaderComponent,
    LoginComponent,
    LoginPinComponent,
    UserProfileToolbarComponent,
    ReportTicketImageActionsComponent,
    BackofficeUsersComponent,
    AddBackofficeUserComponent,
    EditBackofficeUserComponent,
    ViewBackofficeUserComponent,
    UsersComponent,
    CustomCalendarHeaderComponent,
    SetupPasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    Page404Component,
    ConfirmComponent,
    ReportCategoriesComponent,
    AddCategoryComponent,
    ViewCategoryComponent,
    EditCategoryComponent,
    AddUserComponent,
    ViewUserComponent,
    EditUserComponent,
    ContentComponent,
    AddContentComponent,
    EditContentComponent,
    ViewContentComponent,
    ReportStatusManagementComponent,
    AddReportStatusComponent,
    EditReportStatusComponent,
    ViewReportStatusComponent,
    PagesManagementComponent,
    AddPageComponent,
    EditPageComponent,
    ViewPageComponent,
    ConfirmDialogComponent,
    ReservesManagementComponent,
    AddReserveComponent,
    EditReserveComponent,
    ViewReserveComponent,
    MessagesManagementComponent,
    HomePageContactsComponent,

    InspectorReportComponent,
    ReportsManagementComponent,
    ViewReportComponent,
    EditReportComponent,

    CategoriesDropdownComponent,
    BreadcrumbsComponent,
    PagePreviewComponent,
    AppVersionComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      // defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    AngularMaterialModule,
    NgScrollbarModule,
    GoogleMapsModule,
    // GalleryModule,
    // LightboxModule,
    LightgalleryModule,
    MatDialogModule,
    NgxMatColorPickerModule,
    NgxDaterangepickerMd.forRoot(),
    NgxEditorModule,
    EditableModule,
    MatSlideToggleModule,
  ],
  providers: [
    AuthGuard,
    // {
    //   provide: GALLERY_CONFIG,
    //   useValue: {
    //     dots: true,
    //     thumb: false,
    //     counter: false
    //   }
    // },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    TranslationResolver,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
