import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class BackofficeUsersService {

  constructor(
    private api: ApiService
  ) { }

  getBackofficeUsers() {
    return this.api.get(`backofficeusers`);
  }

  getBackofficeUser(id: number | string) {
    return this.api.get(`backofficeusers/${id}`);
  }

  addBackofficeUser(data: any) {
    return this.api.post(`backofficeusers`, data);
  }

  updateBackofficeUser(data: any) {
    return this.api.put(`backofficeusers`, data);
  }

  removeBackofficeUser(id: number | string) {
    return this.api.delete(`backofficeusers/${id}`);
  }

  getRoles() {
    return this.api.get(`roles`, { withCredentials: true } );
  }
}
