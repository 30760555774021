import { Component, OnInit } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { AppConstants } from '../../../shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment';
import { ReportsService } from '../../../shared/services/reports.service';
import { forkJoin, of } from 'rxjs';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { BackofficeUserRoles } from '../../../shared/enums/roles.enum';
import { ReservesManagementService } from '../../../shared/services/reserves-management.service';
import { ReserveTypes } from '../../../shared/enums/reserveTypes.enum';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
})
export class ViewReportComponent implements OnInit {
  private reportId: any = '';
  public reportData: any = {};
  public sendLogs: any[] = [];
  public sendingLogsTableDisplayedColumns: string[] = ['sentTo', 'sentBy', 'sentStatus'];

  public logs: any[] = [];
  public logsTableDisplayedColumns: string[] = ['createdDate', 'backOfficeUserFullName', 'reportStatusName', 'note'];

  public reportStatus: string = '';

  public gallerySettings = {
    counter: true,
    videojs: true,
    plugins: [lgZoom, lgVideo],
  };

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng,
  };
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  infoWindow: google.maps.InfoWindow | undefined;

  constructor(
    private reportsService: ReportsService,
    private reservesService: ReservesManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    const isAdmin = this.auth.userProfile.getValue().roleId === BackofficeUserRoles.ADMIN;

    // get content item data
    this.reportId = this.route.snapshot.paramMap.get('id');

    if (this.reportId) {
      const requests = [
        this.reportsService.getReportData(this.reportId),
        isAdmin ? this.reportsService.getReportSendingLogs(this.reportId) : of([]),
        this.reservesService.getReserves(),
        this.reportsService.getReportLogs(this.reportId),
      ];
      forkJoin(requests).subscribe(([reportResp, sendingResp, reservesResp, reportLogsResp]) => {
        this.reportData = reportResp;
        this.sendLogs = sendingResp;

        this.logs = reportLogsResp.sort(function (a: { createdDate: string | number | Date }, b: { createdDate: string | number | Date }) {
          const keyA = new Date(a.createdDate),
            keyB = new Date(b.createdDate);
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        });
        this.reportData.categories = this.reportData.categories.sort(
          (
            a: { order: number },
            b: {
              order: number;
            }
          ) => (a.order > b.order ? 1 : -1)
        );
        this.reportStatus = reportResp.reportStatusName;

        let loader = new Loader({
          apiKey: environment.googleMapsAPIKey,
          language: environment.googleMapsAPILanguage,
        });

        let center: any = null;
        if (reportResp.latitude && reportResp.longitude) {
          center = {
            lat: reportResp.latitude,
            lng: reportResp.longitude,
          };
        }

        loader.load().then(() => {
          this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
            center,
            zoom: this.zoom,
            styles: AppConstants.googleMap.styles,
          });

          // draw polygons
          reservesResp.forEach((reserve: any) => this.drawReserve(reserve));

          // draw position's marker
          new google.maps.Marker({
            position: center,
            map: this.map,
          });
        });
      });
    }
  }

  drawReserve(reserve: any, accented = false) {
    if (reserve.hasRadius || reserve.radius > 0) {
      // ToDo: remove reserve.radius > 0
      // circle
      const circle = new google.maps.Circle({
        center: {
          lat: reserve.centerLatitude ?? 0,
          lng: reserve.centerLongitude ?? 0,
        },
        radius: reserve.radius,
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: accented ? 100 : reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map,
      });
      circle.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      });
    } else {
      // polygon
      const coords = reserve.coords ? reserve.coords.replace(new RegExp('lat', 'g'), '"lat"').replace(new RegExp('lng', 'g'), '"lng"') : '';

      const polygon = new google.maps.Polygon({
        paths: JSON.parse(<string>`[${coords}]`),
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map,
      });

      polygon.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      });
    }
  }

  showInfoWindow(reserve: any, event: any) {
    this.infoWindow?.close();

    this.infoWindow = new google.maps.InfoWindow({
      content: `<h2>${reserve.name}</h2><p>Type: ${reserve.type}</p><p>${reserve.description ?? ''}</p>`,
    });

    this.infoWindow.open({
      map: this.map,
    });

    this.infoWindow.setPosition(event.latLng.toJSON());
  }

  isVideo(fileName: string): boolean {
    return /^https?:\/\/(.+\/)+.+(\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4))$/i.test(fileName);
  }

  getVideoFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }

  getDataVideo(fileName: string = ''): string {
    return (
      '{"source": [{"src": "' +
      fileName +
      '", "type":"video/' +
      this.getVideoFileExtension(fileName) +
      '"}], "attributes": {"preload": true, "controls": true, "autoplayVideoOnSlide": false}}'
    );
  }

  editReport() {
    this.router.navigate([`report-edit/${this.reportId}`]);
  }
}
