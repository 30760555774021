import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class InspectorReportService {

  public currentInspectorName: string = '';

  constructor(
    private api: ApiService
  ) { }

  getReportData(key: string) {
    return this.api.get(`reports/inspectorkey?inspectorKey=${key}`);
  }

  updateReport(id: number, data: any) {
    return this.api.put(`reports/${id}?inspectorKey=${data.inspectorKey}`, data);
  }
}
