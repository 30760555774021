import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private route: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf('/token/refresh') > -1) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        // if we have 401 but not on the login page and not trying to get token sending PIN
        if (error.status === 401 && req.url.indexOf('/login') === -1 && req.url.indexOf('/token') === -1) {
          return this.handle401Error(req, next, error);
        } else {
          return throwError(() => error);
        }
      })
    )
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler, originalError: any) {
    // try to refresh the token
    return this.authService.refreshCookie().pipe(
      switchMap(() => {
        // proceed to the previous request
        return next.handle(req);
      }),
      catchError((error) => {
        // can't refresh the token. need to re-login, clear all saved data
        localStorage.removeItem('currently_logged_user');
        this.route.navigate(['/login']);
        return throwError(() => originalError);
      })
    );
  }
}
