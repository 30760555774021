import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { toHTML } from "ngx-editor";

export function valueCheckerValidator(whatToFind: string):  ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const searchTarget = typeof(control.value) === "string" ?
      control.value :
      toHTML(control.value).replace(/(<([^>]+)>)/gi, "");
    return searchTarget.includes(whatToFind) ? null : {};
  };
}
