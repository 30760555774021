import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConfirmDialogAnswers } from "../../enums/confirmDialogAnswers.enum";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(ConfirmDialogAnswers.YES);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(ConfirmDialogAnswers.NO);
  }

  onSaveAndExit(): void {
    // Close the dialog, return 'save'
    this.dialogRef.close(ConfirmDialogAnswers.SAVE_AND_EXIT);
  }

}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string
  ) {}
}
