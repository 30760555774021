import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastMessageTypes } from "../enums/toastMessageTypes.enum";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toast: ToastrService,
    private translate: TranslateService
  ) { }

  show(message: string, type: ToastMessageTypes = ToastMessageTypes.SUCCESS) {
    if ( type === ToastMessageTypes.SUCCESS ) this.toast.success(message);
    if ( type === ToastMessageTypes.ERROR ) this.toast.error(message, this.translate.instant('ERROR_MESSAGES.ERROR'), {enableHtml: true});
    if ( type === ToastMessageTypes.SHOW ) this.toast.show(message);
  }
}
