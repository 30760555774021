import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ToastService} from "../../../shared/services/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {ReportStatusService} from "../../../shared/services/report-status.service";

@Component({
  selector: 'app-add-report-status',
  templateUrl: './add-report-status.component.html',
  styleUrls: ['./add-report-status.component.scss']
})
export class AddReportStatusComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private reportStatusService: ReportStatusService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  createReportStatus() {
    if (this.form.valid) {

      const formData = this.form.value;
      this.reportStatusService.addReportStatus({
        name: formData.name
      }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_REPORT_STATUS'));
          this.router.navigate(['/report-status-management']);
        },
        error: (error) => {},
      });

    }

  }

}
