import { Component, OnInit } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PolygonTypes, ReserveTypes } from "../../../shared/enums/reserveTypes.enum";
import { environment } from "../../../../environments/environment";
import { AppConstants } from "../../../shared/constants";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastMessageTypes } from "../../../shared/enums/toastMessageTypes.enum";
import { ReserveModel } from "../../../shared/models/reserve.model";
import { ReservesManagementService } from "../../../shared/services/reserves-management.service";
import { Router } from "@angular/router";
import { conditionalValidator } from "../../../shared/helpers/conditional.validator";
import { firstValueFrom, Observable, Observer, of, Subscription } from "rxjs";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogAnswers } from "../../../shared/enums/confirmDialogAnswers.enum";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-add-reserve',
  templateUrl: './add-reserve.component.html',
  styleUrls: ['./add-reserve.component.scss']
})
export class AddReserveComponent implements OnInit {

  public form: FormGroup;

  public polygonType = '';
  public polygonTypes = PolygonTypes;

  public reserveTypes = ReserveTypes;

  private hasUnsavedData = false;
  private formChangeSubscription: Subscription | undefined;

  public existingRegions : ReserveModel[] = [];
  public existingReserves: ReserveModel[] = [];

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng
  }
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  circle: google.maps.Circle | undefined;
  polygon: google.maps.Polygon | undefined;

  infoWindows: google.maps.InfoWindow | undefined;

  drawnReserves: any = [];

  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private translate: TranslateService,
    private reservesService: ReservesManagementService,
    private router: Router,
    private dialogRef: MatDialogRef<AddReserveComponent>,
    private dialog: MatDialog
  ) {

    // let rgbFillColor = hexToRgb('#ff0000');
    // let rgbStrokeColor = hexToRgb('#ff0000');

    this.form = this.fb.group({
      name: ['', Validators.required],
      reserveType: ['', Validators.required],

      reserveParent: ['', conditionalValidator(
        () => this.form.get('reserveType')?.value === ReserveTypes.NATURE_RESERVE,
        Validators.required,
        'conditionalRegion'
      )],
      polygonType: ['', Validators.required],
      circleRadius: ['', conditionalValidator(
        () => this.polygonType === PolygonTypes.RADIUS,
        Validators.required,
        'conditionalRadius'
      )],
      circleCoordsLat: ['', conditionalValidator(
        () => this.polygonType === PolygonTypes.RADIUS,
        Validators.required,
        'conditionalLat'
      )],
      circleCoordsLng: ['', conditionalValidator(
        () => this.polygonType === PolygonTypes.RADIUS,
        Validators.required,
        'conditionalLng'
      )],

      polygonCoords: [ AppConstants.googleMap.polygon.initialCoords ],
      polygonCoordsJSON: ['', conditionalValidator(
        () => this.polygonType === PolygonTypes.POLYGON,
        Validators.required,
        'conditionalCoords'
      )],

      description: [],
      isVisible: []

      // strokeColor: [new Color(<number>rgbStrokeColor?.r, <number>rgbStrokeColor?.g, <number>rgbStrokeColor?.b)],
      // strokeOpacity: 0.8,
      // strokeWeight: 2,
      // fillColor: [new Color(<number>rgbFillColor?.r, <number>rgbFillColor?.g, <number>rgbFillColor?.b)],
      // fillOpacity: 0.35
    });

    this.formChangeSubscription = this.form.valueChanges.subscribe((value) => {
      this.hasUnsavedData = true;
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.hasUnsavedData) {
      return new Observable((observer: Observer<any>) => {

        const message = this.translate.instant('DIALOGS.CHANGES_ARE_NOT_SAVED');
        const dialogData = new ConfirmDialogModel(this.translate.instant('DIALOGS.CHANGES_ARE_NOT_SAVED_TITLE'), message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: dialogData,
          restoreFocus: false
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult === ConfirmDialogAnswers.YES) {
            observer.next(true);
          } else if (dialogResult === ConfirmDialogAnswers.NO) {
            observer.next(false);
          } else if (dialogResult === ConfirmDialogAnswers.SAVE_AND_EXIT) {
            this.addReserve(false).subscribe({
              next:value => {
                observer.next(value);
              }, error: err => {
                observer.next(false);
              }
            })
          } else {
            observer.next(false);
          }
        });
      });
    } else {
      return of(true);
    }
  }

  ngOnInit(): void {
    let loader = new Loader({
      apiKey: environment.googleMapsAPIKey,
      language: environment.googleMapsAPILanguage
    });

    loader.load().then(() => {
      this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
        center: this.center,
        zoom: this.zoom,
        styles: AppConstants.googleMap.styles
      });

      this.getExistingReserves();

    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.dirty && this.form.controls[controlName].touched && this.form.controls[controlName].hasError(errorName);
  }

  getExistingReserves() {
    this.reservesService.getReserves()
      .subscribe({
        next: (resp: any) => {
          let existingReserves = resp.sort((
            a: { name: { toLowerCase: () => string; }; },
            b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

          this.existingReserves = existingReserves;
          this.existingRegions = existingReserves.filter((region: { type: string; }) => region.type === ReserveTypes.REGION);

          // mark form with updated data as untouched yet
          this.hasUnsavedData = false;

        },
        error: (error) => {
        }
      })
  }

  switchToRadius() {
    this.polygonType = PolygonTypes.RADIUS;
    this.form.get('polygonType')?.patchValue(PolygonTypes.RADIUS);

    this.polygon?.setMap(null);
    this.polygon = undefined;

    // check if we have no added circle yet
    if (!this.circle) {

      const mapCenter = this.map?.getCenter()?.toJSON();

      this.circle = new google.maps.Circle({

        center: {
          lat: mapCenter?.lat ?? 0,
          lng: mapCenter?.lng ?? 0
        },
        radius: AppConstants.googleMap.circle.initialRadius,
        draggable: true,
        editable: true,
        zIndex: 100,

        map: this.map
      });

      this.form.get('circleCoordsLat')?.patchValue(mapCenter?.lat ?? 0);
      this.form.get('circleCoordsLng')?.patchValue(mapCenter?.lng ?? 0);
      this.form.get('circleRadius')?.patchValue(this.circle.getRadius());

      this.updateReserveStyles();

      this.circle.addListener('dragend', (ev: any) => {
        this.form.get('circleCoordsLat')?.patchValue(this.circle?.getCenter()?.lat());
        this.form.get('circleCoordsLng')?.patchValue(this.circle?.getCenter()?.lng());
      });

      this.circle.addListener('radius_changed', (ev: any) => {
        this.form.get('circleRadius')?.patchValue(this.circle?.getRadius());
      });

      this.circle.addListener('center_changed', (ev: any) => {
        this.form.get('circleCoordsLat')?.patchValue(this.circle?.getCenter()?.lat());
        this.form.get('circleCoordsLng')?.patchValue(this.circle?.getCenter()?.lng());
      })

      // this.circle = undefined;
    }
  }

  switchToPolygon() {
    this.polygonType = PolygonTypes.POLYGON;
    this.form.get('polygonType')?.patchValue(PolygonTypes.POLYGON);

    this.circle?.setMap(null);
    this.circle = undefined;

    // check if we have no added circle yet
    if (!this.polygon) {

      this.polygon = new google.maps.Polygon({
        paths: this.form.get('polygonCoords')?.value,

        draggable: true,
        editable: true,
        zIndex: 100,

        map: this.map
      });

      this.updateReserveStyles();

      this.polygon.addListener('click', (ev: any) => {
        this.getPolygonData();
      })

      this.polygon.addListener('dragend', (ev: any) => {
        this.getPolygonData();
      });

      google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
        this.getPolygonData();
      });

      google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
        this.getPolygonData();
      });

      this.form.get('polygonCoordsJSON')?.patchValue(JSON.stringify(this.form.get('polygonCoords')?.value)
        .replace(/["\]\[]/g, ''));
    }
  }

  getPolygonData() {
    const path = this.polygon?.getPath();
    if (path) {
      let newCoords = [];
      for (let i = 0; i < path.getLength(); i++) {
        newCoords.push(path.getAt(i).toJSON());
      }
      this.form.get('polygonCoords')?.patchValue(newCoords);
      this.form.get('polygonCoordsJSON')?.patchValue(JSON.stringify(this.form.get('polygonCoords')?.value).replace(/["\]\[]/g, ''));
    }
  }

  updateCircle() {
    if (this.circle) {
      this.circle.setCenter({lat: +this.form.get('circleCoordsLat')?.value, lng: +this.form.get('circleCoordsLng')?.value});
      this.circle.setRadius(+this.form.get('circleRadius')?.value);
    }
  }

  updatePolygon() {
    if (this.polygon) {
      try {
        const parsedCoords = JSON.parse(('[' + this.form.get('polygonCoordsJSON')?.value + ']')
          .replace(/lat/g, '"lat"')
          .replace(/lng/g, '"lng"'));
        if (parsedCoords) this.polygon.setPaths(parsedCoords);
      } catch {

        this.toast.show(this.translate.instant('ERROR_MESSAGES.INCORRECT_COORDINATES'), ToastMessageTypes.ERROR);
      }
    }
  }

  updateMap() {
    this.form.controls['reserveParent'].updateValueAndValidity();

    // 1. Clear the map
    this.drawnReserves.forEach((reserve: google.maps.Circle | google.maps.Polygon) => reserve.setMap(null));
    this.drawnReserves = [];

    // 2. Prepare and draw everything we need
    // logic: show regions
    if (this.form.get('reserveType')?.value === ReserveTypes.REGION) {
      this.existingRegions.forEach((region: ReserveModel) => this.drawReserve(region));
    }

    // logic: show parent region and all natureReserves in it
    if (this.form.get('reserveType')?.value === ReserveTypes.NATURE_RESERVE) {
      if (this.form.get('reserveParent')?.value) {
        // 1. draw region
        const region = this.existingRegions.find((region) =>
          region.id === this.form.get('reserveParent')?.value);

        this.drawReserve(region);

        // 2. draw all existing reserves inside
        const reserves = this.existingReserves.filter((reserve) =>
          reserve.parentId === this.form.get('reserveParent')?.value);

        reserves.forEach((reserve) => this.drawReserve(reserve));
      }
    }

    // 3. Apply styles
    this.updateReserveStyles();
  }

  drawReserve(reserve: any) {
    if (reserve.hasRadius) {
      // circle
      const circle = new google.maps.Circle({
        center: {
          lat: reserve.centerLatitude ?? 0,
          lng: reserve.centerLongitude ?? 0
        },
        radius: reserve.radius,
        strokeColor: reserve.strokeColor,
        strokeOpacity: reserve.strokeOpacity,
        strokeWeight: reserve.strokeWeight,
        fillColor: reserve.fillColor,
        fillOpacity: reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map
      });
      circle.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })

      this.drawnReserves.push(circle);
    } else {
      // polygon
      const coords = reserve.coords ? reserve.coords
        .replace(new RegExp('lat', 'g'), '"lat"')
        .replace(new RegExp('lng', 'g'), '"lng"') : '';

      const polygon = new google.maps.Polygon({
        paths: JSON.parse(<string>(`[${coords}]`)),
        strokeColor: reserve.strokeColor,
        strokeOpacity: reserve.strokeOpacity,
        strokeWeight: reserve.strokeWeight,
        fillColor: reserve.fillColor,
        fillOpacity: reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map
      });

      polygon.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      })

      this.drawnReserves.push(polygon);
    }
  }

  showInfoWindow(reserve: any, event: any) {
    this.infoWindows?.close();

    this.infoWindows = new google.maps.InfoWindow({
      content: `<h2>${reserve.name}</h2><p>Type: ${reserve.type}</p><p>${reserve.description??''}</p>`
    });

    this.infoWindows.open({
      map: this.map
    });

    this.infoWindows.setPosition(event.latLng.toJSON())

  }

  updateReserveStyles() {
    let strokeColor = AppConstants.googleMap.default.strokeColor;
    let strokeOpacity = AppConstants.googleMap.default.strokeOpacity;
    let strokeWeight = AppConstants.googleMap.default.strokeWeight;
    let fillColor = AppConstants.googleMap.default.fillColor;
    let fillOpacity = AppConstants.googleMap.default.fillOpacity;

    if (this.form.get('reserveType')?.value === ReserveTypes.REGION) {
      strokeColor = AppConstants.googleMap.region.strokeColor;
      strokeOpacity = AppConstants.googleMap.region.strokeOpacity;
      strokeWeight = AppConstants.googleMap.region.strokeWeight;
      fillColor = AppConstants.googleMap.region.fillColor;
      fillOpacity = AppConstants.googleMap.region.fillOpacity;
    }

    if (this.form.get('reserveType')?.value === ReserveTypes.NATURE_RESERVE) {
      strokeColor = AppConstants.googleMap.natureReserve.strokeColor;
      strokeOpacity = AppConstants.googleMap.natureReserve.strokeOpacity;
      strokeWeight = AppConstants.googleMap.natureReserve.strokeWeight;
      fillColor = AppConstants.googleMap.natureReserve.fillColor;
      fillOpacity = AppConstants.googleMap.natureReserve.fillOpacity;
    }

    if (this.circle) {
      this.circle.setOptions({
        strokeColor,
        strokeOpacity,
        strokeWeight,
        fillColor,
        fillOpacity
      });
    }

    if (this.polygon) {
      this.polygon.setOptions({
        strokeColor,
        strokeOpacity,
        strokeWeight,
        fillColor,
        fillOpacity
      });
    }


  }

  async startReserveAdding() {
    await firstValueFrom(this.addReserve());
  }

  addReserve(defaultNavigation = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.form.valid) {
        let data = {};
        const reserveType = this.form.get('reserveType')?.value;

        if (this.polygonType === PolygonTypes.RADIUS) {
          data = {
            name: this.form.get('name')?.value,
            type: reserveType,
            description: this.form.get('description')?.value,
            isVisible: !!this.form.get('isVisible')?.value,
            parentId: this.form.get('reserveType')?.value === ReserveTypes.NATURE_RESERVE ? this.form.get('reserveParent')?.value : null,
            centerLatitude: this.circle?.getCenter()?.lat(),
            centerLongitude: this.circle?.getCenter()?.lng(),
            radius: this.circle?.getRadius(),
            coords: '',
            strokeColor: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeColor : AppConstants.googleMap.region.strokeColor,
            strokeOpacity: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeOpacity : AppConstants.googleMap.region.strokeOpacity,
            strokeWeight: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeWeight : AppConstants.googleMap.region.strokeWeight,
            fillColor: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.fillColor : AppConstants.googleMap.region.fillColor,
            fillOpacity: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.fillOpacity : AppConstants.googleMap.region.fillOpacity
          };
        }

        if (this.polygonType === PolygonTypes.POLYGON) {
          data = {
            name: this.form.get('name')?.value,
            type: reserveType,
            description: this.form.get('description')?.value,
            isVisible: !!this.form.get('isVisible')?.value,
            parentId: this.form.get('reserveType')?.value === ReserveTypes.NATURE_RESERVE ? this.form.get('reserveParent')?.value : null,
            coords: this.form.get('polygonCoordsJSON')?.value,
            strokeColor: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeColor : AppConstants.googleMap.region.strokeColor,
            strokeOpacity: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeOpacity : AppConstants.googleMap.region.strokeOpacity,
            strokeWeight: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.strokeWeight : AppConstants.googleMap.region.strokeWeight,
            fillColor: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.fillColor : AppConstants.googleMap.region.fillColor,
            fillOpacity: reserveType === ReserveTypes.NATURE_RESERVE ?
              AppConstants.googleMap.natureReserve.fillOpacity : AppConstants.googleMap.region.fillOpacity
          };
        }

        this.reservesService.addReserve(data).subscribe({
          next: (resp: any) => {
            this.hasUnsavedData = false;
            this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_RESERVE'));

            if (defaultNavigation) {
              this.router.navigate(['/reserves-management']);
            }

            observer.next(true);
          },
          error: (error) => {
            observer.next(false);
          },
        });

      } else {
        this.toast.show(this.translate.instant('ERROR_MESSAGES.CANT_SAVE_THIS_DATA_YET'), ToastMessageTypes.ERROR);
        observer.next(false);
      }
    });
  }

}
