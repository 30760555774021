<div class="crud-page">
  <div class="page-header">
    <a routerLink="/content-management" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.CONTENT.BACK' | translate}}</a>
    <h1>{{'PAGES.CONTENT_VIEW.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="editContent()">{{'BUTTONS.EDIT_CONTENT' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container">
      <div class="items" *ngIf="contentItem?.name">
        <span class="header">{{'PAGES.CONTENT.NAME' | translate}}</span>
        <span class="value">{{contentItem?.name}}</span>
      </div>

      <div class="items" *ngIf="contentItem?.description">
        <span class="header">{{'PAGES.CONTENT.DESCRIPTION' | translate}}</span>
        <span class="value">{{contentItem?.description}}</span>
      </div>

      <div class="items" *ngIf="contentItem?.imageUri">
        <span class="header">{{'PAGES.CONTENT.IMAGE' | translate}}</span>
        <div class="value icon-preview">
          <lightgallery [settings]="gallerySettings">
            <img [src]="contentItem?.imageUri" class="zoom"/>
          </lightgallery>
        </div>
      </div>

      <div class="items" *ngIf="contentItem?.categories?.[0]">
        <span class="header">{{'PAGES.CONTENT.CATEGORIES' | translate}}</span>
        <span class="value light-link" [routerLink]="'/categories-view/'+category.id"
              *ngFor="let category of contentItem?.categories">{{category.name}}
        </span>
      </div>

      <div class="items" *ngIf="contentItem?.seas?.[0]">
        <span class="header">{{'PAGES.CONTENT.SEAS.TITLE' | translate}}</span>
        <span class="value">
          {{contentItem?.seas?.join(', ')}}
        </span>
      </div>

      <div class="items">
        <span class="header">{{'PAGES.CONTENT.IS_PRIORITIZED' | translate}}</span>
        <span class="value">
          {{contentItem?.isPrioritized ? 'yes' : 'no'}}
        </span>
      </div>

      <div class="items" *ngIf="contentItem?.groupId">
        <span class="header">{{'PAGES.CONTENT.GROUP' | translate}}</span>
        <span class="value">
          {{contentGroupName}}
        </span>
      </div>

      <div class="items">
        <span class="header">{{'PAGES.CONTENT.ORDER' | translate}}</span>
        <span class="value">
          {{contentItem?.order}}
        </span>
      </div>

    </div>
  </div>
</div>
