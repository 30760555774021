<div class="crud-page">
  <div class="page-header replaced-with-2-lines">
    <a routerLink="/reserves-management" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.RESERVES_MANAGEMENT.BACK' | translate}}</a>
    <h1>{{reserve?.name}}</h1>
    <h2>{{reserve?.description}}</h2>
    <button class="toolbar" mat-flat-button color="primary" routerLink="/reserve-edit/{{reserve?.id}}">{{'BUTTONS.EDIT_RESERVE' | translate}}</button>
    <button (click)="confirmRemoveReserve($event, reserve)" class="toolbar" color="primary" mat-flat-button>{{'BUTTONS.DELETE_RESERVE' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container wide">
      <div id="google-map"></div>
      <div id="controls">
        <form [formGroup]="form" class="vertical-form wide">
          <mat-form-field class="half-width">
            <mat-label>{{'PAGES.RESERVES_MANAGEMENT.CURRENT_RESERVE' | translate}}</mat-label>
            <mat-select formControlName="currentReserve" (selectionChange)="gotoReserve()" (opened)="setFocus()">
              <input #searchInput [(ngModel)]="searchReserveText" [ngModelOptions]="{standalone: true}"
                     autocomplete="off" matInput placeholder="{{'PAGES.RESERVES_MANAGEMENT.SEARCH' | translate}}"
                     class="search-select">
              <mat-option *ngFor="let reserve of existingReserves | search : searchReserveText : 'name'" [value]="reserve.id">
                {{reserve.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <div class="items">
          <span class="header">{{'PAGES.RESERVES_MANAGEMENT.TYPE' | translate}}</span>
          <span class="value">{{reserve?.type}}</span>
        </div>

        <div class="items">
          <span class="header">{{'PAGES.RESERVES_MANAGEMENT.IS_VISIBLE' | translate}}</span>
          <span class="value">
          {{(reserve?.isVisible ? 'COMMON.YES' : 'COMMON.NO') | translate}}
        </span>
        </div>

        <div class="items" *ngIf="reserve?.type === reserveTypes.NATURE_RESERVE">
          <span class="header">{{'PAGES.RESERVES_MANAGEMENT.REGION' | translate}}</span>
          <span class="value"><a [routerLink]="'/reserve-view/'+reserve?.parentId" class="light-link">{{reserve?.parentName}}</a></span>
        </div>

        <div class="items" *ngIf="reserve?.hasRadius">
          <span class="header">{{'PAGES.RESERVES_MANAGEMENT.CIRCLE' | translate}}</span>
          <span class="value"><b>{{'PAGES.RESERVES_MANAGEMENT.RADIUS' | translate}}</b>: {{reserve?.radius}}</span>
          <span class="value"><b>{{'PAGES.RESERVES_MANAGEMENT.LAT' | translate}}</b>: {{reserve?.centerLatitude}}</span>
          <span class="value"><b>{{'PAGES.RESERVES_MANAGEMENT.LNG' | translate}}</b>: {{reserve?.centerLongitude}}</span>
        </div>

        <div class="items" *ngIf="!reserve?.hasRadius">
          <span class="header">{{'PAGES.RESERVES_MANAGEMENT.POLYGON' | translate}}</span>
          <span class="value"><br/><b>{{'PAGES.RESERVES_MANAGEMENT.COORDS' | translate}}</b>:<br/>
            <span [innerHTML]="prepareForView(reserve?.coords)"></span>
          </span>
        </div>


      </div>
    </div>
  </div>
</div>
