import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastMessageTypes } from "../../shared/enums/toastMessageTypes.enum";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  login() {

    if (this.form.valid) {

      const email = this.form.get('email')?.value;
      const password = this.form.get('password')?.value;

      this.auth.login( { email, password } )
        .subscribe({
          next: (resp: any) => {

            if (resp.type === 'Temporary') {
              // need to verify via PIN code
              this.router.navigate(['/login-pin']);
            } else {
              // success login
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.LOGGED_IN'));

              // get profile info
              this.auth.profile()
                .subscribe({
                  next: (resp: any) => {
                    this.auth.saveUserToLocalStorage(resp);
                    this.router.navigate(['/']);
                  },
                  error: (error) => {}
                })
            }
          },
          error: (error) => {
            this.toast.show(this.translate.instant('ERROR_MESSAGES.INCORRECT_EMAIL_PASSWORD'), ToastMessageTypes.ERROR);
          },
        });
    }
  }

}
