import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { HomePageContactsService } from "../../shared/services/home-page-contacts.service";
import { Patterns } from "../../shared/patterns";

@Component({
  selector: 'app-home-page-contacts',
  templateUrl: './home-page-contacts.component.html',
  styleUrls: ['./home-page-contacts.component.scss']
})
export class HomePageContactsComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private homePageContactsService: HomePageContactsService,
    private toast: ToastService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(Patterns.mobile)]],
      whatsAppLink: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.homePageContactsService.getContacts()
      .subscribe({
        next: (resp: any) => {
          this.form.get('phoneNumber')?.patchValue(resp.phoneNumber);
          this.form.get('whatsAppLink')?.patchValue(resp.whatsAppLink);
        },
        error: (error) => {}
      })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  saveHomePageContacts() {

    let data = {
      phoneNumber: this.form.get('phoneNumber')?.value,
      whatsAppLink: this.form.get('whatsAppLink')?.value
    };

    this.homePageContactsService.updateContacts(data).subscribe({
      next: (resp: any) => {
        this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_HOME_PAGE_CONTACTS'));
      },
      error: (error) => {},
    });

  }

}
