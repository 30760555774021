import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../shared/services/auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { MustMatch } from "../../shared/helpers/must-match.validator";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  invitationId: string = '';
  public form: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private fb: FormBuilder,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,}') // 8+, 1U+1l+1d
      ]],
      repeatPassword: ['', Validators.required]
    }, { validator: MustMatch('password', 'repeatPassword')});
  }

  ngOnInit(): void {
    this.invitationId = '' + this.route.snapshot.paramMap.get('id');
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  resetPassword() {

    if (this.form.valid) {

      const password = this.form.get('password')?.value;
      const repeatPassword = this.form.get('repeatPassword')?.value;

      this.auth.resetPassword({ invitationId: this.invitationId, password, repeatPassword }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.PASSWORD_UPDATED'));
          this.router.navigate(['/login']);
        },
        error: (error) => {},
      });

    }
  }
}
