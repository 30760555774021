import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationUsersService } from "../../../shared/services/application-users.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "../../../shared/services/toast.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from "@angular/material/chips";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  public form: FormGroup;
  private userId: any;

  constructor(
    private fb: FormBuilder,
    private userService: ApplicationUsersService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.email],
      receiveNewsInfo: '',
      receiveReportInfo: '',
      seaRelations: [],
      isDeleted: ''
    });
  }

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  relations: any = [];

  ngOnInit(): void {
    // get category data
    this.userId = this.route.snapshot.paramMap.get('id');

    if (this.userId) {
      this.userService.getUser(this.userId).subscribe({
        next: (resp: any) => {
          this.form.get('firstName')?.patchValue(resp.firstName);
          this.form.get('lastName')?.patchValue(resp.lastName);
          this.form.get('phoneNumber')?.patchValue(resp.phoneNumber);
          this.form.get('email')?.patchValue(resp.email);
          this.form.get('receiveNewsInfo')?.patchValue(resp.receiveNewsInfo);
          this.form.get('receiveReportInfo')?.patchValue(resp.receiveReportInfo);
          this.relations = resp.seaRelations;
          this.form.get('isDeleted')?.patchValue(resp.isDeleted);
        },
        error: (error: any) => {
        }
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.relations.push(value);
    }

    event.chipInput!.clear();
  }

  remove(relation: any): void {
    const index = this.relations.indexOf(relation);

    if (index >= 0) {
      this.relations.splice(index, 1);
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  saveApplicationUser() {
    if (this.form.valid) {

      const formData = this.form.value;
      this.userService.updateUser({
        id: this.userId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        email: formData.email,
        receiveNewsInfo: !!formData.receiveNewsInfo,
        receiveReportInfo: !!formData.receiveReportInfo,
        seaRelations: this.relations,
        isDeleted: !!formData.isDeleted
      }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_USER'));
          this.router.navigate(['/users']);
        },
        error: (error) => {},
      });

    }

  }

}
