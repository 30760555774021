<div class="page-header search-toolbar">
  <h1>{{'PAGES.REPORTS.TITLE' | translate}}</h1>
  <div class="search-container">
    <mat-form-field appearance="fill">
      <mat-label>Search</mat-label>
      <input matInput [(ngModel)]="filters.searchTerm">
    </mat-form-field>
  </div>
  <button class="toolbar" mat-flat-button color="primary" (click)="exportToExcel()">{{'PAGES.REPORTS.EXPORT_TO_EXCEL' | translate}}</button>
  <button class="toolbar" mat-flat-button color="primary" (click)="resetFilters()">{{'PAGES.REPORTS.RESET_FILTERS' | translate}}</button>
</div>
<div class="page-content">
  <div class="data-table tabs">

    <div id="filters">

      <mat-form-field>
        <mat-label>{{'COMMON.FROM_TO_DATES' | translate}}</mat-label>
        <input type="text" autocomplete="off" matInput ngxDaterangepickerMd
               [(ngModel)]="filters.dateRange"
               [showCustomRangeLabel]="true"
               [alwaysShowCalendars]="true"
               [ranges]="datesRanges"
               [autoApply]="true"
               [closeOnAutoApply]="true"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.REPORTS.BY_CATEGORY' | translate}}"
                    [formControl]="categoryMultiCtrl"
                    [multiple]="true">
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              [formControl]="categoryFilterCtrl"
              [placeholderLabel]="translate.instant('COMMON.SEARCH')"
              [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
              (toggleAll)="toggleSelectAllCategories($event)"
              [toggleAllCheckboxIndeterminate]="isAllCategoriesIndeterminate"
              [toggleAllCheckboxChecked]="isAllCategoriesChecked"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let category of filteredCategories | async" [value]="category.id">
            {{category.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.REPORTS.BY_STATUS' | translate}}"
                    [formControl]="statusMultiCtrl"
                    [multiple]="true">
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              [formControl]="statusFilterCtrl"
              [placeholderLabel]="translate.instant('COMMON.SEARCH')"
              [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
              (toggleAll)="toggleSelectAllStatuses($event)"
              [toggleAllCheckboxIndeterminate]="isAllStatusesIndeterminate"
              [toggleAllCheckboxChecked]="isAllStatusesChecked"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let status of filteredStatuses | async" [value]="status.id">
            {{status.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.REPORTS.BY_REPORT_TYPE' | translate}}" [multiple]="true" [(ngModel)]="filters.reportTypes">
          <mat-option [value]="categoryTypes.OBSERVATION">{{'PAGES.REPORTS.OBSERVATION' | translate}}</mat-option>
          <mat-option [value]="categoryTypes.VIOLATION">{{'PAGES.REPORTS.VIOLATION' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.CONTENT.BY_SEA' | translate}}" [multiple]="true" [(ngModel)]="filters.seas">
          <mat-option [value]="areaSeas.MEDITERRANEAN">{{'PAGES.CONTENT.SEAS.MEDITERRANEAN' | translate}}</mat-option>
          <mat-option [value]="areaSeas.RED">{{'PAGES.CONTENT.SEAS.RED' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.REPORTS.BY_USER' | translate}}"
                    [formControl]="userMultiCtrl"
                    [multiple]="true">
          <mat-option>
            <ngx-mat-select-search
              [showToggleAllCheckbox]="true"
              [formControl]="userFilterCtrl"
              [placeholderLabel]="translate.instant('COMMON.SEARCH')"
              [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
              (toggleAll)="toggleSelectAllUsers($event)"
              [toggleAllCheckboxIndeterminate]="isAllUsersIndeterminate"
              [toggleAllCheckboxChecked]="isAllUsersChecked"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
            {{user.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button class="toolbar search" mat-flat-button color="primary" (click)="searchReports()">{{'PAGES.REPORTS.SEARCH' | translate}}</button>
    </div>

    <mat-tab-group mat-align-tabs="start" class="tab-custom-overflow" (selectedTabChange)="viewChanged($event);">
      <mat-tab label="{{'PAGES.REPORTS.TABLE' | translate}}">
        <table mat-table [dataSource]="reports" multiTemplateDataRows class="mat-elevation-z8 data filter-header" matSort>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">
              {{'COMMON.ACTIONS' | translate}}
            </th>
            <td mat-cell *matCellDef="let report" class="column-100 no-padding">
              <button class="link" mat-icon-button routerLink="/report-edit/{{report.id}}"><mat-icon>edit</mat-icon></button>
              <button class="link" mat-icon-button (click)="confirmRemoveReport($event, report)"><mat-icon>delete_outline</mat-icon></button>
            </td>
          </ng-container>

          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>{{'PAGES.REPORTS.LOCATION' | translate}}</th>
            <td mat-cell *matCellDef="let report"> {{report.location}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="column-50">{{'PAGES.REPORTS.ID' | translate}}</th>
            <td mat-cell *matCellDef="let report" class="column-50">
              <a class="link" routerLink="/report-view/{{report.id}}"> {{report.id}} </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="categoryName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'PAGES.REPORTS.CATEGORY' | translate}}</th>
            <td mat-cell *matCellDef="let report">{{report.categoryName}}</td>
          </ng-container>

          <ng-container matColumnDef="reportType">
            <th mat-header-cell *matHeaderCellDef >{{'PAGES.REPORTS.TYPE' | translate}}</th>
            <td mat-cell *matCellDef="let report">{{report.reportType}}</td>
          </ng-container>

          <ng-container matColumnDef="reportStatusName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'PAGES.REPORTS.STATUS' | translate}}</th>
            <td mat-cell *matCellDef="let report">
              <span *ngIf="isAdmin">
                  <mat-select class="inline-select inside-table" [value]="report.reportStatusId" (click)="$event.stopPropagation()" (selectionChange)="changeReportStatus(report, $event)">
                    <mat-option [value]="status.id" [disabled]="disabledStatusNames.includes(status.name)" *ngFor="let status of statuses">{{status.name}}</mat-option>
                  </mat-select>
              </span>
              <span *ngIf="!isAdmin">{{report.reportStatusName}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="userFullName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'PAGES.REPORTS.CREATED_BY' | translate}}</th>
            <td mat-cell *matCellDef="let report"> {{report.userFullName}} </td>
          </ng-container>

          <ng-container matColumnDef="reportDate">
            <th mat-header-cell *matHeaderCellDef>{{'PAGES.REPORTS.REPORT_DATE' | translate}}</th>
            <td mat-cell *matCellDef="let report">
              <span *ngIf="report.reportDate">{{report.reportDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="backofficeUser">
            <th mat-header-cell *matHeaderCellDef>{{'PAGES.REPORTS.BACKOFFICE_USER_NAME' | translate}}</th>
            <td mat-cell *matCellDef="let report"> {{report.backOfficeUserFullName}} </td>
          </ng-container>

          <ng-container matColumnDef="updatedDate">
            <th mat-header-cell *matHeaderCellDef>{{'PAGES.REPORTS.UPDATED_DATE' | translate}}</th>
            <td mat-cell *matCellDef="let report">
              <span *ngIf="report.updatedDate">{{report.updatedDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef class="column-50">{{'PAGES.REPORTS.NOTE' | translate}}</th>
            <td mat-cell *matCellDef="let report" class="column-50">
              <mat-icon *ngIf="!!report.note" [matTooltip]="report.note" matTooltipClass="notes" class="cursor-help">notes</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let report; columns: displayedColumns" routerLink="/report-view/{{report.id}}" class="pointer" ></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
          </tr>
        </table>
        <mat-paginator (page)="changePageBE($event)" showFirstLastButtons></mat-paginator>
      </mat-tab>
      <mat-tab label="{{'PAGES.REPORTS.MAP' | translate}}">
        <div id="google-map"></div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
