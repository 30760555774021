<div class="crud-page">
  <div class="page-header">
    <a routerLink="/categories" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{'PAGES.REPORT_CATEGORIES.BACK' | translate}}</a>
    <h1>{{'PAGES.REPORT_CATEGORY_ADD.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="createReportCategory()" [disabled]="!form.valid">
      {{'BUTTONS.ADD_REPORT_CATEGORY' | translate}}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form">
      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.REPORT_CATEGORIES.NAME' | translate}}</mat-label>
        <input matInput formControlName="categoryName" required>
        <mat-error *ngIf="checkError('categoryName', 'required')">{{'PAGES.REPORT_CATEGORIES.ERRORS.REQUIRED_CATEGORY_NAME' | translate}}</mat-error>
        <mat-error *ngIf="checkError('categoryName', 'minlength')">{{'PAGES.REPORT_CATEGORIES.ERRORS.TOO_SHORT_CATEGORY_NAME' | translate}}</mat-error>
      </mat-form-field>

      <span class="column-header">{{'PAGES.REPORT_CATEGORIES.ICON' | translate}} *</span>
      <div class="icon-preview" *ngIf="iconPreview">
        <img [src]="iconPreview | safe:'resourceUrl'"/>
      </div>
      <mat-form-field class="no-line">
        <input matInput disabled class="file-input">
        <input type="file" class="file-input" accept="image/*" (change)="onFileSelected($event)"
               formControlName="categoryIconSource" #fileUpload>
        <div class="mat-error custom-error" *ngIf="!iconPreview">{{'PAGES.REPORT_CATEGORIES.ERRORS.REQUIRED_ICON' | translate}}</div>
        <button class="change-icon" mat-flat-button color="accent" (click)="fileUpload.click()">{{'BUTTONS.CHANGE_ICON' | translate}}</button>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="{{'PAGES.REPORT_CATEGORIES.LINK_TO_CONTENT' | translate}}" formControlName="linkToContent" required>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="contentServerSideFilteringCtrl"
              [searching]="searching"
              placeholderLabel="{{'PAGES.REPORT_CATEGORIES.CONTENT_SEARCH_LABEL' | translate}}"
              noEntriesFoundLabel="{{'PAGES.REPORT_CATEGORIES.CONTENT_SEARCH_NO_RESULTS' | translate}}"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let content of filteredServerSideContent | async" [value]="content.id">
            {{content.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="checkError('linkToContent', 'required')">{{'PAGES.REPORT_CATEGORIES.ERRORS.REQUIRED_LINK_TO_CONTENT' | translate}}</mat-error>
      </mat-form-field>

      <span class="column-header">{{'PAGES.REPORT_CATEGORIES.HINT' | translate}} *</span>
      <mat-card class="without-underline">
        <mat-form-field appearance="fill" floatLabel="never">
          <textarea matInput formControlName="hint"></textarea>
          <mat-error *ngIf="checkError('hint', 'required')">{{'PAGES.REPORT_CATEGORIES.ERRORS.REQUIRED_HINT' | translate}}</mat-error>
        </mat-form-field>
      </mat-card>
    </form>
  </div>
</div>
