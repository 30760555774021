import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ApiService } from "../../shared/services/api.service";
import { ToastService } from "../../shared/services/toast.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { BackofficeUserModel } from "../../shared/models/backoffice-user.model";
import { AuthService } from "../../shared/services/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../../shared/constants";
import { StatesService } from "../../shared/services/states.service";
import { Router } from "@angular/router";
import { BackofficeUsersService } from "../../shared/services/backoffice-users.service";

@Component({
  selector: 'app-backoffice-users',
  templateUrl: './backoffice-users.component.html',
  styleUrls: ['./backoffice-users.component.scss']
})
export class BackofficeUsersComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort ;

  constructor(
    private api: ApiService,
    public auth: AuthService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<BackofficeUsersComponent>,
    private dialog: MatDialog,
    private translate: TranslateService,
    private statesService: StatesService,
    private router: Router,
    private backofficeUsersService: BackofficeUsersService
  ) { }

  public backofficeUsers = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'fullName', 'email', 'roleName', 'lastLoginDate'];

  public userRoles: any[] = [];

  ngOnInit(): void {}

  ngAfterViewInit() {

    this.backofficeUsersService.getRoles().subscribe({
      next: (resp: any) => {
        this.userRoles = resp;
      },
      error: (error) => {},
    });

    this.updateBackofficeUsers();
  }

  updateBackofficeUsers() {
    this.backofficeUsersService.getBackofficeUsers()
      .subscribe({
        next: (resp: any) => {
          this.backofficeUsers.data = resp;

          this.paginator.pageIndex = this.statesService.backofficeUsersPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.backofficeUsersPaginator.pageSize;
          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

          this.backofficeUsers.paginator = this.paginator;
          this.backofficeUsers.sort = this.sort;
        },
        error: (error) => {}
      })
  }

  confirmRemoveUser(user: BackofficeUserModel) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'), this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER'));

    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog'
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove user
        this.backofficeUsersService.removeBackofficeUser(user.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_USER'));
              this.updateBackofficeUsers();
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  changePage(event: any) {
    this.statesService.backofficeUsersPaginator.pageIndex = event.pageIndex;
    this.statesService.backofficeUsersPaginator.pageSize = event.pageSize;
  }

  gotoAddUserPage() {
    this.router.navigate([`/backoffice-users-add`]);
  }


}
