<div class="crud-page">
  <div class="page-header">
    <a routerLink="/users" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{'PAGES.USERS.BACK' | translate}}</a>
    <h1>
      {{'PAGES.USERS_UPDATE.TITLE' | translate}}
      <span *ngIf="form.get('isDeleted')?.value" class="flag"><mat-icon>flag</mat-icon></span>
    </h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="saveApplicationUser()" [disabled]="!form.valid">
      {{'BUTTONS.EDIT_APPLICATION_USER' | translate}}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form">
      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.USERS.FIRST_NAME' | translate}}</mat-label>
        <input matInput formControlName="firstName" required>
        <mat-error *ngIf="checkError('firstName', 'required')">{{'PAGES.USERS.ERRORS.REQUIRED_FIRST_NAME' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.USERS.LAST_NAME' | translate}}</mat-label>
        <input matInput formControlName="lastName" required>
        <mat-error *ngIf="checkError('lastName', 'required')">{{'PAGES.USERS.ERRORS.REQUIRED_LAST_NAME' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.USERS.PHONE_NUMBER' | translate}}</mat-label>
        <input matInput formControlName="phoneNumber" required>
        <mat-error *ngIf="checkError('phoneNumber', 'required')">{{'PAGES.USERS.ERRORS.REQUIRED_PHONE_NUMBER' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.USERS.EMAIL' | translate}}</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="checkError('email', 'email')">{{'PAGES.USERS.ERRORS.INVALID_EMAIL' | translate}}</mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="receiveNewsInfo">{{'PAGES.USERS.RECEIVE_NEWS_INFO' | translate}}</mat-checkbox>

      <mat-checkbox formControlName="receiveReportInfo">{{'PAGES.USERS.RECEIVE_REPORT_INFO' | translate}}</mat-checkbox>

      <mat-form-field appearance="fill" class="chips">
        <mat-label>{{'PAGES.USERS.SEA_RELATIONS' | translate}}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip color="primary" selected *ngFor="let relation of relations" (removed)="remove(relation)">
            {{relation}}
            <button matChipRemove><mat-icon>cancel</mat-icon></button>
          </mat-chip>
          <input placeholder="{{'PAGES.USERS.NEW_RELATION' | translate}}"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>

      <mat-checkbox formControlName="isDeleted">{{'PAGES.USERS.IS_DELETED' | translate}}</mat-checkbox>

    </form>
  </div>
</div>
