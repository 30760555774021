<div class="page-header inspector-report">
  <h1 *ngIf="reportData?.reportDate">{{reportData.reportDate + 'Z' | date:"dd/MM/yy (HH:mm)"}}</h1>
  <div class="report-status">
    <span>{{'PAGES.INSPECTOR_REPORT.RESPONSIBLE_INSPECTOR' | translate}}: <b>{{reportData.inspectorFullName}}</b></span>
    <span *ngIf="reportData?.backOfficeUserFullName">{{'PAGES.REPORTS_TICKETS.LAST_CHANGED_BY' | translate}}: <b>{{reportData.backOfficeUserFullName}}</b></span>
    <span>
      {{'PAGES.INSPECTOR_REPORT.REPORT_STATUS' | translate}}:
      <mat-select class="inline-select" [disabled]="!reportData.canEdit" [(ngModel)]="reportStatusId" [ngModelOptions]="{standalone: true}">
        <mat-option [value]="status.id" [disabled]="disabledStatusNames.includes(status.name)" *ngFor="let status of statuses">{{status.name}}</mat-option>
      </mat-select>
    </span>
    <mat-form-field appearance="fill" *ngIf="reportData.canEdit">
      <mat-label>{{ "PAGES.REPORTS.LOG_TABLE.NOTE" | translate }}</mat-label>
      <input matInput [(ngModel)]="reportNote" [ngModelOptions]="{ standalone: true }" />
    </mat-form-field>
  </div>
  <div>
    <button class="toolbar" mat-flat-button color="primary" (click)="saveReport()">{{ "BUTTONS.SAVE_REPORT" | translate }}</button>
  </div>
</div>
<div class="page-content inspector-report">
  <div class="columns-block">
    <div>
      <div class="item" *ngIf="reportData.categories?.[0]">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.CATEGORY' | translate}}</span>
        <span *ngFor="let category of reportData.categories" class="category">
          ↳ {{category.name}}
        </span>
      </div>

      <div class="item">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.REPORTER' | translate}}</span>
        {{reportData.reporterFirstName}} {{reportData.reporterLastName}}
      </div>

      <div class="item">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.PHONE' | translate}}</span>
        <a href="tel:{{reportData.reporterPhoneNumber}}" class="light-link">{{reportData.reporterPhoneNumber}}</a>
      </div>

      <div class="item" *ngIf="reportData.reporterEmail">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.EMAIL' | translate}}</span>
        <a href="mailto:{{reportData.reporterEmail}}" class="light-link">{{reportData.reporterEmail}}</a>
      </div>

      <div class="item" *ngIf="reportData.reporterSeaRelations">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.MY_RELATIONS_TO_THE_SEA' | translate}}</span>
        {{reportData.reporterSeaRelations.join(', ')}}
      </div>

    </div>
    <div *ngIf="reportData.images?.length > 0">
      <div class="item">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.PHOTO_FROM_REPORT' | translate}}</span>
        <lightgallery [settings]="gallerySettings">
          <ng-container  *ngFor="let image of reportData.images">
            <img *ngIf="!isVideo(image)" class="gallery-img thumbnails zoom" [src]="image" />

            <a class="video-thumb-container gallery-img" *ngIf="isVideo(image) && image" [attr.data-video]='getDataVideo(image)'>
              <video class="thumbnails gallery-img" [controls]="false" [autoplay]="false" [muted]="true">
                <source [src]="image" [type]="'video/' + getVideoFileExtension(image)">
              </video>
              <button class="play-button" type="button" aria-disabled="false">
                <span class="play-button-icon-placeholder" aria-hidden="true"></span>
              </button>
            </a>
          </ng-container>
        </lightgallery>
      </div>
    </div>
    <div *ngIf="!!reportData.description || !!reportData.metricObservationData">
      <div class="item" *ngIf="!!reportData.description">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.DESCRIPTION" | translate }}</span>
        {{ reportData.description }}
      </div>
      <div class="item" *ngIf="reportData.metricObservationData">
        <span class="column-header">{{ "PAGES.INSPECTOR_REPORT.METRIC_OBSERVATION_DATA" | translate }}</span>
        <span [innerHTML]="reportData.metricObservationData.split('\n').join('<br />')"></span>
      </div>
    </div>
    <div class="google-container">
      <div class="item">
        <span class="column-header">{{'PAGES.INSPECTOR_REPORT.LOCATION' | translate}}</span>
        <div id="google-map"></div>
      </div>

      <a href="https://maps.google.com/?q={{reportData.latitude}},{{reportData.longitude}}" target="_blank" class="map light-link">GoogleMaps {{reportData.location}}</a>

    </div>
  </div>
  <div class="logs-block" *ngIf="logs.length > 0">
    <span class="column-header">{{ "PAGES.REPORTS.LOGS" | translate }}</span>
    <div class="table-container">
      <table mat-table [dataSource]="logs" class="mat-elevation-z3">

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef class="column-150"> {{ "PAGES.REPORTS.LOG_TABLE.CREATED_DATE" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-150"> {{log.createdDate + 'Z' | date:"dd.MM.yy (HH:mm)"}} </td>
      </ng-container>

      <ng-container matColumnDef="backOfficeUserFullName">
        <th mat-header-cell *matHeaderCellDef class="column-150"> {{ "PAGES.REPORTS.LOG_TABLE.USER_FULL_NAME" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-150"> {{log.backOfficeUserFullName}} </td>
      </ng-container>

      <ng-container matColumnDef="reportStatusName">
        <th mat-header-cell *matHeaderCellDef class="column-150"> {{ "PAGES.REPORTS.LOG_TABLE.REPORT_STATUS_NAME" | translate }} </th>
        <td mat-cell *matCellDef="let log" class="column-150"> {{log.reportStatusName}} </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef> {{ "PAGES.REPORTS.LOG_TABLE.NOTE" | translate }} </th>
        <td mat-cell *matCellDef="let log"> {{log.note}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="logsTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: logsTableDisplayedColumns;"></tr>
    </table>
    </div>
  </div>
</div>

