import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "../../../shared/services/api.service";
import { ToastService } from "../../../shared/services/toast.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ContentService } from "../../../shared/services/content.service";
import { ContentSeas } from "../../../shared/enums/contentSeas.enum";
import { ContentGroupModel } from "../../../shared/models/groups.model";
import { AppConstants } from '../../../shared/constants';

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss']
})
export class AddContentComponent implements OnInit {

  public form: FormGroup;

  public currentImage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentPreview: BehaviorSubject<any> = new BehaviorSubject<any>('');

  public fileName = '';

  public contentSeas = ContentSeas;
  public contentGroups: ContentGroupModel[] | undefined;

  public appConstants = AppConstants;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService,
    private contentService: ContentService
  ) {
    this.form = this.fb.group({
      contentName: ['', [Validators.required, Validators.minLength(3)]],
      contentImageSource: ['', Validators.required],
      contentImage: '',
      description: ['', Validators.required],
      contentSeas: ['', Validators.required],
      isPrioritized: [''],
      groupId: ['', Validators.required],
      contentOrder: ['', [Validators.required, Validators.min(0), Validators.max(this.appConstants.maximumOrderValue)]]
    });
  }

  ngOnInit(): void {

    this.contentService.getGroups().subscribe({
      next: (resp: any) => {
        this.contentGroups = resp;
      },
      error: (error: any) => {
      }
    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  onFileSelected(event: any) {

    if (event.target.files.length > 0) {
      this.form.patchValue({
        contentImage: event.target.files[0]
      });
      this.currentImage.next(this.form.get('contentImageSource')?.value);
      this.preparePreview(event.target.files[0]);
    }
  }

  private preparePreview(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.currentPreview.next(reader.result);
    }
  }

  public get imagePreview(): string {
    return this.currentPreview.getValue();
  }

  createContent() {
    if (this.form.valid) {

      let formData: any = new FormData();
      formData.append('Name', this.form.get('contentName')?.value);
      formData.append('Description', this.form.get('description')?.value);
      formData.append('Image', this.form.get('contentImage')?.value);

      const contentSeas = this.form.get('contentSeas')?.value;
      contentSeas.forEach((sea:string) => formData.append('Seas', sea));

      formData.append('GroupId', this.form.get('groupId')?.value);

      formData.append('IsPrioritized', !!this.form.get('isPrioritized')?.value);
      formData.append('Order', this.form.get('contentOrder')?.value);

      this.contentService.addContent(formData).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_CONTENT_ITEM'));
          this.router.navigate(['/content-management']);
        },
        error: (error) => {},
      });
    }

  }
}
