<div class="page-header">
  <h1>{{'PAGES.USERS.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="exportToExcel()">{{'PAGES.USERS.EXPORT_TO_EXCEL' | translate}}</button>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddUserPage()">{{'BUTTONS.ADD_USER' | translate}}</button>
</div>
<div class="page-content">
  <div class="data-table">

    <table mat-table [dataSource]="applicationUsers" multiTemplateDataRows class="mat-elevation-z8 data">

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">
          {{'COMMON.ACTIONS' | translate}}
        </th>
        <td mat-cell *matCellDef="let user" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/users-edit/{{user.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemoveUser($event, user)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="column-50">{{'PAGES.USERS.ID' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-50">
          <a class="link" routerLink="/users-view/{{user.id}}"> {{user.id}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>{{'PAGES.USERS.FIRST_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.firstName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{'PAGES.USERS.LAST_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{'PAGES.USERS.EMAIL' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>

      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>{{'PAGES.USERS.PHONE_NUMBER' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.phoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef class="column-150">{{'PAGES.USERS.REGISTRATION' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-150">
          <span *ngIf="user.createdDate">{{user.createdDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastLoginDate">
        <th mat-header-cell *matHeaderCellDef class="column-150">{{'PAGES.USERS.LAST_LOGIN_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-150">
          <span *ngIf="user.lastLoginDate">{{user.lastLoginDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span>
          <span *ngIf="user.isDeleted" class="flag"><mat-icon>flag</mat-icon></span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns" [class.removed]="user.isDeleted"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePageBE($event)" showFirstLastButtons></mat-paginator>


  </div>
</div>
