import { AppConstants } from "../constants";

export interface PaginatorModel {
  pageIndex: number;
  pageSize: number;
}
export class PaginatorClass {
  pageIndex: number;
  pageSize: number;

  constructor(data?: PaginatorModel) {
    this.pageIndex = data?.pageIndex ?? 0;
    this.pageSize = data?.pageSize ?? AppConstants.paginator.pageSizeOptions[1]; // middle value 0[1]2
  }
}
