import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUsersService {

  constructor(
    private api: ApiService
  ) { }

  getUsers(pageNumber: number, pageSize: number) {
    return this.api.get(`users`, { pageNumber, pageSize });
  }

  getReportUsers() {
    return this.api.get('users/search', {});
  }

  addUser(data: any) {
    return this.api.post(`users`, data);
  }

  updateUser(data: any) {
    return this.api.put(`users`, data);
  }

  getUser(id: number | string) {
    return this.api.get(`users/${id}`);
  }

  removeUser(id: number | string ) {
    return this.api.delete(`users/${id}`);
  }

  exportToExcel(): void {
    return this.api.open(`users/mailinglistxls`);
  }

}
