import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class ReservesManagementService {

  constructor(
    private api: ApiService
  ) { }

  getReserves(inspectorKey = '') {
    return !!inspectorKey ? this.api.get(`polygons?inspectorKey=${inspectorKey}`) : this.api.get(`polygons`);
  }

  addReserve(formData: any) {
    return this.api.post(`polygons`, formData);
  }

  updateReserve(formData: any) {
    return this.api.put(`polygons`, formData);
  }

  getReserve(id: number | undefined) {
    return this.api.get(`polygons/${id}`);
  }

  deleteReserve(id: number | string ) {
    return this.api.delete(`polygons/${id}`);
  }

  searchReserve(search: string) {
    return this.api.get(`polygons/search`, { searchTerm: search });
  }
}
