<div class="page-header">
  <h1>{{'PAGES.REPORT_CATEGORIES.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddReportCategoryPage()">{{'BUTTONS.ADD_REPORT_CATEGORY' | translate}}</button>
</div>
<div class="page-content">
  <div class="data-table">

    <table mat-table [dataSource]="reportCategories" multiTemplateDataRows matSortActive="name" matSortDirection="asc" class="mat-elevation-z8 data" matSort>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">{{'COMMON.ACTIONS' | translate}}</th>
        <td mat-cell *matCellDef="let category" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/categories-edit/{{category.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemoveCategory($event, category)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-50">{{'PAGES.REPORT_CATEGORIES.ID' | translate}}</th>
        <td mat-cell *matCellDef="let category" class="column-50"> <a class="link" routerLink="/categories-view/{{category.id}}"> {{category.id}} </a></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-200">{{'PAGES.REPORT_CATEGORIES.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let category" class="column-200"> {{category.name}} </td>
      </ng-container>

      <ng-container matColumnDef="hint">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.REPORT_CATEGORIES.HINT' | translate}}</th>
        <td mat-cell *matCellDef="let category" class="truncated-text"> {{category.hint}} </td>
      </ng-container>

      <ng-container matColumnDef="updatedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-200 no-padding">{{'PAGES.REPORT_CATEGORIES.UPDATED_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let category" class="column-200 no-padding"><span *ngIf="category.updatedDate">{{category.updatedDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let category; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>

  </div>
</div>
