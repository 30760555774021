import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  get(url: string, parameters: any = {}, withCredentials = true) {
    const params = new HttpParams({ fromObject: parameters });
    return this.http.get(`${environment.apiUrl}/${url}`, { params, withCredentials });
  }

  delete(url: string, withCredentials = true) {
    return this.http.delete(`${environment.apiUrl}/${url}`, { withCredentials });
  }

  post(url: string, data: any) {
    return this.http.post(`${environment.apiUrl}/${url}`, data, { withCredentials: true });
  }

  put(url: string, data: any) {
   return this.http.put(`${environment.apiUrl}/${url}`, data, { withCredentials: true });
  }

  open(url: string, params: string = '') {
    window.open(`${environment.apiUrl}/${url}${params}`, '_blank');
  }

}
