<div class="page-header">
  <h1>{{'PAGES.HOME_PAGE_CONTACTS.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary"
          (click)="saveHomePageContacts()"
          [disabled]="!form.valid">
    {{'BUTTONS.SAVE_CHANGES' | translate}}
  </button>
</div>
<div class="page-content">
  <div class="view-container">
  <form [formGroup]="form" class="vertical-form columns">
    <mat-form-field>
      <input matInput placeholder="{{'PAGES.HOME_PAGE_CONTACTS.PHONE' | translate}}" type="text" formControlName="phoneNumber" required>
      <!--<mat-hint>{{'PAGES.HOME_PAGE_CONTACTS.PHONE_HINT' | translate}}</mat-hint>-->
      <mat-error *ngIf="checkError('phoneNumber', 'required')">{{'PAGES.HOME_PAGE_CONTACTS.ERRORS.REQUIRED_PHONE' | translate}}</mat-error>
      <mat-error *ngIf="checkError('phoneNumber', 'pattern')">{{'ERROR_MESSAGES.PATTERNS.MOBILE' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="{{'PAGES.HOME_PAGE_CONTACTS.WHATSAPP' | translate}}" type="text" formControlName="whatsAppLink" required>
      <mat-hint>https://wa.me/...</mat-hint>
      <mat-error *ngIf="checkError('whatsAppLink', 'required')">{{'PAGES.HOME_PAGE_CONTACTS.ERRORS.REQUIRED_WHATSAPP' | translate}}</mat-error>
    </mat-form-field>
  </form>
  </div>
</div>

