import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from "../../services/breadcrumbs.service";
import { BreadcrumbsModel } from "../../models/breadcrumbs.model";
import { Router } from "@angular/router";
import { zeroPad } from "../../helpers/core.helpers";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public breadcrumbsItems: BreadcrumbsModel[] = [];
  public zeroPad = zeroPad;
  private breadcrumbsSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit(): void {
    this.breadcrumbsSubscription = this.breadcrumbsService.breadcrumbsItems.subscribe({
      next: (resp: any) => {
        this.breadcrumbsItems = resp.sort((state: { index: number; }) => state.index);
      },
      error: (error) => {}
    })
  }

  gotoBreadcrumbPage(breadcrumbItem: BreadcrumbsModel) {
    // this.breadcrumbsService.clearAfterIndex(breadcrumbItem.index);
    this.breadcrumbsService.pendingShorteningBreadcrumbItem = breadcrumbItem.index;
    this.router.navigate([`/page-view/${breadcrumbItem.pageId}`], {replaceUrl: true});
  }

  ngOnDestroy() {
    this.breadcrumbsSubscription?.unsubscribe();
  }


}
