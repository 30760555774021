import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { BackofficeUserModel } from "../../models/backoffice-user.model";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userProfile: BehaviorSubject<BackofficeUserModel> = new BehaviorSubject<BackofficeUserModel>({
    id: 0,
    mobile: '',
    email: '',
    fullName: '',
    roleId: 0,
    roleName: ''
  });

  public isAuthenticated: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient
  ) {
    this.loadUserFromLocalStorage();
  }

  // login user using email/password
  login (user: { email: string; password: string; }) {
    return this.http.post(`${environment.apiUrl}/auth/login`, user, { withCredentials: true } );
  }

  // setup password
  setupPassword(creds: { invitationId: string; pinCode: string; password: string, repeatPassword: string }) {
    return this.http.post(`${environment.apiUrl}/auth/setuppassword`, creds, { withCredentials: true });
  }

  // send forgot password email
  sendForgotPassword (email: string) {
    return this.http.post(`${environment.apiUrl}/auth/send-forgot-password-email`, { email }, { withCredentials: true } );
  }

  // reset password
  resetPassword(creds: { invitationId: string; password: string, repeatPassword: string }) {
    return this.http.post(`${environment.apiUrl}/auth/reset-password`, creds, { withCredentials: true });
  }

  // check PIN code
  pinCode (pinCode: string) {
    return this.http.post(`${environment.apiUrl}/auth/token`, { pinCode }, { withCredentials: true } );
  }

  // refresh cookie
  refreshCookie() {
    return this.http.post(`${environment.apiUrl}/auth/token/refresh`, {},{ withCredentials: true });
  }

  // 'ping' invitationId and if it's correct it will send email with PIN
  pingInvitationId(invitationId: string) {
    // we don't care if it is valid or not
    return this.http.post(`${environment.apiUrl}/auth/sendcode`, { invitationId });
  }

  // get profile of current logged user
  profile() {
    return this.http.get(`${environment.apiUrl}/userprofiles/me`,{ withCredentials: true });
  }

  logout() {
    return this.http.post(`${environment.apiUrl}/auth/logout`, {},{ withCredentials: true });
  }

  saveUserToLocalStorage(user: BackofficeUserModel) {
    this.userProfile.next(user);
    this.isAuthenticated.next(true);
    localStorage.setItem('currently_logged_user', JSON.stringify(user));
  }

  loadUserFromLocalStorage() : BackofficeUserModel {
    if (!this.isAuthenticated.value) {
      let fromLocalStorage = localStorage.getItem('currently_logged_user');
      if (fromLocalStorage) {
        this.userProfile.next(JSON.parse(fromLocalStorage));
        this.isAuthenticated.next(true);
      }
    }
    return this.userProfile.value;
  }
}
