import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private api: ApiService
  ) { }

  getAppVersion() {
    return this.api.get(`appversion`);
  }

}
