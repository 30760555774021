import { Component, OnInit } from '@angular/core';
import { InspectorReportService } from '../../shared/services/inspector-report.service';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../shared/constants';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../environments/environment';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { ReportStatusService } from '../../shared/services/report-status.service';
import { ReportsService } from '../../shared/services/reports.service';
import { ReservesManagementService } from '../../shared/services/reserves-management.service';
import { ReserveTypes } from '../../shared/enums/reserveTypes.enum';

@Component({
  selector: 'app-inspector-report',
  templateUrl: './inspector-report.component.html',
  styleUrls: ['./inspector-report.component.scss'],
})
export class InspectorReportComponent implements OnInit {
  private reportKey: any = '';
  public reportData: any = {};
  public reportNote: string = '';

  public reportStatusId: number | undefined;
  public statuses: any = [];
  public disabledStatusNames = AppConstants.disabledStatusNames;

  public logs: any[] = [];
  public logsTableDisplayedColumns: string[] = ['createdDate', 'backOfficeUserFullName', 'reportStatusName', 'note'];

  public gallerySettings = {
    counter: true,
    videojs: true,
    plugins: [lgZoom, lgVideo],
  };

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng,
  };
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  infoWindow: google.maps.InfoWindow | undefined;

  constructor(
    private inspectorReportService: InspectorReportService,
    private route: ActivatedRoute,
    private toast: ToastService,
    private translate: TranslateService,
    private reportStatusesService: ReportStatusService,
    private reportsService: ReportsService,
    private reservesService: ReservesManagementService
  ) {}

  ngOnInit(): void {
    // get content item data
    this.reportKey = this.route.snapshot.paramMap.get('id');

    if (this.reportKey) {
      let requests = [
        this.inspectorReportService.getReportData(this.reportKey),
        this.reportStatusesService.getReportStatusesByInspector(this.reportKey),
        this.reservesService.getReserves(this.reportKey),
      ];

      forkJoin(requests).subscribe({
        next: (responses: any) => {
          // report data
          this.reportData = responses[0];

          this.getLogs();

          this.reportData.categories = this.reportData.categories.sort((a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
          );

          this.inspectorReportService.currentInspectorName = responses[0].inspectorFromKeyFullName;
          this.reportStatusId = responses[0].reportStatusId;

          let loader = new Loader({
            apiKey: environment.googleMapsAPIKey,
            language: environment.googleMapsAPILanguage,
          });

          const center: google.maps.LatLngLiteral = {
            lat: responses[0].latitude,
            lng: responses[0].longitude,
          };

          loader.load().then(() => {
            this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
              center,
              zoom: this.zoom,
              styles: AppConstants.googleMap.styles,
            });

            // draw polygons
            responses[2].forEach((reserve: any) => this.drawReserve(reserve));

            new google.maps.Marker({
              position: center,
              map: this.map,
            });
          });

          // statuses
          this.statuses = responses[1];
        },
        error: (error: any) => {},
      });
    }
  }

  drawReserve(reserve: any, accented = false) {
    if (reserve.hasRadius || reserve.radius > 0) {
      // ToDo: remove reserve.radius > 0
      // circle
      const circle = new google.maps.Circle({
        center: {
          lat: reserve.centerLatitude ?? 0,
          lng: reserve.centerLongitude ?? 0,
        },
        radius: reserve.radius,
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: accented ? 100 : reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map,
      });
      circle.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      });
    } else {
      // polygon
      const coords = reserve.coords ? reserve.coords.replace(new RegExp('lat', 'g'), '"lat"').replace(new RegExp('lng', 'g'), '"lng"') : '';

      const polygon = new google.maps.Polygon({
        paths: JSON.parse(<string>`[${coords}]`),
        strokeColor: accented ? AppConstants.googleMap.accent.strokeColor : reserve.strokeColor,
        strokeOpacity: accented ? AppConstants.googleMap.accent.strokeOpacity : reserve.strokeOpacity,
        strokeWeight: accented ? AppConstants.googleMap.accent.strokeWeight : reserve.strokeWeight,
        fillColor: accented ? AppConstants.googleMap.accent.fillColor : reserve.fillColor,
        fillOpacity: accented ? AppConstants.googleMap.accent.fillOpacity : reserve.fillOpacity,
        draggable: false,
        editable: false,
        zIndex: reserve.type === ReserveTypes.REGION ? 1 : 2,
        map: this.map,
      });

      polygon.addListener('click', (ev: any) => {
        this.showInfoWindow(reserve, ev);
      });
    }
  }

  showInfoWindow(reserve: any, event: any) {
    this.infoWindow?.close();

    this.infoWindow = new google.maps.InfoWindow({
      content: `<h2>${reserve.name}</h2><p>Type: ${reserve.type}</p><p>${reserve.description ?? ''}</p>`,
    });

    this.infoWindow.open({
      map: this.map,
    });

    this.infoWindow.setPosition(event.latLng.toJSON());
  }

  isVideo(fileName: string): boolean {
    return /^https?:\/\/(.+\/)+.+(\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4))$/i.test(fileName);
  }

  getVideoFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
  }

  getDataVideo(fileName: string = ''): string {
    return (
      '{"source": [{"src": "' +
      fileName +
      '", "type":"video/' +
      this.getVideoFileExtension(fileName) +
      '"}], "attributes": {"preload": true, "controls": true, "autoplayVideoOnSlide": false}}'
    );
  }

  getLogs() {
    // get logs
    this.reportsService.getReportLogs(this.reportData.id, this.reportKey).subscribe((reportLogsResponse) => {
      this.logs = reportLogsResponse.sort(function (
        a: { createdDate: string | number | Date },
        b: { createdDate: string | number | Date }
      ) {
        const keyA = new Date(a.createdDate),
          keyB = new Date(b.createdDate);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
    });
  }

  saveReport() {
    const data = {
      reportStatusId: this.reportStatusId,
      note: this.reportNote,
      inspectorKey: this.reportKey,
    };

    this.inspectorReportService.updateReport(this.reportData.id, data).subscribe((resp) => {
      this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_INSPECTOR_REPORT'));

      this.reportNote = '';
      this.getLogs();
    });
  }
}
