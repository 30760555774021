import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ApiService } from "../../shared/services/api.service";
import { ToastService } from "../../shared/services/toast.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ReportStatusService } from "../../shared/services/report-status.service";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { ReportStatusModel } from "../../shared/models/report-status.model";
import { StatesService } from "../../shared/services/states.service";
import { AppConstants } from "../../shared/constants";

@Component({
  selector: 'app-report-status-management',
  templateUrl: './report-status-management.component.html',
  styleUrls: ['./report-status-management.component.scss']
})
export class ReportStatusManagementComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort ;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<ReportStatusManagementComponent>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private reportStatusService: ReportStatusService,
    private statesService: StatesService
  ) { }

  public reportStatuses = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'name'];

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.updateReportStatuses();
  }

  confirmRemoveReportStatus(event: Event, status: ReportStatusModel) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'), this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_REPORT_STATUS'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove report status
        this.reportStatusService.removeReportStatus(status.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_REPORT_STATUS'));
              this.updateReportStatuses();
            },
            error: (error: any) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  updateReportStatuses() {
    this.reportStatusService.getReportStatuses()
      .subscribe({
        next: (resp: any) => {
          this.reportStatuses.data = resp;

          this.paginator.pageIndex = this.statesService.reportStatusesPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.reportStatusesPaginator.pageSize;
          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

          this.reportStatuses.paginator = this.paginator;
          this.reportStatuses.sort = this.sort;
        },
        error: (error) => {}
      })
  }

  gotoAddReportStatusPage() {
    this.router.navigate(['report-status-add']);
  }

  changePage(event: any) {
    this.statesService.reportStatusesPaginator.pageIndex = event.pageIndex;
    this.statesService.reportStatusesPaginator.pageSize = event.pageSize;
  }
}
