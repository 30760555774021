import { Component, OnInit } from '@angular/core';

const customPresets = [
  'today',
  'last 7 days',
  'this week',
  'this month',
  'this year',
  'last week',
  'last month',
  'last year',
] as const;

type CustomPreset = typeof customPresets[number];

@Component({
  selector: 'app-custom-calendar-header',
  templateUrl: './custom-calendar-header.component.html',
  styleUrls: ['./custom-calendar-header.component.scss']
})
export class CustomCalendarHeaderComponent implements OnInit {

  readonly customPresets = customPresets;

  constructor() { }

  ngOnInit(): void {
  }

  // called when user selects a range preset:
  selectRange(rangeName: CustomPreset): void {
    // ... implementation follows
  }

}
