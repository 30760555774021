import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationUsersService } from "../../../shared/services/application-users.service";
import { Router } from "@angular/router";
import { ToastService } from "../../../shared/services/toast.service";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from "@angular/material/chips";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userService: ApplicationUsersService,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.email],
      receiveNewsInfo: '',
      receiveReportInfo: '',
      seaRelations: []
    });
  }

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  relations: any = [];

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.relations.push(value);
    }

    event.chipInput!.clear();
  }

  remove(relation: any): void {
    const index = this.relations.indexOf(relation);

    if (index >= 0) {
      this.relations.splice(index, 1);
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  ngOnInit(): void {
  }

  createApplicationUser() {
    if (this.form.valid) {

      const formData = this.form.value;
      this.userService.addUser({
                                      firstName: formData.firstName,
                                      lastName: formData.lastName,
                                      phoneNumber: formData.phoneNumber,
                                      email: formData.email,
                                      receiveNewsInfo: !!formData.receiveNewsInfo,
                                      receiveReportInfo: !!formData.receiveReportInfo,
                                      seaRelations: this.relations
                                    }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_USER'));
          this.router.navigate(['/users']);
        },
        error: (error) => {},
      });

    }

  }

}
