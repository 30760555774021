export declare type SortDirection = 'asc' | 'desc' | '';

export interface SortingModel {
  orderBy: string;
  sortingDirection: SortDirection;
}

export class SortingClass {
  orderBy: string;
  sortingDirection: SortDirection;

  constructor(data?: SortingModel) {
    this.orderBy = data?.orderBy ?? '';
    this.sortingDirection = data?.sortingDirection ?? '';
  }
}
