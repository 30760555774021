import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastService } from "../../shared/services/toast.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ReservesManagementService } from "../../shared/services/reserves-management.service";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { ReserveModel } from "../../shared/models/reserve.model";
import { AppConstants } from "../../shared/constants";
import { StatesService } from "../../shared/services/states.service";

@Component({
  selector: 'app-reserves-management',
  templateUrl: './reserves-management.component.html',
  styleUrls: ['./reserves-management.component.scss']
})
export class ReservesManagementComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort ;

  constructor(
    private toast: ToastService,
    public dialogRef: MatDialogRef<ReservesManagementComponent>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private reservesService: ReservesManagementService,
    private statesService: StatesService
  ) { }

  public reserves = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'name', 'type', 'parentId', 'parentName', 'isVisible'];

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.reserves.paginator = this.paginator;
    this.reserves.sort = this.sort;

    this.updateReserves();
  }

  updateReserves() {
    this.reservesService.getReserves()
      .subscribe({
        next: (resp: any) => {

          this.reserves.data = resp;

          this.paginator.pageIndex = this.statesService.reservesPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.reservesPaginator.pageSize;
          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

          this.reserves.paginator = this.paginator;
          this.reserves.sort = this.sort;
        },
        error: (error) => {}
      })
  }

  confirmRemoveReserve(event: Event, reserve: ReserveModel) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RESERVE'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove reserve
        this.reservesService.deleteReserve(reserve.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_RESERVE'));
              this.updateReserves();
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  gotoAddReserve() {
    this.router.navigate(['reserve-add']);
  }

  changePage(event: any) {
    this.statesService.reservesPaginator.pageIndex = event.pageIndex;
    this.statesService.reservesPaginator.pageSize = event.pageSize;
  }

}
