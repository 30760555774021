export const AppConstants = {
  googleMap: {
    initialCenter: {
      lat: 31.585025210751787,
      lng: 34.48306061718751
    },
    initialZoom: 8,

    default: {
      fillColor: '#ffffff',
      fillOpacity: 0.3,

      strokeColor: '#ffffff',
      strokeOpacity: 0.5,
      strokeWeight: 3
    },

    accent: {
      fillColor: '#c62828',
      fillOpacity: 0.6,
      strokeColor: '#ffffff',
      strokeWeight: 2,
      strokeOpacity: 1
    },

    region: {
      fillColor: '#1565c0',
      fillOpacity: 0.3,

      strokeColor: '#1565c0',
      strokeOpacity: 0.5,
      strokeWeight: 2
    },

    natureReserve: {
      fillColor: '#4caf50',
      fillOpacity: 0.6,

      strokeColor: '#4caf50',
      strokeOpacity: 0.9,
      strokeWeight: 2
    },

    circle: {
      initialRadius: 30000
    },

    polygon: {
      initialCoords: [
        { lat:31.338755319447124, lng:34.235868988037105 },
        { lat:31.67974071335641, lng:34.494621307373066 },
        { lat:31.656657381958667, lng:34.78187939453123 },
        { lat:31.342616057630327, lng:34.747875183105485 }
      ]
    },

    styles: [
      {
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#ebe3cd"
          }
        ]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#523735"
          }
        ]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#f5f1e6"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#c9b2a6"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#dcd2be"
          }
        ]
      },
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#ae9e90"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#93817c"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a5b076"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#447530"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f5f1e6"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#fdfcf8"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#f8c967"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#e9bc62"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#e98d58"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#db8555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#806b63"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#8f7d77"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#ebe3cd"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#dfd2ae"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b9d3c2"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#92998d"
          }
        ]
      }
    ]
  },

  paginator: {
    pageSizeOptions: [10, 50, 100]
  },

  messageTextMaxLength: {
    sms: 160,
    email: 1024,
    whatsapp: 800
  },

  disabledStatusNames: ['Draft', 'Open', 'In progress'],

  linkPlaceholder: '[link]',

  maximumOrderValue: 1000
}

