import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoriesService } from "../../shared/services/categories.service";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastService } from "../../shared/services/toast.service";
import { ReportCategoryModel } from "../../shared/models/report-category.model";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../../shared/constants";
import { StatesService } from "../../shared/services/states.service";

@Component({
  selector: 'app-report-categories',
  templateUrl: './report-categories.component.html',
  styleUrls: ['./report-categories.component.scss']
})
export class ReportCategoriesComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort ;

  constructor(
    private categoriesService: CategoriesService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<ReportCategoriesComponent>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private statesService: StatesService
  ) { }

  public reportCategories = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'name', 'hint', 'updatedDate'];

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.reportCategories.paginator = this.paginator;
    this.reportCategories.sort = this.sort;

    this.updateCategories();
  }

  updateCategories() {
    this.categoriesService.getCategories()
      .subscribe({
        next: (resp: any) => {

          this.reportCategories.data = resp;

          this.paginator.pageIndex = this.statesService.reportCategoriesPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.reportCategoriesPaginator.pageSize;
          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

          this.reportCategories.paginator = this.paginator;
          this.reportCategories.sort = this.sort;
        },
        error: (error) => {}
      })
  }

  confirmRemoveCategory(event: Event, category: ReportCategoryModel) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'), this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CATEGORY'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove category
        this.categoriesService.deleteCategory(category.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_REPORT_CATEGORY'));
              this.updateCategories();
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  gotoAddReportCategoryPage() {
    this.router.navigate(['categories-add']);
  }

  changePage(event: any) {
    this.statesService.reportCategoriesPaginator.pageIndex = event.pageIndex;
    this.statesService.reportCategoriesPaginator.pageSize = event.pageSize;
  }
}
