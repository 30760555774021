<div class="crud-page">
  <div class="page-header">
    <a routerLink="/backoffice-users" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{ "PAGES.BACKOFFICE_USERS.BACK" | translate }}</a
    >
    <h1>{{ "PAGES.BACKOFFICE_USER_UPDATE.TITLE" | translate }}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="updateBackofficeUser()" [disabled]="!form.valid || !inspectorCheck()">
      {{ "BUTTONS.UPDATE_USER" | translate }}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form columns">
      <div>
        <mat-form-field>
          <input
            matInput
            placeholder="{{ 'PAGES.BACKOFFICE_USERS.FULL_NAME' | translate }}"
            type="text"
            formControlName="fullName"
            required
          />
          <mat-error *ngIf="checkError('fullName', 'required')">{{
            "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_FULL_NAME" | translate
          }}</mat-error>
          <mat-error *ngIf="checkError('fullName', 'pattern')">{{ "ERROR_MESSAGES.PATTERNS.NAME" | translate }}</mat-error>
        </mat-form-field>
        <div class="field-and-checkboxes">
          <mat-form-field>
            <input matInput placeholder="{{ 'PAGES.BACKOFFICE_USERS.EMAIL' | translate }}" type="text" formControlName="email" required />
            <mat-error *ngIf="checkError('email', 'required')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_EMAIL" | translate }}</mat-error>
            <mat-error *ngIf="checkError('email', 'email')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.INVALID_EMAIL" | translate }}</mat-error>
          </mat-form-field>
          <mat-checkbox
            [(ngModel)]="receiveEmail"
            *ngIf="form.get('role')?.value === userRoles.INSPECTOR"
            [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox>
        </div>
        <div class="field-and-checkboxes">
          <mat-form-field style="flex: 2">
            <input
              matInput
              placeholder="{{ 'PAGES.BACKOFFICE_USERS.MOBILE' | translate }}"
              type="text"
              formControlName="mobile"
              [required]="form.get('role')?.value === userRoles.INSPECTOR"
            />
            <mat-error *ngIf="checkError('mobile', 'pattern')">{{ "ERROR_MESSAGES.PATTERNS.MOBILE" | translate }}</mat-error>
            <mat-error *ngIf="checkError('mobile', 'conditionalMobile')">{{
              "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_MOBILE" | translate
            }}</mat-error>
          </mat-form-field>
          <div class="checkboxes" *ngIf="form.get('role')?.value === userRoles.INSPECTOR && !!form.get('mobile')?.value">
            <mat-checkbox
              class="vertical-layout"
              [(ngModel)]="receiveSMS"
              [ngModelOptions]="{ standalone: true }"
            >{{'PAGES.MESSAGES_MANAGEMENT.SMS' | translate}}</mat-checkbox>
            <mat-checkbox
              class="vertical-layout"
              [(ngModel)]="receiveWhatsApp"
              *ngIf="form.get('role')?.value === userRoles.INSPECTOR"
              [ngModelOptions]="{ standalone: true }"
            >{{'PAGES.MESSAGES_MANAGEMENT.WHATSAPP' | translate}}</mat-checkbox>
          </div>
        </div>
        <mat-form-field>
          <mat-select placeholder="{{ 'PAGES.BACKOFFICE_USERS.ROLE' | translate }}" formControlName="role" required>
            <mat-option [value]="userRoles.ADMIN">{{ "ROLES.ADMIN" | translate }}</mat-option>
            <mat-option [value]="userRoles.INSPECTOR">{{ "ROLES.INSPECTOR" | translate }}</mat-option>
            <mat-option [value]="userRoles.REPORT_USER">{{ "ROLES.REPORT_USER" | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="checkError('role', 'required')">{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_ROLE" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="form.get('role')?.value === userRoles.INSPECTOR">
        <mat-form-field class="half-width">
          <mat-select
            placeholder="{{ 'PAGES.BACKOFFICE_USERS.CATEGORIES' | translate }}"
            [formControl]="categoryMultiCtrl"
            [multiple]="true"
            [required]="form.get('role')?.value === userRoles.INSPECTOR"
          >
            <mat-option>
              <ngx-mat-select-search
                [showToggleAllCheckbox]="true"
                [formControl]="categoryFilterCtrl"
                [placeholderLabel]="translate.instant('COMMON.SEARCH')"
                [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
                (toggleAll)="toggleSelectAllCategories($event)"
                [toggleAllCheckboxIndeterminate]="isAllCategoriesIndeterminate"
                [toggleAllCheckboxChecked]="isAllCategoriesChecked"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let category of filteredCategories | async" [value]="category.id">
              {{ category.name }}
            </mat-option>
          </mat-select>
          <mat-error>{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_CATEGORY" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="half-width">
          <mat-select
            placeholder="{{ 'PAGES.BACKOFFICE_USERS.RESERVES' | translate }}"
            [formControl]="reserveMultiCtrl"
            [multiple]="true"
            [required]="form.get('role')?.value === userRoles.INSPECTOR"
          >
            <mat-option>
              <ngx-mat-select-search
                [showToggleAllCheckbox]="true"
                [formControl]="reserveFilterCtrl"
                [placeholderLabel]="translate.instant('COMMON.SEARCH')"
                [noEntriesFoundLabel]="translate.instant('COMMON.NO_DATA_MATCHING_YOUR_REQUEST')"
                (toggleAll)="toggleSelectAllReserves($event)"
                [toggleAllCheckboxIndeterminate]="isAllReservesIndeterminate"
                [toggleAllCheckboxChecked]="isAllReservesChecked"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let reserve of filteredReserves | async" [value]="reserve.id">
              {{ reserve.name }}
            </mat-option>
          </mat-select>
          <mat-error>{{ "PAGES.BACKOFFICE_USERS.ERRORS.REQUIRED_RESERVE" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
