import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private api: ApiService
  ) { }

  // Content

  getContentItems(searchTerm: string = '') {
    if (!!searchTerm) {
      return this.api.get(`contentobjects/search/${searchTerm}`);
    } else {
      return this.api.get(`contentobjects`);
    }
  }

  addContent(data: any) {
    return this.api.post(`contentobjects`, data);
  }

  updateContent(data: any) {
    return this.api.put(`contentobjects`, data);
  }

  getContent(id: number) {
    return this.api.get(`contentobjects/${id}`);
  }

  removeContent(id: number) {
    return this.api.delete(`contentobjects/${id}`);
  }

  // Content Groups
  getGroups() {
    return this.api.get(`contentobjects/groups`);
  }

  setContentOrder(contentId: number, order: number): Observable<any> {
    return this.api.put(`contentobjects/${contentId}/${order}`, {});
  }
}
