import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private api: ApiService
  ) { }

  getCategories() {
    return this.api.get(`categories`);
  }

  addCategory(formData: any) {
    return this.api.post(`categories`, formData);
  }

  updateCategory(formData: any) {
    return this.api.put(`categories`, formData);
  }

  getCategory(id: number | string) {
    return this.api.get(`categories/${id}`);
  }

  deleteCategory(id: number | string ) {
    return this.api.delete(`categories/${id}`);
  }

  searchContentCategory(search: string) {
    return this.api.get(`contentobjects/search`, { searchTerm: search });
  }
}
