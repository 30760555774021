import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class HomePageContactsService {

  constructor(
    private api: ApiService
  ) { }

  getContacts() {
    return this.api.get(`contacts`);
  }

  updateContacts(data: any) {
    return this.api.put(`contacts`, data);
  }
}
