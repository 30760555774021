import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
@Injectable()
export class TranslationResolver implements Resolve<any> {
  constructor(
    private translate: TranslateService
  ) {}
  resolve() {
    return this.translate.get('__LAST__');
  }

}
