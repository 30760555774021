import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

  title: string = '';
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel
  ) {
    this.title = data.title;
    this.message = data.message;
  }

  onConfirm(): void {
    // confirmed
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // not confirmed
    this.dialogRef.close(false);
  }

}

export class ConfirmModel {
  constructor(
    public title: string,
    public message: string
  ) {
  }
}
