import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, delay, tap, filter, map, takeUntil } from 'rxjs/operators';
import { CategoriesService } from "../../../shared/services/categories.service";
import { ApiService } from "../../../shared/services/api.service";
import { ToastService } from "../../../shared/services/toast.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnDestroy {

  public form: FormGroup;

  protected content: any = [];
  public contentServerSideFilteringCtrl: FormControl = new FormControl();
  public searching = false;
  public filteredServerSideContent: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public currentIcon: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public currentPreview: BehaviorSubject<any> = new BehaviorSubject<any>('');

  protected _onDestroy = new Subject<void>();

  public fileName = '';

  constructor(
    private categoriesService: CategoriesService,
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {

    this.form = this.fb.group({
      categoryName: ['', [Validators.required, Validators.minLength(3)]],
      categoryIconSource: ['', Validators.required],
      categoryIcon: '',
      linkToContent: ['', Validators.required],
      hint: ['', Validators.required]
    });

  }


  ngOnInit(): void {
    // get initial content values
    this.categoriesService.searchContentCategory('').subscribe({
      next: (resp: any) => {
        this.filteredServerSideContent.next(resp);
      },
      error: (error: any) => {}});


    // listen for search field value changes
    this.contentServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.content) {
            return [];
          }

          // server fetching and filtering data
          return this.categoriesService.searchContentCategory(search).subscribe({
            next: (resp: any) => {
              this.filteredServerSideContent.next(resp);
              this.searching = false;
            },
            error: (error: any) => {
              this.searching = false;
            }
          })
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe({});
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  onFileSelected(event: any) {

    if (event.target.files.length > 0) {
      this.form.patchValue({
        categoryIcon: event.target.files[0]
      });
      this.currentIcon.next(this.form.get('categoryIconSource')?.value);
      this.preparePreview(event.target.files[0]);
    }
  }

  private preparePreview(file: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.currentPreview.next(reader.result);
    }
  }

  public get iconPreview(): string {
    return this.currentPreview.getValue();
  }

  createReportCategory() {
    if (this.form.valid) {

      let formData: any = new FormData();
      formData.append('Name', this.form.get('categoryName')?.value);
      if (this.form.get('hint')?.value) formData.append('Hint', this.form.get('hint')?.value);
      if (this.form.get('linkToContent')?.value) formData.append('ContentId', this.form.get('linkToContent')?.value);
      if (this.form.get('categoryIcon')?.value) formData.append('Image', this.form.get('categoryIcon')?.value);

      this.categoriesService.addCategory(formData).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.CREATED_REPORT_CATEGORY'));
          this.router.navigate(['/categories']);
        },
        error: (error) => {},
      });
    }

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
