<ng-container *ngIf="isStandAlone; then standAloneComponent; else complexComponent"></ng-container>

<ng-template #complexComponent>
  <app-header (toggleSidebarEvent)="sidenav.toggle()"></app-header>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" opened class="sidenav">
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover">
        <mat-nav-list>
          <a *ngIf="ifUserHasAccess('backofficeUsers')" mat-list-item routerLinkActive="is-active" routerLink="/backoffice-users">
            <mat-icon>admin_panel_settings</mat-icon>
            {{'SIDE_MENU.BACKOFFICE_USERS' | translate}}</a>
          <a *ngIf="ifUserHasAccess('applicationUsers')" mat-list-item routerLinkActive="is-active" routerLink="/users">
            <mat-icon>supervisor_account</mat-icon>
            {{'SIDE_MENU.APPLICATION_USERS' | translate}}</a>
          <a *ngIf="ifUserHasAccess('reserves')" mat-list-item routerLinkActive="is-active" routerLink="/reserves-management">
            <mat-icon>pin_drop</mat-icon>
            {{'SIDE_MENU.RESERVES' | translate}}</a>
          <a *ngIf="ifUserHasAccess('reports')" mat-list-item routerLinkActive="is-active" routerLink="/reports-tickets">
            <mat-icon>record_voice_over</mat-icon>
            {{'SIDE_MENU.REPORTS' | translate}}</a>
          <mat-expansion-panel *ngIf="ifUserHasAccess('categories')">
            <mat-expansion-panel-header>
              <mat-icon>record_voice_over</mat-icon>
              {{'SIDE_MENU.CATEGORIES' | translate}}
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a mat-list-item routerLinkActive="is-active" routerLink="/pages-management">
                <mat-icon>noise_control_off</mat-icon>
                {{'SIDE_MENU.CATEGORIES_APP_PAGES' | translate}}
              </a>
              <a mat-list-item routerLinkActive="is-active" routerLink="/categories">
                <mat-icon>noise_control_off</mat-icon>
                {{'SIDE_MENU.CATEGORIES_REPORT_CATEGORIES' | translate}}
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <a *ngIf="ifUserHasAccess('contentManagement')" mat-list-item routerLinkActive="is-active" routerLink="/content-management">
            <mat-icon>tab</mat-icon>
            {{'SIDE_MENU.CONTENT_MANAGEMENT' | translate}}
          </a>
          <a *ngIf="ifUserHasAccess('reportStatusManagement')" mat-list-item routerLinkActive="is-active" routerLink="/report-status-management">
            <mat-icon>tab</mat-icon>
            {{'SIDE_MENU.REPORT_STATUS_MANAGEMENT' | translate}}
          </a>

          <mat-expansion-panel *ngIf="ifUserHasAccess('notifications')">
            <mat-expansion-panel-header>
              <mat-icon>record_voice_over</mat-icon>
              {{'SIDE_MENU.NOTIFICATIONS' | translate}}
            </mat-expansion-panel-header>
            <mat-nav-list dense>
              <a mat-list-item routerLinkActive="is-active" routerLink="/home-page-contacts">
                <mat-icon>noise_control_off</mat-icon>
                {{'SIDE_MENU.HOME_PAGE_CONTACTS' | translate}}
              </a>
              <a mat-list-item routerLinkActive="is-active" routerLink="/messages-management">
                <mat-icon>noise_control_off</mat-icon>
                {{'SIDE_MENU.MESSAGES_MANAGEMENT' | translate}}
              </a>
            </mat-nav-list>
          </mat-expansion-panel>


        </mat-nav-list>
      </ng-scrollbar>
      <app-version></app-version>
    </mat-sidenav>
    <mat-sidenav-content>
      <ng-scrollbar [autoHeightDisabled]="false" visibility="hover">
        <div class="scroll-content">
          <router-outlet></router-outlet>
        </div>
      </ng-scrollbar>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<ng-template #standAloneComponent>
  <app-header [reportHeader]="true" *ngIf="isReport"></app-header>
  <router-outlet></router-outlet>
</ng-template>

<div *ngIf="isLoading | async" class="overlay">
  <mat-spinner class="spinner"></mat-spinner>
</div>
