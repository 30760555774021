<div class="crud-page">
  <div class="page-header">
    <a class="link back-button" routerLink="/reserves-management"><mat-icon>arrow_left</mat-icon>{{'PAGES.RESERVES_MANAGEMENT.BACK' | translate}}</a>
    <h1>{{'PAGES.RESERVE_ADD.TITLE' | translate}}</h1>
    <button (click)="startReserveAdding()" [disabled]="!form.valid" class="toolbar" color="primary" mat-flat-button>{{'BUTTONS.ADD_RESERVE' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container wide">
      <div id="google-map"></div>
      <div id="controls">
        <form [formGroup]="form" class="vertical-form wide">
          <!--
          <mat-form-field class="half-width">
            <mat-label>Current Reserve</mat-label>
            <mat-select formControlName="reserveParent" (selectionChange)="updateMap()" required>
              <mat-option *ngFor="let existingRegion of existingRegions" [value]="existingRegion.id">
                {{existingRegion.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          -->

          <mat-form-field>
            <mat-label>{{'PAGES.RESERVES_MANAGEMENT.NAME' | translate}}</mat-label>
            <input formControlName="name" matInput>
            <mat-error *ngIf="checkError('name', 'required')">{{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_NAME' | translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="description">
            <mat-label>{{'PAGES.RESERVES_MANAGEMENT.DESCRIPTION' | translate}}</mat-label>
            <textarea formControlName="description" matInput></textarea>
          </mat-form-field>

          <mat-checkbox class="is-visible" formControlName="isVisible">{{'PAGES.RESERVES_MANAGEMENT.IS_VISIBLE' | translate}}</mat-checkbox>

          <mat-radio-group formControlName="reserveType" class="in-row" (change)="updateMap()">
            <mat-radio-button value="{{reserveTypes.REGION}}">{{reserveTypes.REGION}}</mat-radio-button>
            <mat-radio-button value="{{reserveTypes.NATURE_RESERVE}}">{{reserveTypes.NATURE_RESERVE}}</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="checkError('reserveType', 'required')">{{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_RESERVE_TYPE' | translate}}</mat-error>

          <mat-form-field *ngIf="form.get('reserveType')?.value === reserveTypes.NATURE_RESERVE" class="half-width region">
            <mat-label>{{'PAGES.RESERVES_MANAGEMENT.REGION' | translate}} *</mat-label>
            <mat-select formControlName="reserveParent" (selectionChange)="updateMap()">
              <mat-option *ngFor="let existingRegion of existingRegions" [value]="existingRegion.id">
                {{existingRegion.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="checkError('reserveParent', 'conditionalRegion')">{{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_REGION' | translate}}</mat-error>
          </mat-form-field>

          <mat-button-toggle-group  class="type-toggle">
            <mat-button-toggle value="{{polygonTypes.RADIUS}}" (click)="switchToRadius()">
              {{'PAGES.RESERVES_MANAGEMENT.CIRCLE' | translate}}
            </mat-button-toggle>
            <mat-button-toggle value="{{polygonTypes.POLYGON}}" (click)="switchToPolygon()">
              {{'PAGES.RESERVES_MANAGEMENT.POLYGON' | translate}}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <mat-error *ngIf="checkError('polygonType', 'required')">{{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_POLYGON_TYPE' | translate}}</mat-error>

          <ng-container *ngIf="polygonType === polygonTypes.RADIUS">
            <div class="same-row">
              <mat-form-field class="half-width">
                <mat-label>{{'PAGES.RESERVES_MANAGEMENT.LAT' | translate}}</mat-label>
                <input formControlName="circleCoordsLat" matInput (change)="updateCircle()" required>
                <mat-error *ngIf="checkError('circleCoordsLat', 'required')">
                  {{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_LATITUDE' | translate}}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="half-width">
                <mat-label>{{'PAGES.RESERVES_MANAGEMENT.LNG' | translate}}</mat-label>
                <input formControlName="circleCoordsLng" matInput (change)="updateCircle()" required>
                <mat-error *ngIf="checkError('circleCoordsLng', 'required')">
                  {{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_LONGITUDE' | translate}}
                </mat-error>
              </mat-form-field>
            </div>

            <mat-form-field>
              <mat-label>{{'PAGES.RESERVES_MANAGEMENT.RADIUS' | translate}}</mat-label>
              <input formControlName="circleRadius" matInput (change)="updateCircle()" required>
              <mat-error *ngIf="checkError('circleCoordsLat', 'required')">
                {{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_RADIUS' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="polygonType === polygonTypes.POLYGON">
            <mat-form-field class="coords">
              <mat-label>{{'PAGES.RESERVES_MANAGEMENT.COORDS' | translate}}</mat-label>
              <textarea formControlName="polygonCoordsJSON" matInput (change)="updatePolygon()" required></textarea>
              <mat-error *ngIf="checkError('polygonCoordsJSON', 'required')">
                {{'PAGES.RESERVES_MANAGEMENT.ERRORS.REQUIRED_COORDINATES' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>

          <!--
          <div class="same-row">
            <mat-form-field class="half-width">
              <mat-label>Fill Color</mat-label>
              <input formControlName="fillColor" matInput [ngxMatColorPicker]="fillColorPicker" (colorChange)="updateColors()">
              <ngx-mat-color-toggle matSuffix [for]="fillColorPicker"></ngx-mat-color-toggle>
              <ngx-mat-color-picker #fillColorPicker></ngx-mat-color-picker>
            </mat-form-field>

            <mat-form-field class="half-width">
              <mat-label>Stroke Color</mat-label>
              <input formControlName="strokeColor" matInput [ngxMatColorPicker]="strokeColorPicker" (colorChange)="updateColors()">
              <ngx-mat-color-toggle matSuffix [for]="strokeColorPicker"></ngx-mat-color-toggle>
              <ngx-mat-color-picker #strokeColorPicker></ngx-mat-color-picker>
            </mat-form-field>
          </div>
          -->
        </form>
      </div>
    </div>
  </div>
</div>
