import { Component, OnInit } from '@angular/core';
import { PageChildren, PageModel } from "../../../shared/models/page.model";
import { ReportCategoryModel } from "../../../shared/models/report-category.model";
import { CategoryTypes } from "../../../shared/enums/categoryTypes.enum";
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, Observer, of, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { PagesManagementService } from "../../../shared/services/pages-management.service";
import { CategoriesService } from "../../../shared/services/categories.service";
import { BreadcrumbsService } from "../../../shared/services/breadcrumbs.service";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmComponent, ConfirmModel } from "../../modal-dialogs/confirm/confirm.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ComponentCanDeactivate } from "../../../shared/interfaces/component-can-deactivate";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogAnswers } from "../../../shared/enums/confirmDialogAnswers.enum";
import { ToastMessageTypes } from "../../../shared/enums/toastMessageTypes.enum";
import { ContentSeas } from "../../../shared/enums/contentSeas.enum";

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss']
})
export class EditPageComponent implements OnInit, ComponentCanDeactivate {

  public form: FormGroup;

  private hasUnsavedData = false;
  private formChangeSubscription: Subscription | undefined;

  public pageId: any;
  public page: PageModel | undefined;

  public pages: PageModel[] | undefined;
  public categories: ReportCategoryModel[] | undefined

  public categoryTypes = CategoryTypes;
  public areaSeas = ContentSeas;

  public previewItems: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public savingStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PagesManagementService,
    private categoriesService: CategoriesService,
    private breadcrumbsService: BreadcrumbsService,
    private fb: FormBuilder,
    private toast: ToastService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<EditPageComponent>,
    private dialog: MatDialog
  ) {

    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      subTitle: '',
      main: '',
      categoryType: '',
      children: this.fb.array([])
    }, { validators: [
        this.checkedPageType('categoryType'),
        this.checkedSea('children'),
        this.notEmptyChildrenValidator('children')]});

    this.formChangeSubscription = this.form.valueChanges.subscribe((value) => {
      this.hasUnsavedData = true;
    });

  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.hasUnsavedData) {
      return new Observable((observer: Observer<any>) => {

        const message = this.translate.instant('DIALOGS.CHANGES_ARE_NOT_SAVED');
        const dialogData = new ConfirmDialogModel(this.translate.instant('DIALOGS.CHANGES_ARE_NOT_SAVED_TITLE'), message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: dialogData,
          restoreFocus: false
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
          if (dialogResult === ConfirmDialogAnswers.YES) {
            this.checkBreadcrumbs();
            observer.next(true);
          } else if (dialogResult === ConfirmDialogAnswers.NO) {
            observer.next(false);
          } else if (dialogResult === ConfirmDialogAnswers.SAVE_AND_EXIT) {
            this.updatePage(false).subscribe({
              next:value => {
                if (value) this.checkBreadcrumbs();
                observer.next(value);
              }, error: err => {
                observer.next(false);
              }
            })
          } else {
            observer.next(false);
          }
        });
      });
    } else {
      this.checkBreadcrumbs();
      return of(true);
    }
  }

  ngOnInit(): void {
    // get page's data
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.getData();

    // record first breadcrumb item if needed
    if (this.breadcrumbsService.breadcrumbsItems.getValue().length === 0) {
      this.breadcrumbsService.addBreadcrumb(this.pageId);
    }
  }

  checkBreadcrumbs() {
    this.breadcrumbsService.pendingAddingBreadcrumbItem !== undefined
    && this.breadcrumbsService.addBreadcrumb(this.breadcrumbsService.pendingAddingBreadcrumbItem);

    this.breadcrumbsService.pendingShorteningBreadcrumbItem !== undefined
    && this.breadcrumbsService.clearAfterIndex(this.breadcrumbsService.pendingShorteningBreadcrumbItem);
  }

  checkedSea(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      // need to have sea selected
      let seaSelected = true;
      control.value.forEach((value:any) => {
        if (value.sea !== ContentSeas.RED && value.sea !== ContentSeas.MEDITERRANEAN) {
          seaSelected = false
        }
      })

      // set error if validation fails
      if (control.value.length > 0 && !seaSelected) {
        control.setErrors({ seaRequired: true });
      }

    }
  }

  checkedPageType(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      // set error if validation fails
      if (!(!this.form?.get('main')?.value ||
        this.form?.get('main')?.value && (control.value === CategoryTypes.VIOLATION ||
          control.value === CategoryTypes.OBSERVATION))) {
        control.setErrors({ categoryRequired: true });
      }
    }
  }

  notEmptyChildrenValidator(controlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];

      // need to have at least one category selected
      let categorySelected = false;
      control.value.forEach((value:PageChildren) => {
        if (value.categoryId > 0) categorySelected = true
      })

      // set error if validation fails
      if (control.value.length > 0 && !categorySelected) {
        control.setErrors({ emptyChildren: true });
      }
    }
  }

  addPageChild(categoryId = 0, pageId = 0, order = 0, sea: ContentSeas, promoted = false) {
    this.pageChildren.push(this.newChild(categoryId, pageId, order, sea, promoted));
  }

  addNewChild() {
    const max_order = Math.max(...this.form.value.children.map((child:PageChildren) => child.order), 0) + 1;
    this.pageChildren.push(this.newChild(0, 0, max_order, ContentSeas.NONE, false));
  }

  removeChild(i:number) {
    this.pageChildren.removeAt(i);
  }

  get pageChildren() {
    return this.form.get('children') as FormArray;
  }

  newChild(categoryId: number, pageId: number, order: number, sea: ContentSeas, promoted: boolean): FormGroup {
    return this.fb.group({
      categoryId,
      pageId,
      order,
      sea,
      promoted
    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  getData() {

    let requests = [
      this.pagesService.getPage(this.pageId),
      this.pagesService.getPages(),
      this.categoriesService.getCategories()
    ];

    forkJoin(requests).subscribe({
      next: (responses: any) => {

        // responses[0] - page info
        let pageInfo = responses[0];
        pageInfo.children = pageInfo.children.sort((
          a: { order: number; },
          b: { order: number; }) => (a.order > b.order ? 1 : -1));
        this.page = pageInfo;

        // responses[1] - all pages
        this.pages = responses[1]
          .filter((response: { id: number; }) => response.id !== +this.pageId)
          .sort((a: { title: string; }, b: { title: string; }) => (a.title > b.title ? 1 : -1));

        // responses[2] - all categories
        this.categories = responses[2].sort((a: { name: string; }, b: { name: string; }) => (a.name > b.name ? 1 : -1));

        this.previewItems.next(this.getItems());

        // update form fields
        this.form.get('main')?.setValue(this.page?.main);
        this.form.get('categoryType')?.setValue(this.page?.categoryType);
        this.form.get('title')?.setValue(this.page?.title);
        this.form.get('subTitle')?.setValue(this.page?.subTitle);

        pageInfo.children.forEach((child: PageChildren) => {
          return this.addPageChild(child.categoryId, child.pageId, child.order, child.sea, child.promoted);
        })

        // mark form with updated data as untouched yet
        this.hasUnsavedData = false;

      },
      error: (error) => {}
    });

  }

  getCategoryName(id: number) {
    return this.categories?.find(category => category.id === id)?.name;
  }

  getPageTitle(id: number) {
    return this.pages?.find(page => page.id === id)?.title;
  }

  getItems() {
    return this.page?.children?.map(child => child.pageId && child.pageId > 0 ?
      {
        name: this.getPageTitle(child.pageId),
        type: 'page',
        promoted: child.promoted
      } :
      {
        name: this.getCategoryName(child.categoryId),
        type: 'category',
        promoted: child.promoted
      });
  }

  get unconnectedPagesNumber() : number | undefined {
    let withoutPage = this.page?.children?.filter(child => child.pageId === 0);
    return withoutPage && withoutPage.length;
  }

  gotoChild(pageId: number) {
    this.breadcrumbsService.pendingAddingBreadcrumbItem = pageId;
    // this.breadcrumbsService.addBreadcrumb(pageId);
    this.router.navigate([`/page-view/${pageId}`], {replaceUrl: true});
  }

  // ToDo: Remove this temporary solution
  choosePage(child: any, index: number) {
    let selectBox = document.getElementsByClassName('page-select-' + index) as HTMLCollectionOf<HTMLElement>;
    selectBox && selectBox[0].click();
  }

  checkCategoryType() {
    if (!this.form.get('main')?.value) this.form.get('categoryType')?.patchValue(CategoryTypes.NONE);
  }

  checkMain() {
    this.form.get('main')?.patchValue(true);
  }

  async startPageUpdating() {
    await firstValueFrom(this.updatePage());
  }

  updatePage(defaultNavigation = true): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.form.valid) {

        let data = this.form.value;
        data.id = this.page?.id;

        data.children = data.children.map((child:PageChildren) => {
          if (child.pageId && child.pageId > 0) return child;

          return {
            order: child.order,
            promoted: child.promoted,
            categoryId: child.categoryId,
            sea: child.sea
          };
        });

        this.pagesService.updatePage(data).subscribe({
          next: (resp: any) => {
            this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_PAGE'));
            this.hasUnsavedData = false;
            if (defaultNavigation) {
              this.breadcrumbsService.updateBreadcrumbsData();
              this.router.navigate(['/page-view/' + this.pageId]);
            }

            observer.next(true);
          },
          error: (error) => {
            observer.next(false);
          },
        });
      } else {
        this.toast.show(this.translate.instant('ERROR_MESSAGES.CANT_SAVE_THIS_DATA_YET'), ToastMessageTypes.ERROR);
        observer.next(false);
      }
    });
  }

  confirmRemovePage(event: Event, pageId: number) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAGE'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove page
        this.pagesService.deletePage(pageId)
          .subscribe({
            next: (resp: any) => {
              this.hasUnsavedData = false;
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_PAGE'));
              this.router.navigate(['/pages-management/'], {replaceUrl: true});
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  ngOnDestroy() {
    this.formChangeSubscription?.unsubscribe();
  }
}
