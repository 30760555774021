import { Component, OnInit } from '@angular/core';
import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";
import { ToastService } from "../services/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-user-profile-toolbar',
  templateUrl: './user-profile-toolbar.component.html',
  styleUrls: ['./user-profile-toolbar.component.scss']
})
export class UserProfileToolbarComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private route: Router,
    private toast: ToastService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  logOut() {
    // we do this to enable logging out user even if he lost internet connection
    localStorage.removeItem('currently_logged_user');

    // try to logout on server
    this.auth.logout().subscribe({
      next: (resp: any) => {
        // logged out on server side too
      },
      error: (error) => {
        // logged out on user's side but can't on server
      }
    });

    // change the state and show the login page
    this.auth.isAuthenticated.next(false);
    this.toast.show(this.translate.instant('SUCCESS_MESSAGES.LOGGED_OUT'));
    this.route.navigate(['/login']);
  }

}
