<div class="crud-page">
  <div class="page-header">
    <a routerLink="/report-status-management" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{'PAGES.REPORT_STATUS_MANAGEMENT.BACK' | translate}}</a>
    <h1>{{'PAGES.REPORT_STATUS_UPDATE.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="updateReportStatus()" [disabled]="!form.valid">
      {{'BUTTONS.EDIT_REPORT_STATUS' | translate}}
    </button>
  </div>
  <div class="page-content">
    <form [formGroup]="form" class="vertical-form">
      <mat-form-field appearance="fill">
        <mat-label>{{'PAGES.REPORT_STATUS_MANAGEMENT.NAME' | translate}}</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="checkError('name', 'required')">{{'PAGES.REPORT_STATUS_MANAGEMENT.ERRORS.REQUIRED_REPORT_STATUS_NAME' | translate}}</mat-error>
      </mat-form-field>
    </form>
  </div>
</div>
