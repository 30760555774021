import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-report-ticket-image-actions',
  templateUrl: './report-ticket-image-actions.component.html',
  styleUrls: ['./report-ticket-image-actions.component.scss']
})
export class ReportTicketImageActionsComponent implements OnInit {

  @Input() image: string = '';
  @Input() thumb: boolean = false;

  @Output() deleteImageEvent: EventEmitter<any> = new EventEmitter();
  @Output() replaceImageEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {;
  }


  deleteImage() {
    this.deleteImageEvent.emit(this.image);
  }

  replaceImage() {
    this.replaceImageEvent.emit(this.image);
  }

}
