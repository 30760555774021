// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://app-eco-be-backoffice-stg.azurewebsites.net/api/v1',

  googleMapsAPIKey: 'AIzaSyCU26907z9zRiZIxlUrMS6F3QY5Z_W1LDg',
  googleMapsAPILanguage: 'en',

  reportLinkPattern: 'https://staging.ecoscope-backoffice-web.pages.dev/report/XXXXXXXX'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
