import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationUsersService } from "../../../shared/services/application-users.service";

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

  private userId: any;
  public user: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersService: ApplicationUsersService
  ) { }

  ngOnInit(): void {
    // get user data
    this.userId = this.route.snapshot.paramMap.get('id');

    if (this.userId) {
      this.usersService.getUser(this.userId).subscribe({
        next: (resp: any) => {
          this.user = resp;
        },
        error: (error: any) => {}});
    }
  }

  public get seaRelations(): string {
    return this.user.seaRelations.join(', ');
  }


  editUser() {
    this.router.navigate([`users-edit/${this.userId}`]);
  }

}
