import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../shared/services/auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-login-pin',
  templateUrl: './login-pin.component.html',
  styleUrls: ['./login-pin.component.scss']
})
export class LoginPinComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.form = this.fb.group({
      pin: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  sendPIN() {

    if (this.form.valid) {

      const pin = this.form.get('pin')?.value;

      this.auth.pinCode(pin).subscribe({
        next: (resp: any) => {

          // get profile info
          this.auth.profile()
            .subscribe({
              next: (resp: any) => {
                this.toast.show(this.translate.instant('SUCCESS_MESSAGES.LOGGED_IN'));
                this.auth.saveUserToLocalStorage(resp);
                this.router.navigate(['/']);
              },
              error: (error) => {}
            })

        },
        error: (error) => {},
      });

    }
  }

}
