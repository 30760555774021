<div class="crud-page">
  <div class="page-header">
    <a routerLink="/report-status-management" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.REPORT_STATUS_MANAGEMENT.BACK' | translate}}</a>
    <h1>{{'PAGES.REPORT_STATUS_VIEW.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="editReportStatus()">{{'BUTTONS.EDIT_REPORT_STATUS' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container">
      <div class="items">
        <span class="header">{{'PAGES.REPORT_STATUS_MANAGEMENT.ID' | translate}}</span>
        <span class="value">{{reportStatus?.id}}</span>
      </div>

      <div class="items">
        <span class="header">{{'PAGES.REPORT_STATUS_MANAGEMENT.NAME' | translate}}</span>
        <span class="value">{{reportStatus?.name}}</span>
      </div>

    </div>
  </div>
</div>
