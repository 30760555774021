import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriesService } from "../../../shared/services/categories.service";
import lgZoom from 'lightgallery/plugins/zoom';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent implements OnInit {

  private categoryId: any;
  public category: any;

  public gallerySettings = {
    counter: false,
    plugins: [lgZoom]
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private categoriesService: CategoriesService
  ) { }

  ngOnInit(): void {
    // get category data
    this.categoryId = this.route.snapshot.paramMap.get('id');

    if (this.categoryId) {
      this.categoriesService.getCategory(this.categoryId).subscribe({
        next: (resp: any) => {
          this.category = resp;
        },
        error: (error: any) => {}});
    }
  }

  editReportCategory() {
    this.router.navigate([`categories-edit/${this.categoryId}`]);
  }

}
