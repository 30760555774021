import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { ToastService } from '../../shared/services/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StatesService } from '../../shared/services/states.service';
import { ReportsService } from '../../shared/services/reports.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppConstants } from '../../shared/constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfirmComponent, ConfirmModel } from '../modal-dialogs/confirm/confirm.component';
import { MatSort, MatSortable } from '@angular/material/sort';
import { forkJoin, ReplaySubject, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CustomCalendarHeaderComponent } from '../../shared/custom-calendar-header/custom-calendar-header.component';
import moment from 'moment';
import { ContentSeas } from '../../shared/enums/contentSeas.enum';
import { CategoryTypes } from '../../shared/enums/categoryTypes.enum';
import { ReportStatusService } from '../../shared/services/report-status.service';
import { CategoriesService } from '../../shared/services/categories.service';
import { ApplicationUsersService } from '../../shared/services/application-users.service';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/services/auth/auth.service';
import { BackofficeUserRoles } from '../../shared/enums/roles.enum';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-reports-management',
  templateUrl: './reports-management.component.html',
  styleUrls: ['./reports-management.component.scss'],
})
export class ReportsManagementComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort;

  readonly CustomCalendarHeaderComponent = CustomCalendarHeaderComponent;

  center: google.maps.LatLngLiteral = {
    lat: AppConstants.googleMap.initialCenter.lat,
    lng: AppConstants.googleMap.initialCenter.lng,
  };
  zoom = AppConstants.googleMap.initialZoom;

  map: google.maps.Map | undefined;
  infoWindow: google.maps.InfoWindow | undefined;

  icons: Record<string, { icon: string }> = {
    red: {
      icon: 'assets/icons/pins/red.png',
    },
    orange: {
      icon: 'assets/icons/pins/orange.png',
    },
    green: {
      icon: 'assets/icons/pins/green.png',
    },
  };

  markers: google.maps.Marker[] = [];

  currentViewType: number = 0;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private dialog: MatDialog,
    private reportsService: ReportsService,
    private router: Router,
    public translate: TranslateService,
    private statesService: StatesService,
    private reportStatusesService: ReportStatusService,
    private categoriesService: CategoriesService,
    private usersService: ApplicationUsersService,
    private auth: AuthService
  ) {}

  public reports = new MatTableDataSource([]);
  public displayedColumns: string[] = [
    'actions',
    'id',
    'categoryName',
    'reportType',
    'reportStatusName',
    'location',
    'reportDate',
    'userFullName',
    'updatedDate',
    'backofficeUser',
    'note',
  ];

  public allReports: any = [];

  public filters: any = {
    orderBy: 'id',
    sortingDirection: 'desc',
  };

  public datesRanges: any = {};

  public categories: any = []; // all categories from the response
  public categoryMultiCtrl: FormControl = new FormControl(); // control for the selected categories
  public categoryFilterCtrl: FormControl = new FormControl(); // control for the filter keyword
  public filteredCategories: ReplaySubject<any[]> = new ReplaySubject<any[]>(1); // filtered categories
  protected filteredCategoriesCache: any[] = []; // help to work with search & select
  public isAllCategoriesIndeterminate = true;
  public isAllCategoriesChecked = false;

  public statuses: any = [];
  public statusMultiCtrl: FormControl = new FormControl();
  public statusFilterCtrl: FormControl = new FormControl();
  public filteredStatuses: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected filteredStatusesCache: any[] = [];
  public isAllStatusesIndeterminate = true;
  public isAllStatusesChecked = false;
  public disabledStatusNames = AppConstants.disabledStatusNames;

  public users: any = [];
  public userMultiCtrl: FormControl = new FormControl();
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected filteredUsersCache: any[] = [];
  public isAllUsersIndeterminate = true;
  public isAllUsersChecked = false;

  public areaSeas = ContentSeas;
  public categoryTypes = CategoryTypes;

  private componentIsDestroyed = new Subject<boolean>();
  private sortingSubscription: Subscription | undefined;

  ngOnInit(): void {
    this.datesRanges = {
      [this.translate.instant('PAGES.REPORTS.TODAY')]: [moment(), moment()],
      [this.translate.instant('PAGES.REPORTS.YESTERDAY')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      [this.translate.instant('PAGES.REPORTS.LAST7DAYS')]: [moment().subtract(6, 'days'), moment()],
      // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      [this.translate.instant('PAGES.REPORTS.THIS_MONTH')]: [moment().startOf('month'), moment().endOf('month')],
      [this.translate.instant('PAGES.REPORTS.LAST_MONTH')]: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };

    let requests = [
      this.usersService.getReportUsers(),
      this.categoriesService.getCategories(),
      this.reportStatusesService.getReportStatuses(),
    ];

    forkJoin(requests).subscribe({
      next: (responses: any) => {
        this.users = responses[0]
          .filter((user: { isDeleted: boolean }) => !user.isDeleted)
          .map((user: any) => ({ ...user, fullName: `${user.name ?? ''}` }));
        this.categories = responses[1];
        this.statuses = responses[2];

        // Categories
        // set initial selection for categories
        if (!this.statesService.reportTicketsFilters.categoriesIds) {
          this.categoryMultiCtrl.setValue([]);
        }

        // load the initial categories list
        this.filteredCategories.next(this.categories.slice());

        // listen for search field value changes
        this.categoryFilterCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.filterCategories();
          this.setToggleAllCategoriesCheckboxState();
        });

        // listen for multi select field value changes
        this.categoryMultiCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.setToggleAllCategoriesCheckboxState();
        });

        this.categoryFilterCtrl.patchValue('');

        if (this.statesService.reportTicketsFilters.categoriesIds) {
          this.categoryMultiCtrl.setValue([this.statesService.reportTicketsFilters.categoriesIds]);
          this.setToggleAllCategoriesCheckboxState();
        }

        // Statuses
        // set initial selection for statuses
        if (!this.statesService.reportTicketsFilters.reportStatusIds) {
          this.statusMultiCtrl.setValue([]);
        }

        // load the initial statuses list
        this.filteredStatuses.next(this.statuses.slice());

        // listen for search field value changes
        this.statusFilterCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.filterStatuses();
          this.setToggleAllStatusesCheckboxState();
        });

        // listen for multi select field value changes
        this.statusMultiCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.setToggleAllStatusesCheckboxState();
        });

        this.statusFilterCtrl.patchValue('');

        if (this.statesService.reportTicketsFilters.reportStatusIds) {
          this.statusMultiCtrl.setValue([this.statesService.reportTicketsFilters.reportStatusIds]);
          this.setToggleAllStatusesCheckboxState();
        }

        // Users
        // set initial selection for users
        if (!this.statesService.reportTicketsFilters.usersIds) {
          this.userMultiCtrl.setValue([]);
        }

        // load the initial users list
        this.filteredUsers.next(this.users.slice());

        // listen for search field value changes
        this.userFilterCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.filterUsers();
          this.setToggleAllUsersCheckboxState();
        });

        // listen for multi select field value changes
        this.userMultiCtrl.valueChanges.pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
          this.setToggleAllUsersCheckboxState();
        });

        this.userFilterCtrl.patchValue('');

        if (this.statesService.reportTicketsFilters.usersIds) {
          this.userMultiCtrl.setValue([this.statesService.reportTicketsFilters.usersIds]);
          this.setToggleAllUsersCheckboxState();
        }

        setTimeout(() => {
          if (this.statesService.reportTicketsFilters.searchTerm)
            this.filters.searchTerm = this.statesService.reportTicketsFilters.searchTerm;

          if (this.statesService.reportTicketsFilters.dateRange) this.filters.dateRange = this.statesService.reportTicketsFilters.dateRange;

          if (this.statesService.reportTicketsSorting.orderBy) this.filters.orderBy = this.statesService.reportTicketsSorting.orderBy;
          if (this.statesService.reportTicketsSorting.sortingDirection)
            this.filters.sortingDirection = this.statesService.reportTicketsSorting.sortingDirection;

          if (this.filters.orderBy && this.filters.sortingDirection)
            this.sort.sort({ id: this.filters.orderBy, start: this.filters.sortingDirection } as MatSortable);

          if (this.statesService.reportTicketsFilters.seas) this.filters.seas = this.statesService.reportTicketsFilters.seas;

          if (this.statesService.reportTicketsFilters.reportTypes)
            this.filters.reportTypes = this.statesService.reportTicketsFilters.reportTypes;

          if (this.statesService.reportTicketsFilters.categoriesIds) {
            this.categoryMultiCtrl.setValue(this.statesService.reportTicketsFilters.categoriesIds);
            this.setToggleAllCategoriesCheckboxState();
          }

          if (this.statesService.reportTicketsFilters.reportStatusIds) {
            this.statusMultiCtrl.setValue(this.statesService.reportTicketsFilters.reportStatusIds);
            this.setToggleAllStatusesCheckboxState();
          }

          if (this.statesService.reportTicketsFilters.usersIds) {
            this.userMultiCtrl.setValue(this.statesService.reportTicketsFilters.usersIds);
            this.setToggleAllUsersCheckboxState();
          }

          this.updateReportTickets();
        }, 0);
      },
      error: (error) => {},
    });
  }

  filterCategories() {
    if (!this.categories) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategoriesCache = this.categories.slice();
      this.filteredCategories.next(this.filteredCategoriesCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the categories
    this.filteredCategoriesCache = this.categories.filter((category: { name: string }) => category.name.toLowerCase().indexOf(search) > -1);
    this.filteredCategories.next(this.filteredCategoriesCache);
  }
  toggleSelectAllCategories(selectAllValue: boolean) {
    this.filteredCategories.pipe(take(1), takeUntil(this.componentIsDestroyed)).subscribe((val) => {
      if (selectAllValue) {
        let alreadySelected = this.categoryMultiCtrl.value;
        val.forEach((category) => {
          if (!alreadySelected.includes(category.id)) alreadySelected.push(category.id);
        });
        this.categoryMultiCtrl.patchValue(alreadySelected);
      } else {
        this.categoryMultiCtrl.patchValue([]);
      }
    });
  }
  protected setToggleAllCategoriesCheckboxState() {
    let filteredLength = 0;
    if (this.categoryMultiCtrl && this.categoryMultiCtrl.value) {
      this.filteredCategoriesCache.forEach((filteredCategory) => {
        if (this.categoryMultiCtrl.value.find((selectedCategoryId: number) => selectedCategoryId === filteredCategory.id)) {
          filteredLength++;
        }
      });
      this.isAllCategoriesIndeterminate = filteredLength > 0 && filteredLength < this.filteredCategoriesCache.length;
      this.isAllCategoriesChecked = filteredLength > 0 && filteredLength === this.filteredCategoriesCache.length;
    }
  }

  filterStatuses() {
    if (!this.statuses) {
      return;
    }
    // get the search keyword
    let search = this.statusFilterCtrl.value;
    if (!search) {
      this.filteredStatusesCache = this.statuses.slice();
      this.filteredStatuses.next(this.filteredStatusesCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the statuses
    this.filteredStatusesCache = this.statuses.filter((status: { name: string }) => status.name.toLowerCase().indexOf(search) > -1);
    this.filteredStatuses.next(this.filteredStatusesCache);
  }
  toggleSelectAllStatuses(selectAllValue: boolean) {
    this.filteredStatuses.pipe(take(1), takeUntil(this.componentIsDestroyed)).subscribe((val) => {
      if (selectAllValue) {
        let alreadySelected = this.statusMultiCtrl.value;
        val.forEach((status) => {
          if (!alreadySelected.includes(status.id)) alreadySelected.push(status.id);
        });
        this.statusMultiCtrl.patchValue(alreadySelected);
      } else {
        this.statusMultiCtrl.patchValue([]);
      }
    });
  }
  protected setToggleAllStatusesCheckboxState() {
    let filteredLength = 0;
    if (this.statusMultiCtrl && this.statusMultiCtrl.value) {
      this.filteredStatusesCache.forEach((filteredStatus) => {
        if (this.statusMultiCtrl.value.find((selectedStatusId: number) => selectedStatusId === filteredStatus.id)) {
          filteredLength++;
        }
      });
      this.isAllStatusesIndeterminate = filteredLength > 0 && filteredLength < this.filteredStatusesCache.length;
      this.isAllStatusesChecked = filteredLength > 0 && filteredLength === this.filteredStatusesCache.length;
    }
  }

  filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsersCache = this.users.slice();
      this.filteredUsers.next(this.filteredUsersCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredUsersCache = this.users.filter((user: { name: string }) => user.name?.toLowerCase().indexOf(search) > -1);
    this.filteredUsers.next(this.filteredUsersCache);
  }
  toggleSelectAllUsers(selectAllValue: boolean) {
    this.filteredUsers.pipe(take(1), takeUntil(this.componentIsDestroyed)).subscribe((val) => {
      if (selectAllValue) {
        let alreadySelected = this.userMultiCtrl.value;
        val.forEach((user) => {
          if (!alreadySelected.includes(user.id)) alreadySelected.push(user.id);
        });
        this.userMultiCtrl.patchValue(alreadySelected);
      } else {
        this.userMultiCtrl.patchValue([]);
      }
    });
  }
  protected setToggleAllUsersCheckboxState() {
    let filteredLength = 0;
    if (this.userMultiCtrl && this.userMultiCtrl.value) {
      this.filteredUsersCache.forEach((filteredUser) => {
        if (this.userMultiCtrl.value.find((selectedUserId: number) => selectedUserId === filteredUser.id)) {
          filteredLength++;
        }
      });
      this.isAllUsersIndeterminate = filteredLength > 0 && filteredLength < this.filteredUsersCache.length;
      this.isAllUsersChecked = filteredLength > 0 && filteredLength === this.filteredUsersCache.length;
    }
  }

  searchReports() {
    this.statesService.reportTicketsPaginator.pageIndex = 0;

    if (this.filters.searchTerm) {
      this.statesService.reportTicketsFilters.searchTerm = this.filters.searchTerm;
    } else {
      delete this.statesService.reportTicketsFilters.searchTerm;
    }

    if (this.filters.dateRange?.startDate && this.filters.dateRange?.endDate) {
      this.statesService.reportTicketsFilters.dateRange = this.filters.dateRange;
    } else {
      delete this.statesService.reportTicketsFilters.dateRange;
    }

    if (this.filters.seas) {
      this.statesService.reportTicketsFilters.seas = this.filters.seas;
    } else {
      delete this.statesService.reportTicketsFilters.seas;
    }

    if (this.filters.reportTypes) {
      this.statesService.reportTicketsFilters.reportTypes = this.filters.reportTypes;
    } else {
      delete this.statesService.reportTicketsFilters.reportTypes;
    }

    if (this.categoryMultiCtrl.value) {
      this.statesService.reportTicketsFilters.categoriesIds = this.categoryMultiCtrl.value;
    } else {
      delete this.statesService.reportTicketsFilters.categoriesIds;
    }

    if (this.statusMultiCtrl.value) {
      this.statesService.reportTicketsFilters.reportStatusIds = this.statusMultiCtrl.value;
    } else {
      delete this.statesService.reportTicketsFilters.reportStatusIds;
    }

    if (this.userMultiCtrl.value) {
      this.statesService.reportTicketsFilters.usersIds = this.userMultiCtrl.value;
    } else {
      delete this.statesService.reportTicketsFilters.usersIds;
    }

    if (this.currentViewType === 0) {
      // update table view data
      this.updateReportTickets();
    } else {
      // update map view data
      this.getMapData();
    }
  }

  updateReportTickets() {
    let filterData: any = { ...this.statesService.reportTicketsFilters };

    filterData.pageNumber = this.statesService.reportTicketsPaginator.pageIndex + 1;
    filterData.pageSize = this.statesService.reportTicketsPaginator.pageSize;

    if (this.statesService.reportTicketsFilters.dateRange?.startDate || this.statesService.reportTicketsFilters.dateRange?.endDate) {
      if (this.statesService.reportTicketsFilters.dateRange?.startDate)
        filterData.startDate = moment(this.statesService.reportTicketsFilters.dateRange.startDate).utc().format();
      if (this.statesService.reportTicketsFilters.dateRange?.endDate)
        filterData.endDate = moment(this.statesService.reportTicketsFilters.dateRange.endDate).utc().format();
    }

    if (this.filters.orderBy && this.filters.sortingDirection) filterData.orderBy = this.filters.orderBy;
    if (this.filters.sortingDirection) filterData.sortingDirection = this.filters.sortingDirection;

    if (this.statesService.reportTicketsFilters.seas) filterData.seas = this.statesService.reportTicketsFilters.seas;

    if (this.statesService.reportTicketsFilters.categoriesIds)
      filterData.categoriesIds = this.statesService.reportTicketsFilters.categoriesIds;

    if (this.statesService.reportTicketsFilters.reportStatusIds)
      filterData.reportStatusIds = this.statesService.reportTicketsFilters.reportStatusIds;

    if (this.statesService.reportTicketsFilters.usersIds) filterData.usersIds = this.statesService.reportTicketsFilters.usersIds;

    if (this.statesService.reportTicketsFilters.reportTypes) filterData.reportTypes = this.statesService.reportTicketsFilters.reportTypes;

    if (filterData.dateRange) delete filterData.dateRange;

    this.reportsService.getReportTickets(filterData).subscribe({
      next: (resp: any) => {
        this.reports.data = resp.result;

        this.paginator.pageIndex = this.statesService.reportTicketsPaginator.pageIndex;
        this.paginator.pageSize = this.statesService.reportTicketsPaginator.pageSize;
        this.paginator.length = resp.count;

        this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

        this.reports.sort = this.sort;

        if (!this.sortingSubscription) {
          this.sortingSubscription = this.reports.sort.sortChange.pipe(takeUntil(this.componentIsDestroyed)).subscribe((sort) => {
            this.filters.orderBy = sort.active;
            this.filters.sortingDirection = sort.direction;

            this.statesService.reportTicketsSorting = { orderBy: sort.active, sortingDirection: sort.direction };
            this.updateReportTickets();
          });
        }
      },
      error: (error) => {},
    });
  }

  getMapData() {
    this.infoWindow?.close();
    let filterData: any = { ...this.statesService.reportTicketsFilters };

    if (this.statesService.reportTicketsFilters.dateRange?.startDate || this.statesService.reportTicketsFilters.dateRange?.endDate) {
      if (this.statesService.reportTicketsFilters.dateRange?.startDate)
        filterData.startDate = moment(this.statesService.reportTicketsFilters.dateRange.startDate).utc().format();
      if (this.statesService.reportTicketsFilters.dateRange?.endDate)
        filterData.endDate = moment(this.statesService.reportTicketsFilters.dateRange.endDate).utc().format();
    }

    if (this.statesService.reportTicketsFilters.seas) filterData.seas = this.statesService.reportTicketsFilters.seas;

    if (this.statesService.reportTicketsFilters.categoriesIds)
      filterData.categoriesIds = this.statesService.reportTicketsFilters.categoriesIds;

    if (this.statesService.reportTicketsFilters.reportStatusIds)
      filterData.statusIds = this.statesService.reportTicketsFilters.reportStatusIds;

    if (this.statesService.reportTicketsFilters.usersIds) filterData.usersIds = this.statesService.reportTicketsFilters.usersIds;

    if (this.statesService.reportTicketsFilters.reportTypes) filterData.reportTypes = this.statesService.reportTicketsFilters.reportTypes;

    if (filterData.dateRange) delete filterData.dateRange;

    this.reportsService.getMapReportTickets(filterData).subscribe({
      next: (resp: any) => {
        this.allReports = resp;

        this.markers.forEach((marker) => {
          marker.setMap(null);
        });

        // draw all pins
        this.allReports.forEach((report: any) => {
          if (report.longitude && report.latitude) {
            const type = report.reportStatusId > 1 ? (report.reportStatusId > 20 ? 'green' : 'orange') : 'red';
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(report.latitude, report.longitude),
              icon: this.icons[type].icon,
              map: this.map,
            });
            marker.addListener('click', (ev: any) => {
              this.showInfoWindow(report, ev);
            });
            this.markers.push(marker);
          }
        });
      },
      error: (error) => {},
    });
  }

  showInfoWindow(report: any, event: any) {
    this.infoWindow?.close();

    this.infoWindow = new google.maps.InfoWindow({
      content:
        `<h2>${report.location}</h2>` +
        (report.categories?.[0]
          ? `<p><b>${this.translate.instant('PAGES.REPORTS.CATEGORY')}:</b><br/>` +
            (report.categories.map((category: any) => '↳ ' + category.name).join(`<br/>`) + `<br/>`)
          : ``) +
        (report.userFullName ? `<br/>${this.translate.instant('PAGES.REPORTS.REPORTER_NAME')}: <b>${report.userFullName}</b>` : ``) +
        (report.userPhoneNumber ? `<br>${this.translate.instant('PAGES.REPORTS.REPORTER_PHONE')}: <b>${report.userPhoneNumber}</b>` : ``) +
        (report.userEmail ? `<br>${this.translate.instant('PAGES.REPORTS.REPORTER_EMAIL')}: <b>${report.userEmail}</b>` : ``) +
        `<br/><br/>${this.translate.instant('PAGES.REPORTS.TYPE')}: <b>${report.reportType}</b><br/>` +
        (report.backOfficeUserFullName
          ? `<br>${this.translate.instant('PAGES.REPORTS.BACKOFFICE_USER_NAME')}: <b>${report.backOfficeUserFullName}</b>`
          : ``) +
        `<br/><br/><a href="/report-view/${report.id}" target="_blank" class="link">${this.translate.instant(
          'PAGES.REPORTS.MORE_DETAILS'
        )}</a>`,
    });

    this.infoWindow.open({
      map: this.map,
    });

    this.infoWindow.setPosition(event.latLng.toJSON());
  }

  changeReportStatus(report: any, ev: any) {
    const statusId = ev.value;

    const data = {
      reportStatusId: statusId,
      categories: report.categories,
    };

    this.reportsService.updateReport(report.id, data).subscribe((resp: any) => {
      this.updateReportTickets();
      this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_INSPECTOR_REPORT_STATUS'));
    });
  }

  get isAdmin(): boolean {
    return this.auth.userProfile.getValue().roleId === BackofficeUserRoles.ADMIN;
  }

  changePageBE(event: PageEvent) {
    this.statesService.reportTicketsPaginator.pageIndex = event.pageIndex;
    this.statesService.reportTicketsPaginator.pageSize = event.pageSize;

    this.updateReportTickets();
  }

  resetFilters() {
    this.statesService.reportTicketsFilters = {};
    this.statesService.reportTicketsSorting = { orderBy: '', sortingDirection: '' };

    this.filters.searchTerm = '';

    delete this.filters.dateRange;

    delete this.filters.seas;
    delete this.filters.reportTypes;

    this.categoryMultiCtrl.setValue([]);
    this.statusMultiCtrl.setValue([]);
    this.userMultiCtrl.setValue([]);

    delete this.statesService.reportTicketsFilters.seas;
    delete this.statesService.reportTicketsFilters.reportTypes;
    delete this.statesService.reportTicketsFilters.categoriesIds;
    delete this.statesService.reportTicketsFilters.reportStatusIds;
    delete this.statesService.reportTicketsFilters.usersIds;

    this.statesService.reportTicketsPaginator.pageIndex = 0;

    if (this.currentViewType === 0) {
      // update table view data
      this.updateReportTickets();
    } else {
      // update map view data
      this.getMapData();
    }
  }

  exportToExcel() {
    this.reportsService.exportToExcel(this.filters);
  }

  viewChanged(event: any) {
    this.currentViewType = event.index;

    if (event.index === 1) {
      if (!this.map) {
        // load Google Map
        let loader = new Loader({
          apiKey: environment.googleMapsAPIKey,
          language: environment.googleMapsAPILanguage,
        });

        loader.load().then(() => {
          this.map = new google.maps.Map(document.getElementById('google-map') as HTMLElement, {
            center: this.center,
            zoom: this.zoom,
            styles: AppConstants.googleMap.styles,
          });

          // get data
          this.getMapData();
        });
      } else {
        // just get the data
        this.getMapData();
      }
    } else {
      this.updateReportTickets();
    }
  }

  confirmRemoveReport(event: Event, report: any) {
    event.stopPropagation();

    const dialogData = new ConfirmModel(
      this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_REPORT')
    );
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: '400px',
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        // remove report
        this.reportsService.removeReport(report.id).subscribe({
          next: (resp: any) => {
            this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_REPORT'));
            this.updateReportTickets();
          },
          error: (error: any) => {},
        });
      } else {
        // not confirmed - do nothing
      }
    });
  }

  ngOnDestroy() {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();

    this.sortingSubscription?.unsubscribe();
  }
}
