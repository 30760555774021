<div class="page-header">
  <h1>{{'PAGES.REPORT_STATUS_MANAGEMENT.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddReportStatusPage()">{{'BUTTONS.ADD_REPORT_STATUS' | translate}}</button>
</div>
<div class="page-content">
  <div class="data-table">

    <div class="statuses-disclaimer">
      {{'PAGES.REPORT_STATUS_MANAGEMENT.DISCLAIMER_LINE_1' | translate}}<br />
      {{'PAGES.REPORT_STATUS_MANAGEMENT.DISCLAIMER_LINE_2' | translate}}<br />
      {{'PAGES.REPORT_STATUS_MANAGEMENT.DISCLAIMER_LINE_3' | translate}}<br />
      {{'PAGES.REPORT_STATUS_MANAGEMENT.DISCLAIMER_LINE_4' | translate}}
    </div>

    <table mat-table [dataSource]="reportStatuses" multiTemplateDataRows matSortActive="name" matSortDirection="asc" class="mat-elevation-z8 data" matSort>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">
          {{'COMMON.ACTIONS' | translate}}
        </th>
        <td mat-cell *matCellDef="let status" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/report-status-edit/{{status.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemoveReportStatus($event, status)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-50">{{'PAGES.REPORT_STATUS_MANAGEMENT.ID' | translate}}</th>
        <td mat-cell *matCellDef="let status" class="column-50">
          <a class="link" routerLink="/report-status-view/{{status.id}}"> {{status.id}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.REPORT_STATUS_MANAGEMENT.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let status"> {{status.name}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let content; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>


  </div>
</div>
