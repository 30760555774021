import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../../shared/services/api.service";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { BackofficeUsersService } from "../../../shared/services/backoffice-users.service";
import { ActivatedRoute, Router } from "@angular/router";
import { conditionalValidator } from "../../../shared/helpers/conditional.validator";
import { BackofficeUserRoles } from "../../../shared/enums/roles.enum";
import { ReserveModel } from "../../../shared/models/reserve.model";
import { forkJoin, ReplaySubject, Subject } from "rxjs";
import { ReservesManagementService } from "../../../shared/services/reserves-management.service";
import { CategoriesService } from "../../../shared/services/categories.service";
import { ReportCategoryModel } from "../../../shared/models/report-category.model";
import { Patterns } from "../../../shared/patterns";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-edit-backoffice-user',
  templateUrl: './edit-backoffice-user.component.html',
  styleUrls: ['./edit-backoffice-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditBackofficeUserComponent implements OnInit {

  public form: FormGroup;
  public userRoles = BackofficeUserRoles;
  private userId: any;

  public reserves: ReserveModel[] = [];
  public reserveMultiCtrl: FormControl = new FormControl(); // control for the selected reserves
  public reserveFilterCtrl: FormControl = new FormControl(); // control for the filter keyword
  public filteredReserves: ReplaySubject<any[]> = new ReplaySubject<any[]>(1); // filtered reserves
  protected filteredReservesCache: any[] = []; // help to work with search & select
  public isAllReservesIndeterminate = true;
  public isAllReservesChecked = false;

  public categories: ReportCategoryModel[] = [];
  public categoryMultiCtrl: FormControl = new FormControl(); // control for the selected categories
  public categoryFilterCtrl: FormControl = new FormControl(); // control for the filter keyword
  public filteredCategories: ReplaySubject<any[]> = new ReplaySubject<any[]>(1); // filtered categories
  protected filteredCategoriesCache: any[] = []; // help to work with search & select
  public isAllCategoriesIndeterminate = true;
  public isAllCategoriesChecked = false;

  public receiveEmail: boolean = false;
  public receiveSMS: boolean = false;
  public receiveWhatsApp: boolean = false;

  private componentIsDestroyed = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    public translate: TranslateService,
    private backofficeUsersService: BackofficeUsersService,
    private reservesService: ReservesManagementService,
    private categoriesService: CategoriesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      fullName: ['', [
        Validators.required,
        Validators.pattern(Patterns.name)
      ]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['',
        [Validators.pattern(Patterns.mobile),
        conditionalValidator(
          () => this.form.get('role')?.value === this.userRoles.INSPECTOR,
          Validators.required,
          'conditionalMobile'
      )]],
      role: [[], Validators.required]
    });
  }

  ngOnDestroy() {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.complete();
  }

  ngOnInit(): void {
    // get category data
    this.userId = this.route.snapshot.paramMap.get('id');

    if (this.userId) {
      this.backofficeUsersService.getBackofficeUser(this.userId).subscribe({
        next: (resp: any) => {

          this.receiveEmail = resp.recieveEmail;
          this.receiveSMS = resp.recieveSMS;
          this.receiveWhatsApp = resp.recieveWhatsApp;

          this.form.get('fullName')?.patchValue(resp.fullName);
          this.form.get('role')?.patchValue(resp.roleId);
          this.form.get('mobile')?.setValue(resp.mobile);
          this.form.get('email')?.patchValue(resp.email);

          // get reserves and categories
          let requests = [
            this.categoriesService.getCategories(),
            this.reservesService.getReserves()
          ];

          forkJoin(requests).subscribe({
            next: (responses: any) => {
              // responses[0] - all categories
              const categories = responses[0].sort((
                a: { name: { toLowerCase: () => string; }; },
                b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

              this.categories = categories;
              this.form.get('categories')?.patchValue(resp.categoriesIds);

              // set initial selection for categories
              if (!resp.categoriesIds) {
                this.categoryMultiCtrl.setValue([]);
              }

              // load the initial categories list
              this.filteredCategories.next(this.categories.slice());

              // listen for search field value changes
              this.categoryFilterCtrl.valueChanges
                .pipe(takeUntil(this.componentIsDestroyed))
                .subscribe(() => {
                  this.filterCategories();
                  this.setToggleAllCategoriesCheckboxState();
                });

              // listen for multi select field value changes
              this.categoryMultiCtrl.valueChanges
                .pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
                this.setToggleAllCategoriesCheckboxState();
              });

              this.categoryFilterCtrl.patchValue('');

              if (resp.categoriesIds) {
                this.categoryMultiCtrl.setValue([resp.categoriesIds]);
                this.setToggleAllCategoriesCheckboxState();
              }

              // responses[1] - all reserves
              const reserves = responses[1].sort((
                a: { name: { toLowerCase: () => string; }; },
                b: { name: { toLowerCase: () => string; }; }) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

              this.reserves = reserves;
              this.form.get('reserves')?.patchValue(resp.polygonsIds);

              // set initial selection for reserves
              if (!resp.polygonsIds) {
                this.reserveMultiCtrl.setValue([]);
              }

              // load the initial reserves list
              this.filteredReserves.next(this.reserves.slice());

              // listen for search field value changes
              this.reserveFilterCtrl.valueChanges
                .pipe(takeUntil(this.componentIsDestroyed))
                .subscribe(() => {
                  this.filterReserves();
                  this.setToggleAllReservesCheckboxState();
                });

              // listen for multi select field value changes
              this.reserveMultiCtrl.valueChanges
                .pipe(takeUntil(this.componentIsDestroyed)).subscribe(() => {
                this.setToggleAllReservesCheckboxState();
              });

              this.reserveFilterCtrl.patchValue('');

              if (resp.polygonsIds) {
                this.reserveMultiCtrl.setValue([resp.polygonsIds]);
                this.setToggleAllReservesCheckboxState();
              }

              setTimeout(() => {

                if (resp.categoriesIds) {
                  this.categoryMultiCtrl.setValue(resp.categoriesIds);
                  this.setToggleAllCategoriesCheckboxState();
                }

                if (resp.polygonsIds) {
                  this.reserveMultiCtrl.setValue(resp.polygonsIds);
                  this.setToggleAllReservesCheckboxState();
                }

              }, 0);

            },
            error: (error) => {}
          });
        },
        error: (error: any) => {
        }
      });
    }
  }

  filterCategories() {
    if (!this.categories) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategoriesCache = this.categories.slice();
      this.filteredCategories.next(this.filteredCategoriesCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the categories
    this.filteredCategoriesCache = this.categories.filter((category: { name: string; }) => category.name.toLowerCase().indexOf(search) > -1);
    this.filteredCategories.next(this.filteredCategoriesCache);
  }
  toggleSelectAllCategories(selectAllValue: boolean) {
    this.filteredCategories.pipe(take(1), takeUntil(this.componentIsDestroyed))
      .subscribe(val => {
        if (selectAllValue) {
          let alreadySelected = this.categoryMultiCtrl.value;
          val.forEach(category => {
            if (!alreadySelected.includes(category.id)) alreadySelected.push(category.id);
          })
          this.categoryMultiCtrl.patchValue(alreadySelected);
        } else {
          this.categoryMultiCtrl.patchValue([]);
        }
      });
  }
  protected setToggleAllCategoriesCheckboxState() {
    let filteredLength = 0;
    if (this.categoryMultiCtrl && this.categoryMultiCtrl.value) {
      this.filteredCategoriesCache.forEach(filteredCategory => {
        if (this.categoryMultiCtrl.value.find((selectedCategoryId: number) => selectedCategoryId === filteredCategory.id)) {
          filteredLength++;
        }
      });
      this.isAllCategoriesIndeterminate = filteredLength > 0 && filteredLength < this.filteredCategoriesCache.length;
      this.isAllCategoriesChecked = filteredLength > 0 && filteredLength === this.filteredCategoriesCache.length;
    }
  }

  filterReserves() {
    if (!this.reserves) {
      return;
    }
    // get the search keyword
    let search = this.reserveFilterCtrl.value;
    if (!search) {
      this.filteredReservesCache = this.reserves.slice();
      this.filteredReserves.next(this.filteredReservesCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the reserves
    this.filteredReservesCache = this.reserves.filter((reserve: { name: string; }) => reserve.name.toLowerCase().indexOf(search) > -1);
    this.filteredReserves.next(this.filteredReservesCache);
  }
  toggleSelectAllReserves(selectAllValue: boolean) {
    this.filteredReserves.pipe(take(1), takeUntil(this.componentIsDestroyed))
      .subscribe(val => {
        if (selectAllValue) {
          let alreadySelected = this.reserveMultiCtrl.value;
          val.forEach(reserve => {
            if (!alreadySelected.includes(reserve.id)) alreadySelected.push(reserve.id);
          })
          this.reserveMultiCtrl.patchValue(alreadySelected);
        } else {
          this.reserveMultiCtrl.patchValue([]);
        }
      });
  }
  protected setToggleAllReservesCheckboxState() {
    let filteredLength = 0;
    if (this.reserveMultiCtrl && this.reserveMultiCtrl.value) {
      this.filteredReservesCache.forEach(filteredReserve => {
        if (this.reserveMultiCtrl.value.find((selectedReserveId: number) => selectedReserveId === filteredReserve.id)) {
          filteredLength++;
        }
      });
      this.isAllReservesIndeterminate = filteredLength > 0 && filteredLength < this.filteredReservesCache.length;
      this.isAllReservesChecked = filteredLength > 0 && filteredLength === this.filteredReservesCache.length;
    }
  }

  inspectorCheck() {
    return this.form.value.role === BackofficeUserRoles.INSPECTOR ?
      !(this.categoryMultiCtrl?.value?.length === 0 || this.reserveMultiCtrl?.value?.length === 0) : true;
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  updateBackofficeUser() {
    if (this.form.valid) {

      const formData = this.form.value;

      let data: any = {
        id: this.userId,
        fullName: formData.fullName,
        roleId: formData.role,
        email: formData.email,
        categoriesIds: [],
        polygonsIds: []
      };

      if (formData.mobile) {
        data.mobile = formData.mobile;
      }

      if (formData.role === BackofficeUserRoles.INSPECTOR) {
        data.categoriesIds = this.categoryMultiCtrl.value ? this.categoryMultiCtrl.value : [];
        data.polygonsIds = this.reserveMultiCtrl.value ? this.reserveMultiCtrl.value : [];

        data.recieveEmail = this.receiveEmail;
        data.recieveSMS = this.receiveSMS;
        data.recieveWhatsApp = this.receiveWhatsApp;
      }

      this.backofficeUsersService.updateBackofficeUser(data).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_USER'));
          this.router.navigate(['/backoffice-users']);
        },
        error: (error) => {},
      });

    }

  }
}
