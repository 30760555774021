<div class="page-header">
  <h1>{{'PAGES.RESERVES_MANAGEMENT.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddReserve()">{{'BUTTONS.ADD_RESERVE' | translate}}</button>
</div>
<div class="page-content top-breadcrumbs">
  <div class="data-table">

    <table mat-table [dataSource]="reserves" multiTemplateDataRows matSortActive="name" matSortDirection="asc" class="mat-elevation-z8 data" matSort>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">{{'COMMON.ACTIONS' | translate}}</th>
        <td mat-cell *matCellDef="let reserve" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/reserve-edit/{{reserve.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemoveReserve($event, reserve)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-100">{{'PAGES.RESERVES_MANAGEMENT.ID' | translate}}</th>
        <td mat-cell *matCellDef="let reserve" class="column-100">
          <a class="link" routerLink="/reserve-view/{{reserve.id}}"> {{reserve.id}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-200">{{'PAGES.RESERVES_MANAGEMENT.NAME' | translate}}</th>
        <td mat-cell *matCellDef="let reserve" class="column-200">
          {{reserve.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.RESERVES_MANAGEMENT.TYPE' | translate}}</th>
        <td mat-cell *matCellDef="let reserve"> {{reserve.type}} </td>
      </ng-container>

      <ng-container matColumnDef="parentId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-100">{{'PAGES.RESERVES_MANAGEMENT.PARENT_ID' | translate}}</th>
        <td mat-cell *matCellDef="let reserve" class="column-100">
          <a class="link" routerLink="/reserve-view/{{reserve.parentId}}" *ngIf="reserve.parentId"> {{reserve.parentId}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.RESERVES_MANAGEMENT.PARENT_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let reserve"> {{reserve.parentName}} </td>
      </ng-container>

      <ng-container matColumnDef="isVisible">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-100">{{'PAGES.RESERVES_MANAGEMENT.IS_VISIBLE' | translate}}</th>
        <td mat-cell *matCellDef="let reserve" class="column-100">
          {{(reserve?.isVisible ? 'COMMON.YES' : 'COMMON.NO') | translate}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let reserve; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>

  </div>
</div>
