import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth-guard';
import { LoginComponent } from './components/login/login.component';
import { LoginPinComponent } from "./components/login-pin/login-pin.component";
import { Page404Component } from "./components/page404/page404.component";
import { SetupPasswordComponent } from "./components/setup-password/setup-password.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";

import { BackofficeUsersComponent } from "./components/backoffice-users/backoffice-users.component";
import { AddBackofficeUserComponent } from "./components/backoffice-users/add-backoffice-user/add-backoffice-user.component";
import { ViewBackofficeUserComponent } from "./components/backoffice-users/view-backoffice-user/view-backoffice-user.component";
import { EditBackofficeUserComponent } from "./components/backoffice-users/edit-backoffice-user/edit-backoffice-user.component";

import { UsersComponent } from "./components/users/users.component";
import { AddUserComponent } from "./components/users/add-user/add-user.component";
import { ViewUserComponent } from "./components/users/view-user/view-user.component";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";

import { ReportCategoriesComponent } from "./components/report-categories/report-categories.component";
import { AddCategoryComponent } from "./components/report-categories/add-category/add-category.component";
import { EditCategoryComponent } from "./components/report-categories/edit-category/edit-category.component";
import { ViewCategoryComponent } from "./components/report-categories/view-category/view-category.component";

import { TranslationResolver } from "./shared/resolvers/translation.resolver";
import { CanDeactivateGuard } from "./shared/guards/can-deactivate.guard";

import { ContentComponent } from "./components/content/content.component";
import { AddContentComponent } from "./components/content/add-content/add-content.component";
import { EditContentComponent } from "./components/content/edit-content/edit-content.component";
import { ViewContentComponent } from "./components/content/view-content/view-content.component";

import { ReportStatusManagementComponent } from "./components/report-status-management/report-status-management.component";
import { AddReportStatusComponent } from "./components/report-status-management/add-report-status/add-report-status.component";
import { EditReportStatusComponent } from "./components/report-status-management/edit-report-status/edit-report-status.component";
import { ViewReportStatusComponent } from "./components/report-status-management/view-report-status/view-report-status.component";

import { PagesManagementComponent } from "./components/pages-management/pages-management.component";
import { AddPageComponent } from "./components/pages-management/add-page/add-page.component";
import { EditPageComponent } from "./components/pages-management/edit-page/edit-page.component";
import { ViewPageComponent } from "./components/pages-management/view-page/view-page.component";

import { ReservesManagementComponent } from "./components/reserves-management/reserves-management.component";
import { AddReserveComponent } from "./components/reserves-management/add-reserve/add-reserve.component";
import { EditReserveComponent } from "./components/reserves-management/edit-reserve/edit-reserve.component";
import { ViewReserveComponent } from "./components/reserves-management/view-reserve/view-reserve.component";

import { MessagesManagementComponent } from "./components/messages-management/messages-management.component";
import { HomePageContactsComponent } from "./components/home-page-contacts/home-page-contacts.component";

import { InspectorReportComponent } from "./components/inspector-report/inspector-report.component";
import { ReportsManagementComponent } from "./components/reports-management/reports-management.component";
import { EditReportComponent } from "./components/reports-management/edit-report/edit-report.component";
import { ViewReportComponent } from "./components/reports-management/view-report/view-report.component";


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'reports-tickets' },

  { path: '', resolve : { model: TranslationResolver }, children: [

      { path: 'backoffice-users', component: BackofficeUsersComponent, canActivate: [AuthGuard]},
      { path: 'backoffice-users-add', component: AddBackofficeUserComponent, canActivate: [AuthGuard]},
      { path: 'backoffice-users-edit/:id', component: EditBackofficeUserComponent, canActivate: [AuthGuard]},
      { path: 'backoffice-users-view/:id', component: ViewBackofficeUserComponent, canActivate: [AuthGuard]},

      { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
      { path: 'users-add', component: AddUserComponent, canActivate: [AuthGuard]},
      { path: 'users-edit/:id', component: EditUserComponent, canActivate: [AuthGuard]},
      { path: 'users-view/:id', component: ViewUserComponent, canActivate: [AuthGuard]},

      { path: 'content-management', component: ContentComponent, canActivate: [AuthGuard] },
      { path: 'content-add', component: AddContentComponent, canActivate: [AuthGuard]},
      { path: 'content-edit/:id', component: EditContentComponent, canActivate: [AuthGuard]},
      { path: 'content-view/:id', component: ViewContentComponent, canActivate: [AuthGuard]},

      { path: 'pages-management', component: PagesManagementComponent, canActivate: [AuthGuard] },
      { path: 'page-add', component: AddPageComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      { path: 'page-edit/:id', component: EditPageComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      { path: 'page-view/:id', component: ViewPageComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},

      { path: 'reserves-management', component: ReservesManagementComponent, canActivate: [AuthGuard]},
      { path: 'reserve-add', component: AddReserveComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      { path: 'reserve-edit/:id', component: EditReserveComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
      { path: 'reserve-view/:id', component: ViewReserveComponent, canActivate: [AuthGuard]},

      { path: 'report-status-management', component: ReportStatusManagementComponent, canActivate: [AuthGuard]},
      { path: 'report-status-add', component: AddReportStatusComponent, canActivate: [AuthGuard]},
      { path: 'report-status-edit/:id', component: EditReportStatusComponent, canActivate: [AuthGuard]},
      { path: 'report-status-view/:id', component: ViewReportStatusComponent, canActivate: [AuthGuard]},

      { path: 'reports-tickets', component: ReportsManagementComponent, canActivate: [AuthGuard]},
      { path: 'report-view/:id', component: ViewReportComponent, canActivate: [AuthGuard]},
      { path: 'report-edit/:id', component: EditReportComponent, canActivate: [AuthGuard]},

      { path: 'categories', component: ReportCategoriesComponent, canActivate: [AuthGuard]},
      { path: 'categories-add', component: AddCategoryComponent, canActivate: [AuthGuard]},
      { path: 'categories-edit/:id', component: EditCategoryComponent, canActivate: [AuthGuard]},
      { path: 'categories-view/:id', component: ViewCategoryComponent, canActivate: [AuthGuard]},

      { path: 'messages-management', component: MessagesManagementComponent, canActivate: [AuthGuard]},
      { path: 'home-page-contacts', component: HomePageContactsComponent, canActivate: [AuthGuard]},

      { path: 'login', component: LoginComponent},
      { path: 'login-pin', component: LoginPinComponent},
      { path: 'forgot-password', component: ForgotPasswordComponent},
      { path: 'reset/:id', component: ResetPasswordComponent},
      { path: 'invite/:id', component: SetupPasswordComponent},

      { path: 'report/:id', component: InspectorReportComponent},

      { path: 'page404', component: Page404Component},
      { path: '**', redirectTo: 'page404' }

    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
