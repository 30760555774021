<div class="crud-page">
  <div class="page-header">
    <a routerLink="/backoffice-users" class="link back-button">
      <mat-icon>arrow_left</mat-icon>
      {{'PAGES.BACKOFFICE_USERS.BACK' | translate}}</a>
    <h1>{{'PAGES.BACKOFFICE_USERS_VIEW.TITLE' | translate}}</h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="editBackofficeUser()">{{'BUTTONS.EDIT_USER' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container">
      <div class="items" *ngIf="user?.fullName">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.FULL_NAME' | translate}}</span>
        <span class="value">{{user?.fullName}}</span>
      </div>

      <div class="items" *ngIf="user?.email">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.EMAIL' | translate}}</span>
        <span class="value">{{user?.email}}</span>
      </div>

      <div class="items" *ngIf="user?.mobile">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.MOBILE' | translate}}</span>
        <span class="value">{{user?.mobile}}</span>
      </div>

      <div class="items" *ngIf="user?.roleName">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.ROLE' | translate}}</span>
        <span class="value">{{user?.roleName}}</span>
      </div>

      <div class="items" *ngIf="user?.categories && user?.roleId === backofficeUserRoles.INSPECTOR">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.CATEGORIES' | translate}}</span>
        <span class="value">{{user?.categories}}</span>
      </div>

      <div class="items" *ngIf="user?.reserves && user?.roleId === backofficeUserRoles.INSPECTOR">
        <span class="header">{{'PAGES.BACKOFFICE_USERS.RESERVES' | translate}}</span>
        <span class="value">{{user?.reserves}}</span>
      </div>


    </div>
  </div>
</div>
