import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InspectorReportService } from "../services/inspector-report.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSidebarEvent: EventEmitter<any> = new EventEmitter();
  @Input() reportHeader: boolean = false;

  constructor(
    public inspectorReportService: InspectorReportService
  ) { }

  ngOnInit(): void { }

  toggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

}
