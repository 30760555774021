<div class="page-header">
  <h1>{{'PAGES.BACKOFFICE_USERS.TITLE' | translate}}</h1>
  <button class="toolbar" mat-flat-button color="primary" (click)="gotoAddUserPage()">{{'BUTTONS.ADD_USER' | translate}}</button>
</div>
<div class="page-content">
  <div class="data-table">

    <table mat-table [dataSource]="backofficeUsers" multiTemplateDataRows matSortActive="fullName" matSortDirection="asc" class="mat-elevation-z8 data" matSort>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="column-100 no-padding">{{'COMMON.ACTIONS' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-100 no-padding">
          <button class="link" mat-icon-button routerLink="/backoffice-users-edit/{{user.id}}"><mat-icon>edit</mat-icon></button>
          <button class="link" mat-icon-button (click)="confirmRemoveUser(user)"><mat-icon>delete_outline</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-50">{{'PAGES.BACKOFFICE_USERS.ID' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-50">
          <a class="link" routerLink="/backoffice-users-view/{{user.id}}"> {{user.id}} </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.BACKOFFICE_USERS.FULL_NAME' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PAGES.BACKOFFICE_USERS.EMAIL' | translate}}</th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>

      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-100">{{'PAGES.BACKOFFICE_USERS.ROLE' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-100"> {{user.roleName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastLoginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-150">{{'PAGES.BACKOFFICE_USERS.LAST_LOGIN_DATE' | translate}}</th>
        <td mat-cell *matCellDef="let user" class="column-150"><span *ngIf="user?.lastLoginDate">{{user.lastLoginDate + 'Z' | date:"dd.MM.yy (HH:mm)"}}</span></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">{{'COMMON.NO_DATA_MATCHING_YOUR_REQUEST' | translate}}</td>
      </tr>
    </table>

    <mat-paginator (page)="changePage($event)" showFirstLastButtons></mat-paginator>
  </div>
</div>
