import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "../../../shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { ReportStatusService } from "../../../shared/services/report-status.service";

@Component({
  selector: 'app-edit-report-status',
  templateUrl: './edit-report-status.component.html',
  styleUrls: ['./edit-report-status.component.scss']
})
export class EditReportStatusComponent implements OnInit {

  public form: FormGroup;
  private reportStatusId: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private translate: TranslateService,
    private reportStatusService: ReportStatusService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // get report status data
    this.reportStatusId = this.route.snapshot.paramMap.get('id');

    if (this.reportStatusId) {
      this.reportStatusService.getReportStatus(this.reportStatusId).subscribe({
        next: (resp: any) => {
          this.form.get('name')?.patchValue(resp.name);
        },
        error: (error: any) => {
        }
      });
    }
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  updateReportStatus() {
    if (this.form.valid) {

      const formData = this.form.value;
      this.reportStatusService.updateReportStatus(this.reportStatusId,{
        name: formData.name
      }).subscribe({
        next: (resp: any) => {
          this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_REPORT_STATUS'));
          this.router.navigate(['/report-status-management']);
        },
        error: (error) => {},
      });

    }

  }

}
