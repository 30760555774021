import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ToastService } from "../../shared/services/toast.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PagesManagementService } from "../../shared/services/pages-management.service";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { PageModel } from "../../shared/models/page.model";
import { CategoryTypes } from "../../shared/enums/categoryTypes.enum";
import { BreadcrumbsService } from "../../shared/services/breadcrumbs.service";
import { zeroPad } from "../../shared/helpers/core.helpers";
import { AppConstants } from "../../shared/constants";
import { StatesService } from "../../shared/services/states.service";

@Component({
  selector: 'app-pages-management',
  templateUrl: './pages-management.component.html',
  styleUrls: ['./pages-management.component.scss']
})
export class PagesManagementComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(MatSort) sort: MatSort ;

  constructor(
    private pagesService: PagesManagementService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<PagesManagementComponent>,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private statesService: StatesService
  ) { }

  public categoryTypes = CategoryTypes;
  public pages = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'title', 'subTitle'];
  public zeroPad = zeroPad;

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.breadcrumbsService.clearBreadcrumb();
    this.pages.paginator = this.paginator;
    this.pages.sort = this.sort;

    this.updatePages();
  }

  updatePages() {
    this.pagesService.getPages()
      .subscribe({
        next: (resp: any) => {

          this.pages.data = resp;

          this.paginator.pageIndex = this.statesService.pagesPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.pagesPaginator.pageSize;
          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

          this.pages.paginator = this.paginator;
          this.pages.sort = this.sort;
        },
        error: (error) => {}
      })
  }

  shortenedCategoryType(categoryName: string) {
    return categoryName !== this.categoryTypes.NONE ? categoryName.substring(0, 3) : '';
  }

  confirmRemovePage(event: Event, page: PageModel) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'),
      this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAGE'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove page
        this.pagesService.deletePage(page.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_PAGE'));
              this.updatePages();
            },
            error: (error) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  gotoAddPage() {
    this.router.navigate(['page-add']);
  }

  changePage(event: any) {
    this.statesService.pagesPaginator.pageIndex = event.pageIndex;
    this.statesService.pagesPaginator.pageSize = event.pageSize;
  }

}
