import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../shared/constants';
import { CommunicationChannel } from '../../shared/enums/messages.enum';
import { MessagesModel } from '../../shared/models/messages.model';
import { Editor, Toolbar, toHTML, toDoc } from 'ngx-editor';
import { environment } from '../../../environments/environment';
import { conditionalValidator } from '../../shared/helpers/conditional.validator';
import { valueCheckerValidator } from '../../shared/helpers/value-checker.validator';
import { MessagesManagementService } from '../../shared/services/messages-management.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-messages-management',
  templateUrl: './messages-management.component.html',
  styleUrls: ['./messages-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesManagementComponent implements OnInit {
  public appConstants = AppConstants;
  public communicationChannel = CommunicationChannel;

  public sendToAdminOnly: boolean = false;

  public linkPatternLength = environment.reportLinkPattern.length;

  public emailEditor: Editor;
  public emailEditorToolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['ordered_list', 'bullet_list'],
    ['text_color', 'background_color'],
  ];

  public messages: MessagesModel[] = [];

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messagesService: MessagesManagementService,
    private toast: ToastService,
    public translate: TranslateService
  ) {
    this.emailEditor = new Editor();

    this.form = this.fb.group({
      smsIsActive: [],
      smsMessage: [
        '',
        [
          conditionalValidator(() => this.form.get('smsIsActive')?.value, Validators.required, 'conditionalSMS'),
          conditionalValidator(
            () => this.form.get('smsIsActive')?.value,
            valueCheckerValidator(AppConstants.linkPlaceholder),
            'existingLinkSMS'
          ),
        ],
      ],

      emailIsActive: [],
      emailMessage: [
        '',
        [
          conditionalValidator(() => this.form.get('emailIsActive')?.value, Validators.required, 'conditionalEmail'),
          conditionalValidator(
            () => this.form.get('emailIsActive')?.value,
            valueCheckerValidator(AppConstants.linkPlaceholder),
            'existingLinkEmail'
          ),
        ],
      ],

      whatsappIsActive: [],
      whatsappMessage: [
        '',
        [conditionalValidator(() => this.form.get('whatsappIsActive')?.value, Validators.required, 'conditionalWhatsApp')],
      ],
    });
  }

  ngOnInit(): void {
    forkJoin([this.messagesService.getMessages(), this.messagesService.getToggler()]).subscribe(([messagesSettings, toggler]) => {
      this.messages = messagesSettings as MessagesModel[];

      this.messages.map((res: { communicationChannel: string }) => {
        res.communicationChannel = res.communicationChannel.toLowerCase();
        return res;
      });

      this.form.get('smsIsActive')?.patchValue(this.getMessage(CommunicationChannel.SMS)?.isEnabled);
      this.form.get('smsMessage')?.patchValue(this.getMessage(CommunicationChannel.SMS)?.templateText);

      this.form.get('emailIsActive')?.patchValue(this.getMessage(CommunicationChannel.EMAIL)?.isEnabled);
      this.form.get('emailMessage')?.patchValue(toDoc(this.getMessage(CommunicationChannel.EMAIL)?.templateText || ''));

      this.form.get('whatsappIsActive')?.patchValue(this.getMessage(CommunicationChannel.WHATSAPP)?.isEnabled);
      this.form.get('whatsappMessage')?.patchValue(this.getMessage(CommunicationChannel.WHATSAPP)?.templateText);

      this.sendToAdminOnly = toggler;
    });
  }

  getMessage(name: CommunicationChannel) {
    return this.messages.find((message: MessagesModel) => message.communicationChannel === name);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  getMultiLineTranslation(text: string) {
    const translatedText: Array<string> = this.translate.instant(text);
    return translatedText.join('<br>');
  }

  saveMessages() {
    const preparedData = {
      templates: [
        {
          communicationChannel: 'Sms', // because of difference between FE&BE
          isEnabled: this.form.get(`${CommunicationChannel.SMS}IsActive`)?.value,
          templateText: this.form.get(`${CommunicationChannel.SMS}Message`)?.value,
        },
        {
          communicationChannel: 'Email', // because of difference between FE&BE
          isEnabled: this.form.get(`${CommunicationChannel.EMAIL}IsActive`)?.value,
          templateText: this.form.get(`${CommunicationChannel.EMAIL}Message`)?.value,
        },
        {
          communicationChannel: 'WhatsApp', // because of difference between FE&BE
          isEnabled: this.form.get(`${CommunicationChannel.WHATSAPP}IsActive`)?.value,
          templateText: this.form.get(`${CommunicationChannel.WHATSAPP}Message`)?.value,
        },
      ],
    };

    this.messagesService.updateMessages(preparedData).subscribe({
      next: (resp: any) => {
        this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_MESSAGES'));
      },
      error: (error) => {},
    });
  }

  getNumberOfLinks(text: string): number {
    return text.split(AppConstants.linkPlaceholder).length - 1;
  }

  getMessageLength(text: string) {
    const numberOfLinks = this.getNumberOfLinks(text);
    return text.length - numberOfLinks * AppConstants.linkPlaceholder.length + numberOfLinks * this.linkPatternLength;
  }

  getMaxMessageLength(communicationChannel: CommunicationChannel) {
    const text = this.form.get(`${communicationChannel}Message`)?.value;
    const maxLength = AppConstants.messageTextMaxLength[communicationChannel];
    const numberOfLinks = this.getNumberOfLinks(text);

    return maxLength + numberOfLinks * AppConstants.linkPlaceholder.length - numberOfLinks * this.linkPatternLength;
  }

  emailText(): string {
    const emailMessage = this.form?.get(`${CommunicationChannel.EMAIL}Message`)?.value;
    const r = emailMessage ? toHTML(emailMessage).replace(/(<([^>]+)>)/gi, '') : '';
    alert(r);
    return r;
  }

  ngOnDestroy(): void {
    this.emailEditor.destroy();
  }

  saveToggler(): void {
    this.messagesService.setToggler(this.sendToAdminOnly).subscribe((resp: any) => {
      this.toast.show(this.translate.instant('SUCCESS_MESSAGES.UPDATED_TOGGLER'));
    });
  }
}
