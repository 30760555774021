import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
// import { MatSort } from "@angular/material/sort";
import { ApiService } from "../../shared/services/api.service";
import { ToastService } from "../../shared/services/toast.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmComponent, ConfirmModel } from "../modal-dialogs/confirm/confirm.component";
import { ApplicationUsersService } from "../../shared/services/application-users.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../../shared/constants";
import { StatesService } from "../../shared/services/states.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatTable) table: MatTable<any>;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  // @ViewChild(MatSort) sort: MatSort ;


  constructor(
    private api: ApiService,
    private toast: ToastService,
    public dialogRef: MatDialogRef<UsersComponent>,
    private dialog: MatDialog,
    private usersService: ApplicationUsersService,
    private router: Router,
    private translate: TranslateService,
    private statesService: StatesService
  ) { }

  public applicationUsers = new MatTableDataSource([]);
  public displayedColumns: string[] = ['actions', 'id', 'firstName', 'lastName', 'phoneNumber', 'email', 'createdDate', 'lastLoginDate'];

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.updateApplicationUsers();
  }

  confirmRemoveUser(event: Event, user: any) {
    const dialogData = new ConfirmModel(this.translate.instant('DIALOGS.CONFIRM_ACTION'), this.translate.instant('DIALOGS.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER'));
    const dialogRef = this.dialog.open(ConfirmComponent, {
      maxWidth: "400px",
      data: dialogData,
      panelClass: 'modal-dialog',
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        // remove user
        this.usersService.removeUser(user.id)
          .subscribe({
            next: (resp: any) => {
              this.toast.show(this.translate.instant('SUCCESS_MESSAGES.REMOVED_USER'));
              this.updateApplicationUsers();
            },
            error: (error: any) => {}
          })
      } else {
        // not confirmed - do nothing
      }

    });
  }

  updateApplicationUsers() {
    this.usersService.getUsers(this.statesService.usersPaginator.pageIndex+1,
                                          this.statesService.usersPaginator.pageSize)
      .subscribe({
        next: (resp: any) => {
          this.applicationUsers.data = resp.result;

          this.paginator.pageIndex = this.statesService.usersPaginator.pageIndex;
          this.paginator.pageSize = this.statesService.usersPaginator.pageSize;
          this.paginator.length = resp.count;

          this.paginator.pageSizeOptions = AppConstants.paginator.pageSizeOptions;

        },
        error: (error) => {}
      })
  }

  changePageBE(event: PageEvent) {
    this.statesService.usersPaginator.pageIndex = event.pageIndex;
    this.statesService.usersPaginator.pageSize = event.pageSize;

    this.updateApplicationUsers();
  }

  gotoAddUserPage() {
    this.router.navigate(['users-add']);
  }

  exportToExcel() {
    this.usersService.exportToExcel();
  }
}
