<div class="crud-page">
  <div class="page-header">
    <a routerLink="/users" class="link back-button"><mat-icon>arrow_left</mat-icon>{{'PAGES.USERS.BACK' | translate}}</a>
    <h1>
      {{'PAGES.USERS_VIEW.TITLE' | translate}}
      <span *ngIf="user?.isDeleted" class="flag"><mat-icon>flag</mat-icon></span>
    </h1>
    <button class="toolbar" mat-flat-button color="primary" (click)="editUser()">{{'BUTTONS.EDIT_APPLICATION_USER' | translate}}</button>
  </div>
  <div class="page-content">
    <div class="view-container">
      <div class="items bold-danger" *ngIf="user?.isDeleted">
        {{'PAGES.USERS.USER_IS_DELETED' | translate}}
      </div>

      <div class="items" *ngIf="user?.firstName">
        <span class="header">{{'PAGES.USERS.FIRST_NAME' | translate}}</span>
        <span class="value">{{user?.firstName}}</span>
      </div>

      <div class="items" *ngIf="user?.lastName">
        <span class="header">{{'PAGES.USERS.LAST_NAME' | translate}}</span>
        <span class="value">{{user?.lastName}}</span>
      </div>

      <div class="items" *ngIf="user?.phoneNumber">
        <span class="header">{{'PAGES.USERS.PHONE_NUMBER' | translate}}</span>
        <span class="value">{{user?.phoneNumber}}</span>
      </div>

      <div class="items" *ngIf="user?.email">
        <span class="header">{{'PAGES.USERS.EMAIL' | translate}}</span>
        <span class="value">{{user?.email}}</span>
      </div>

      <div class="items" *ngIf="user?.lastLoginDate">
        <span class="header">{{'PAGES.USERS.LAST_LOGIN_DATE' | translate}}</span>
        <span class="value">{{user?.lastLoginDate + 'Z' | date: "dd.MM.yy (HH:mm)"}}</span>
      </div>

      <div class="items" *ngIf="user?.status">
        <span class="header">{{'PAGES.USERS.STATUS' | translate}}</span>
        <span class="value">{{user?.status}}</span>
      </div>

      <div class="items" *ngIf="user?.receiveNewsInfo">
        <span class="header">{{'PAGES.USERS.RECEIVE_NEWS_INFO' | translate}}</span>
        <span class="value">{{user?.receiveNewsInfo ? 'yes' : 'no'}}</span>
      </div>

      <div class="items" *ngIf="user?.receiveReportInfo">
        <span class="header">{{'PAGES.USERS.RECEIVE_REPORT_INFO' | translate}}</span>
        <span class="value">{{user?.receiveReportInfo ? 'yes' : 'no'}}</span>
      </div>

      <div class="items">
        <span class="header">{{'PAGES.USERS.EULA' | translate}}</span>
        <span class="value">{{user?.eula ? 'yes' : 'no'}}</span>
      </div>
      <div class="items">
        <span class="header">{{'PAGES.USERS.EULA_DATE' | translate}}</span>
        <span class="value">{{user?.eulaDate ? (user?.eulaDate + 'Z' | date: "dd.MM.yy (HH:mm)") : '---'}}</span>
      </div>

      <div class="items" *ngIf="user?.seaRelations?.length > 0">
        <span class="header">{{'PAGES.USERS.SEA_RELATIONS' | translate}}</span>
        <span class="value">{{seaRelations}}</span>
      </div>
      <div class="items" *ngIf="user?.createdDate">
        <span class="header">{{'PAGES.USERS.CREATED_DATE' | translate}}</span>
        <span class="value">{{user?.createdDate + 'Z' | date: "dd.MM.yy (HH:mm)"}}</span>
      </div>
      <div class="items" *ngIf="user?.updatedDate">
        <span class="header">{{'PAGES.USERS.UPDATED_DATE' | translate}}</span>
        <span class="value">{{user?.updatedDate + 'Z' | date: "dd.MM.yy (HH:mm)"}}</span>
      </div>

    </div>
  </div>
</div>
