import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagesManagementService {

  constructor(
    private api: ApiService
  ) { }

  getMessages() {
    return this.api.get(`messagetemplates`);
  }

  updateMessages(data: any) {
    return this.api.put(`messagetemplates`, data);
  }

  getToggler(): Observable<any> {
    return this.api.get('messagetemplates/toggle');
  }

  setToggler(isAdminOnly: boolean): Observable<any> {
    return this.api.post('messagetemplates/toggle/' + isAdminOnly, {});
  }

}
