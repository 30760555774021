import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { BreadcrumbsModel } from "../models/breadcrumbs.model";
import { PagesManagementService } from "./pages-management.service";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  public breadcrumbsItems: BehaviorSubject<BreadcrumbsModel[]> = new BehaviorSubject<BreadcrumbsModel[]>([]);

  public pendingAddingBreadcrumbItem: number | undefined;
  public pendingShorteningBreadcrumbItem: number | undefined;

  constructor(
    private pagesService: PagesManagementService
  ) { }

  addBreadcrumb(pageId: number) {

    this.pagesService.getPage(pageId).subscribe({
      next: (page: any) => {
        let currentState = this.breadcrumbsItems.getValue();
        currentState.push({index: currentState.length, pageId, pageTitle: page.title});
        this.pendingAddingBreadcrumbItem = undefined;
        this.breadcrumbsItems.next(currentState);
      },
      error: (error) => {}
    });
  }

  updateBreadcrumbsData() {
    this.pagesService.getPages().subscribe({
      next: (pages: any) => {
        let currentState = this.breadcrumbsItems.getValue();
        currentState = currentState.map(bc => {
          return {
            index: bc.index,
            pageId: bc.pageId,
            pageTitle: pages.find((page: { id: number; }) => page.id == bc.pageId).title
          }
        })

        this.breadcrumbsItems.next(currentState);
      },
      error: (error) => {}
    })
  }

  clearAfterIndex(index: number) {
    let currentState = this.breadcrumbsItems.getValue().filter((item: BreadcrumbsModel) => item.index <= index);
    this.pendingShorteningBreadcrumbItem = undefined;
    this.breadcrumbsItems.next(currentState);
  }

  updateItems(pageId: number) {
    let breadcrumbItemIndex = this.breadcrumbsItems.getValue().findIndex(item => item.pageId === pageId);
    this.breadcrumbsItems.next(this.breadcrumbsItems.getValue().slice(0, breadcrumbItemIndex-1));
  }

  clearBreadcrumb() {
    this.breadcrumbsItems.next([]);
  }
}
